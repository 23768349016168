.dayd{
    padding: 20px 160px;
    display: flex;
    flex-direction: row;
}

.dayd > img {
    width: 100%;
}

.dayd-content{
    /* transform: translateX(-100%); */
    padding: 0px 200px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    transform: translateY(-212px);
}

.dayd-content > .content1{
    font-size: 28px;
    font-weight: 800;
}
.dayd-content > .content2{
    font-size: 28px;
    font-weight: 800;
    color: #f0b33e;
}

.dayd-content > .content3{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.content3 > .btn1{
    background-color: #068c89;
    border: 2px solid #068c89;
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
}

.content3 > .btn2{
    color: #068c89;
    font-weight: 500;
    padding: 8px 16px;
    border: 2px solid #068c89;
    border-radius: 20px;
    cursor: pointer;
}

.content3 > .btn1:hover{
    color: #068c89;
    background-color: transparent;
    font-weight: 500;
    /* padding: 8px 16px; */
    border: 2px solid #068c89;
    /* border-radius: 20px; */
}

.content3 > .btn2:hover{
    background-color: #068c89;
    color: white;
    /* padding: 8px 16px; */
    /* border-radius: 20px; */
}

@media screen and (max-width: 1100px) {
    .dayd{
        padding: 20px 120px;
    }
}
@media screen and (max-width: 1000px) {
    .dayd{
        padding: 20px 100px;
    }
    .dayd-content{
        padding: 0px 130px;
    }
}
@media screen and (max-width: 800px) {
    .dayd{
        padding: 20px 80px;
    }
    .dayd-content{
        gap: 6px;
        transform: translateY(-160px);
        padding: 0px 100px;
    }
    .dayd-content > .content1{
        font-size: 22px;
    }
    .dayd-content > .content2{
        font-size: 22px;
    }
    .dayd-content > .content3{
        gap: 16px;
    }

    .content3{
        font-size: 12px;
    }
}
@media screen and (max-width: 500px) {
    .dayd{
        padding: 0px;
        overflow-x: hidden;
    }
    .dayd > img{
        width: 230%;
    }
    .dayd-content{
        padding: 0px 20px;
        transform: translateY(0px);
        align-items: flex-start;
        padding: 20px 20px 40px 20px;
    }
}