.fourth-fold {
    background: #003C3C;
    padding: 0px 0;overflow: hidden;
}

.visit-info {
    position: relative;
padding-left: 120px;
padding-top: 30px;
}
.visit-info::before {
    background-image: url(../images/Group-3015013.svg);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -37px;
    z-index: 1;
    width: 100%;
    height: 230px;
    left: 7px;
    background-size: 52%;
}
.fourth-fold h5 {
    font-size: 25px;
color: #fff;
position: relative;
z-index: 9;
font-weight: bold;
line-height: 42px;
}
.fourth-fold h5 b {
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    left: 0;
    bottom: -18px;
  }

.fourth-fold h5 span {
    font-size: 32px;
    font-weight: 800;    
    font-family: 'GalanoGrotesqueBold', sans-serif;
}
.bookslot .btn {
    background: #FB9F40;
    border: 1px solid #FB9F40;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    padding: 12px 32px 12px 32px;
}


@media only screen and (max-width: 767px) { 
.visit-info {
    position: relative;
    padding-left: 0px;
    padding-top: 0px;
}
.fourth-fold h5 span {
    padding-left: 0px;
    display: block;
}
.fourth-fold h5 {
    
    line-height: 25px;font-size: 12px;

}
.bookslot .btn {
    margin-top: 20px;
    font-size: 15px;
    line-height: 32px;
    padding: 5px 15px 5px 15px;
    position: relative;
    z-index: 1111;
}
.visit-info::before {
    top: 8px;
    z-index: 1;
    width: 204%;
    height: 120%;
    left: 34px;
    background-size: 100%;
}

.fourth-fold h5 b {
    font-size: 9px;
    font-weight: 400;
}





}
.lkiu{position: absolute; right: 0px; bottom: 0px;font-size: 9px; color:white;margin-right: 10px;margin-bottom: 4px;}