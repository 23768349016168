.nbar{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
    padding: 20px 160px;
    /* margin: 10px; */
    
}

.nbar .logo img{
    width: 100px;
}

.nbar .logo:hover{
    cursor: pointer;
}
.nbar-tabs{
    display: flex;
    flex-direction: row;
    gap: 3em;
    padding-right: 40px;
    align-items: center;
}

.nbar-tabs div{
    font-weight: 600;
    text-decoration: none;
}

.nbar-tabs > div > a{
    text-decoration: none;
    color: black;
}

.nbar-tabs div:hover{
    cursor: pointer;
}

.nbar-tab-3{
    border: 2px solid #068C69;
    padding: 6px 20px;
    color: #068C69;
    border-radius: 20px;
}

.nbar-tab-3:hover{
    color: white;
    background-color: #068C69;
}

.modal{
    display: flex !important;
    position: absolute !important;
    /* justify-content: center;
    align-items: center; */
    padding: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.popup-content{
    width: 80% !important;
    height: 400px;
    border-radius: 12px;
}

.modal > .content{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.modal > .content > .title{
    font-size: 20px;
    font-weight: 700;
    color: #068C69;
}

.modal > .content > .submit{
    border: 2px solid #068C69;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #068C69;
    font-size: 18px;
    border-radius: 14px;
    font-weight: 600;
}
.modal > .content > .submit:hover{
    background-color: #068C69;
    color: white;
    cursor: pointer;
}

.modal > .content > div{
    display: flex;
    gap: 20px;
}

.modal > .content > div > div{
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
}

.modal > .content > .phone-input > .phone-err{
    font-size: 14px;
    color: red;
}

.modal > .content > .phone-input > .err-inactive{
    border: 2px solid black;
}

.modal > .content > .phone-input > .err-active{
    border: 2px solid red;
}

.modal > .content > div > input{
    border-radius: 8px;
    position: absolute;
    left: 200px;
    padding-left: 12px;
}

.nbar-tabs > div:nth-child(3){
    color: #068C69;
    padding: 4px 10px;
    border-radius: 20px;
    border: 2px solid #068C69;
    display: flex;
    gap: 10px;
}


.modal{
    padding: 20px;
}

.failed-booking-message{
    /* width: 100%; */
    background-color: white;
    box-shadow: #068C69 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 20px;
    position: fixed;
    bottom: 28px;
    right: 40px;
    z-index: 6;
    color: #068C69;
}



@media screen and (max-width: 1100px){
    .nbar{
        padding: 20px 120px;
    }
}

@media screen and (max-width: 800px){
    .nbar{
        padding: 20px 80px;
    }
    .nbar-tabs{
        gap: 1.5rem;
    }

    .failed-booking-message{
        font-size: 18px;
    }
}
@media screen and (max-width: 500px){
    .nbar{
        justify-content: space-between;
        padding: 20px 40px;
    }
    
    .nbar-tabs{
        padding-right: 0px;
    }
    .nbar > .nbar-tabs > div{
        display: none;
    }

    .nbar > .nbar-tabs > div:nth-child(3){
        display: block;
        font-size: 16px;
    }

    .modal > .content > div{
        display: block;
    }

    .modal > .content > div > input{
        position: initial;
    }

    .modal > .content{
        gap: 16px;
    }
    .failed-booking-message{
        font-size: 14px;
        bottom: 80px;
    }
}



@media screen and (max-width: 400px){
    .nbar > .nbar-tabs > div:nth-child(3){
        font-size: 12px;
        display: flex;
        gap: 10px;
    }

    .nbar{
        padding: 20px;
    }

}