.wckh{
    margin: 80px 0px;
    padding: 0px 160px;
}
.wckh-title{
    font-size: 32px;
    font-weight: 700;

    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(-400px 40px 120px #068C69);
}

.wckh-title > div > img{
    margin-left: 10px;
    width: 24px;
}

.wckh-title > div > span{
    color: #068c89;
}

.wckh-title > .wckh-title-content{
    font-size: 14px;
    opacity: 0.5;
    margin-top: 10px;
    text-align: center;
    padding: 0px 150px;
}

.wckh-cards{
    transform: translateY(0px) !important;
    /* transform: translateY(0px) !important; */
    margin-top: 60px;
}

/* .wckh-cards {
    display: flex;
    flex: row;
    justify-content: space-evenly;
    margin-top: 40px;
}

.wckh-cards > div > div{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px;
}

.wckh-cards > div > img{
    width: 240px;
}

.wckh-cards > div > span{
    display: none;
} */


.wska{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.wska > img{
    height: 200px;
    width: 280px;
}

.wska > div{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
}

.wska > span{
    text-align: left;
    opacity: 0.8;
}

@media screen and (max-width: 1100px) {
    .wckh-title > .wckh-title-content{
        padding: 0px 0px;
    }
    .wckh{
        padding: 0px 120px;
    }
}


@media screen and (max-width:  1000px){
    .wckh-cards > div >img{
        width: 200px;
    }
}
@media screen and (max-width:  800px){
    .wckh{
        padding: 0px 80px;
    }
    .wckh-cards > div >img{
        width: 160px;
    }

    .wckh-cards > div{
        width: 160px;
    }
}

@media only screen and (max-width: 480px){
    .wckh{
        padding: 20px 40px;
        /* z-index: 50; */
    }

    .wckh-title > div{
        text-align: center;
    }

    .wckh-title{
        position:relative;
        z-index: 3;
    }

    /* .wckh-cards{
        flex-direction: column;
    }

    .wckh-cards > div{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .wckh-cards > div > div{
        display: none;
    }

    .wckh-cards > div > span{
        display: block;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        margin: 10px 0px;
        transform: translateX(-16px);
    }

    .wckh-cards > div > img{
        width: 220px;
    } */
    

}

