section {
  padding: 40px 0;
}
ol.decimal-list li {
  list-style: decimal !important;
  padding: 3px 10px;
}

ol.decimal-list li::before {
  display: none;
}

.blog h5 {min-height: 50px;}

.blog {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .blog {
      margin-top: 57px;
  }
}
