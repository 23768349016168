/* Container Styling */

.why-kyno-section {
  display: flex;
  flex-direction: column;
  color: #213547;
  text-align: center;
  margin: 0 auto;
  padding: 80px 0;
  /* max-width: 1200px; */
  background-color: #E9F8F5;
}
.highlights-container {
  display: flex;
  gap: 10px;
  /* background-color: #f0faf4; */
  /* Light greenish background */
  /* padding: 20px; */
  border-radius: 8px;
  max-width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  /* flex-basis: auto; */
  /* font-family: Arial, sans-serif; */
}

/* Individual Card */
.highlight-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  padding: 15px;
  align-items: start;
  flex-grow: 1;
    flex-basis: 0;
}

/* Image */
.highlight-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

/* Text Content */
.highlight-text {
  padding: 15px 0;
  color: #333;
  text-align: left;
  height: 160px;
}

.highlight-text h3 {
  color: #083E3E;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.highlight-text p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #083E3E;
  text-align: justify;
}
.kyno-content {
  width: 50%;
  margin: 0 auto 50px;
}
.kyno-content h2 {
  color: #1f3c2d;
  font-size: 50px;
  margin-bottom: 15px;
  font-weight: 700;
}

.kyno-content p {
  line-height: 1.5;
  font-size: 1rem;
  margin-bottom: 10px;
  color: #083E3E;
}


/* Responsive Layout */
@media (max-width: 768px) {
  .why-kyno-section {
    padding: 40px 20px;
  }

  /* Container Styling */
   /* Text Content */
   .kyno-content h2 {
    color: #1f3c2d;
    font-size: 1.8rem;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .kyno-content p {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 10px;
    color: #083E3E;
  }

  .kyno-content {
    padding: 0px;
    width: 100%;
    margin: 0 auto 0px;
  }


  .highlights-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: #f0faf4; */
    /* Light greenish background */
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
    /* font-family: Arial, sans-serif; */
  }

  /* Individual Card */
  .highlight-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    flex-basis: unset;
    flex-grow: unset;
  }

  /* Image */
  .highlight-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  /* Text Content */
  .highlight-text {
    /* padding: 15px; */
    color: #333;
  }

  .highlight-text h3 {
    color: #083E3E;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .highlight-text p {
    font-size: 0.95rem;
    line-height: 1.5;
    color: #083E3E;
  }
}