.patient-info-page{
    // padding: 20px;
    height: calc(100vh - 70px);

    .title{
        padding: 20px;
        width: 450px;
        display: flex;
        align-items: center;
        img{
            width: 31%;
        }

        &:hover{
            cursor: pointer;
        }

        .sales-db-back{
            position: relative;
            left: 80px;
            font-size: 18px;
            font-weight: 600;
            background-color: rgb(185, 180, 180);
            padding: 4px 10px;
            color: black;
            border-radius: 8px;

            &:hover{
                cursor: pointer;
                background-color: grey;
                color: white;
            }
        }
    }

    .patient-info{
        
        display: flex;
        flex-direction: row;
        height: 100%;




        .left{
            width: 25%;
            // border-right: 2px solid rgba(0,0,0,0.2);
            border-top: 2px solid rgba(0, 0, 0, 0.15);
            height: max-content;
            background-color: white;

            
            .personal-info{
                div{
                    input, select{
                        background-color: #068c6923;
                        border: 0px;
                    }
                }

                .update{
                    margin: 8px;
                    color: #068c69;
                    font-weight: 600;
                    background-color: white;
                    border: 2px solid #068c69;
                    width: fit-content;
                    padding: 4px 10px;

                    &:hover{
                        background-color: #068c6923;
                        color: white;
                        cursor: pointer;
                    }
                }
            }


            .basic-details{
                padding: 12px;
                padding-bottom: 16px;
                border-bottom: 2px solid #068c684a;

                .name{
                    font-size: 28px;
                    font-weight: 700;
                    color: rgba(0, 0, 0, 0.75);
                }

                .connect-btns{
                    margin-top: 10px;

                    img{
                        margin: 0px 10px 0px 0px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }

            .Collapsible{
                padding: 4px 0px 4px 0px;
                .Collapsible__trigger{
                    font-size: 18px;
                    font-weight: 650;
                    color: #000000a0;
                    padding:  0px 12px;
                    display: block;
                    border-bottom: 2px solid #068c684a;
                }
                .lead-info-title, .personal-info-title, .family-info-title{
                    padding: 0px 12px;
                }
                .lead-info, .personal-info, .family-info{
                    padding: 0px 20px;
                    color: rgba(0, 0, 0, 0.6);
                    background-color: #068c6823;
                    font-size: 12px;
                }

                .family-info{
                    display: flex;
                    flex-direction: column;
                    gap: 6px 10px;
                    background-color: white;

                    .add-member-link{
                        text-align: right;
                        font-weight: 700;
                        color: blue;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    .patient-family{
                        background-color: #cbcbcb;
                        padding: 10px;
                        position: relative;

                        .edit{
                            position: absolute;
                            right: 10px;

                            &:hover{
                                cursor: pointer;
                            }
                        }

                        .name{
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            align-items: center;
                            

                            span:nth-child(1){
                                font-size: 16px;
                                font-weight: 650;
                            }
                        }
                        .age-gender{
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            align-items: center;
                            span{
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .page-msg{
                position: absolute;
                bottom: 20px;
                left: 20px;
                background: #068c69;
                padding: 4px 10px;
                border-radius: 8px;
                color: honeydew;
                font-size: 18px;
                font-weight: 700;

            }
        }

        .middle{
            padding: 20px 0px;
            width: 60%;
            // background-color: rgba(0, 0, 0, 0.096);
            .tabs{
                display: flex;
                padding: 0px 30px 10px 30px;
                gap: 20px;
                flex-direction: row;
                // background-color: white;
                div{
                    font-size: 16px;
                    font-weight: 700;
                    
                    &.active{
                        color: #068c69;
                    }
                    
                    &:hover{
                        color: #068c69;
                        cursor: pointer;
                    }
                }
            }

            .middle-content{
                background-color: #f9f7f7;
                width: 100%;
                height: 100%;


                .activities{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px;
                    .activity{
                        padding: 10px ;
                        background-color: white;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        // justify-content: space-between;
                        gap: 20px;
                        align-items: center;

                        .all-leads, .active-leads {
                            .title{
                                font-size: 16px;
                                font-weight: 650;
                                padding: 10px;
                            }
                            .content{
                                padding: 10px;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .lead{
                                    background-color: white;
                                    padding: 4px 10px 4px 10px;
                                    font-size: 14px;
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-between;
                                    div{
                                        font-size: 12px;
                                        right: 10px;
                                    }
                                }
                            }
                        }

                        
                        .all-leads{
                            background-color: #f9f7f7;
                            height: 250px;
                            overflow-y: scroll;
                            scrollbar-width: 0px;
                            width: 100%;
                            &::-webkit-scrollbar{
                                width: 5px;
                            }
                            span{
                                font-size: 10px;
                                height: 30px;
                                display: flex;
                                align-items: flex-end;
                            }
                        }

                        .active-leads{
                            background-color: #f9f7f7;
                            height: 250px;
                            overflow-y: scroll;
                            scrollbar-width: 0px;
                            width: 100%;
                            &::-webkit-scrollbar{
                                width: 5px;
                            }

                            .content{
                                .lead{ 
                                    padding: 4px 10px 16px 10px;
                                    position: relative;
                                    &:hover{
                                        cursor: pointer;
                                        .options{
                                            display: flex;
                                            position: absolute;
                                            bottom: 10px;
                                            padding: 2px 4px;
                                            border: 1px solid black;
                                            background-color: #cbcbcb;
                                            font-weight: 600;
                                            border-radius: 8px;
                                            &:hover{
                                                background-color: grey;
                                                color: white;
                                            }
                                        }
                                        .edit-option{
                                            display: flex;
                                            position: absolute;
                                            bottom: 10px;
                                            right: 160px;
                                            padding: 2px 4px;
                                            border: 1px solid black;
                                            background-color: #cbcbcb;
                                            font-weight: 600;
                                            border-radius: 8px;
                                            width: fit-content;
                                            &:hover{
                                                background-color: grey;
                                                color: white;
                                            }
                                        }
                                    }
                                    .options{
                                        display: none;
                                    }
                                    .edit-option{
                                        display: none;
                                    }
                                    border-left: 8px solid yellow;
                                    border-radius: 8px;

                                    &.cancelled{
                                        border-left: 8px solid red;
                                        border-radius: 8px;
                                    }
                                    &.rescheduled{
                                        border-left: 8px solid rgb(255, 218, 10);
                                        border-radius: 8px;
                                    }

                                    ._left{
                                        font-weight: 600;
                                    }
                                    
                                    ._right{
                                        font-weight: 600;
                                        
                                    }
                                }
                            }
                        }
                    }
                }

                .transactions{
                    // background-color: white;
                    padding: 20px 0px 0px 0px;
                    .title{
                        padding: 10px 30px;
                        background-color: #f9f7f7;
                        font-size: 16px;
                        font-weight: 650;
                        width: 100%;
                    }

                    .transaction{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 8px 30px 24px 30px;
                        align-items: center;
                        position: relative;
                        margin-bottom: 12px;
                        background-color: white;
                        &.pending{
                            border-left: 8px solid red;
                            border-radius: 8px;
                        }
                        &.payment-link-send{
                            border-left: 8px solid brown;
                            border-radius: 8px;
                        }

                        &.payment-done{
                            border-left: 8px solid green;
                            border-radius: 8px;
                        }

                        .options{
                            position: absolute;
                            display: flex;
                            bottom: 0px;
                            font-size: 12px;
                            gap: 12px;
                            div{
                                padding: 1px 5px;
                                border: 1px solid black;
                                background-color: #f9f7f7;
                                border-radius: 8px;
                                font-weight: 600;
                                
                                &:hover{
                                    cursor: pointer;
                                    background-color: grey;
                                    color: white;
                                }
                            }
                        }
                    }
                }

                .attachments{
                    padding: 20px;

                    .uploaded-content{
                        height: 300px;
                        overflow-y: scroll;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .preview{
                            display: flex;
                            gap: 20px;
                            position: relative;

                            div{
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                span{
                                    font-weight: 600;       
                                }
                            }

                            

                            .send{
                                padding: 4px 10px;
                                background-color: #acacac;
                                font-weight: 700;
                                text-align: center;
                                border-radius: 8px;
                                position: absolute;
                                right: 20px;
                                bottom: 20px;
                                &:hover{
                                    cursor: pointer;
                                    color: white;
                                    background-color: grey;
                                }
                            }
                        }
                    }

                    .title{
                        padding: 10px 30px;
                        background-color: white;
                        font-size: 16px;
                        font-weight: 650;
                        width: 100%;
                    }

                    .content{
                        padding: 10px 30px;

                        .upload-section{
                            .file-input{

                                .file-name-for-upload{
                                    margin-top: 20px;

                                    input{
                                        padding-left: 10px;
                                    }
                                }
                                .submit{
                                    margin: 20px 0px;
                                    background-color: rgb(185, 180, 180);
                                    color: black;
                                    border-radius: 8px;
                                    padding: 4px 10px;
                                    width: fit-content;

                                    &:hover{
                                        cursor: pointer;
                                        background-color: grey;
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }

                .notes{
                    padding: 20px 20px 0px 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    .note{
                        background-color: white;
                        height: 400px;
                        .heading{
                            font-weight: 650;
                            display: flex;
                            justify-content: space-between;
                            padding: 10px 20px;
                        }

                        .note-text{
                            border: 0px;
                            width: 98%;
                            height: 85%;
                            padding: 20px;

                            .comment{
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                padding: 4px 10px;
                                background-color: #f9f7f7;
                                border-radius: 8px;
                            }
                        }
                    }
                    .btn{
                        display: flex;
                        justify-content: flex-end;
                        .submit{
                            color: white;
                            background-color: #068c69;
                            border: 2px solid #068c69;
                            border-radius: 8px;
                            padding: 8px 16px;
                            font-weight: 650;
                            width: fit-content;

                            &:hover{
                                cursor: pointer;
                                background-color: white;
                                color: #068c69;
                            }
                        }
                    }
                        
                    .comments{
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        div{
                            padding: 4px 10px;
                            font-weight: 650;
                        }
                        .comment{
                            padding: 4px 10px;
                            border: 0px
                        }
                    }
                }

            }
        }


        .right{
            display: flex;
            flex-direction: column;
            padding: 20px;
            gap: 10px;
            font-weight: 650;
            background-color: white;
            padding-top: 60px;

            div{
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}

.patientinfo-content{

    height: 560px;
    width: 60% !important;
    padding: 0px;
    border-radius: 0px;
    border: 0px;
    .book-appointment-popup{
        .head{
            width: 100%;
            background-color: #068c69;
            color: white;
            font-size: 20px;
            font-weight: 650;
            padding: 8px 20px;
            text-align: center;
        }


        .inputs{
            padding: 30px;
            font-weight: 600;
            color: rgba(0,0,0,0.8);

            .name{
                padding-left: 150px;

                input{
                    margin-left: 20px;
                }
            }

            .cols{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                gap: 40px;
                justify-content: space-evenly;

                .left-col{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
                .right-col{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .submit{
                margin: 30px auto;
                background-color: #068c69;
                color: white;
                border: 2px solid #068c69;
                padding: 8px 16px;
                width: fit-content;
                &:hover{
                    background-color: white;
                    color: #068c69;
                    cursor: pointer;
                }
            }

            .modal-msg{
                position: absolute;
                right: 40px;
                bottom: 40px;
                color: white;
                font-weight: 600;
                font-size: 18px;
            }
        }
    }
}

.reschedule-cancel-popup-content{
    padding: 0px;
    border: 0px;
    .reschedule-cancel{
        .title{
            width: 100%;
            padding: 10px;
            background-color: #068c69;
            color: white;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        .content{
            text-align: center;
            padding: 20px;

            .cancel{
                padding: 20px;
                textarea{
                    width: 200px;
                    height: 80px;
                }
                .submit{
                    border: 2px solid #068c69;
                    background-color: #068c69;
                    color: white;
                    font-weight: 600;
                    padding: 4px 12px;
                    border-radius: 12px;
                    margin: auto;
                    margin-top: 20px;
                    width: fit-content;
                    &:hover{
                        cursor: pointer;
                        background-color: white;
                        color: #068c69;
                    }
                }
            }


            .reschedule{
                padding: 20px;
                textarea{
                    width: 200px;
                    height: 80px;
                }
                .submit{
                    border: 2px solid #068c69;
                    background-color: #068c69;
                    color: white;
                    font-weight: 600;
                    padding: 4px 12px;
                    border-radius: 12px;
                    margin: auto;
                    margin-top: 20px;
                    width: fit-content;
                    &:hover{
                        cursor: pointer;
                        background-color: white;
                        color: #068c69;
                    }
                }
            }
        }
    }
}


.edit-patient-family-member-content{
    border: 0px;
    padding: 0px;

    .content{

        display: flex;
        flex-direction: column;
        gap: 10px;
        font-weight: 600;
        justify-content: center;
        align-items: center;

        .title{
            background-color: #068c69;
            text-align: center;
            color: white;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
        
        }

        .disease-selector{
            width: 80%;
        }
        .submit{
            background-color: #068c69;
            border: 2px solid #068c69;
            padding: 8px 12px;
            color: white;

            &:hover{
                cursor: pointer;
                color: #068c69;
                background-color: white;
            }
        }
    }   
}


.add-member-content{
    padding: 0px;
    border: 0px;

    .add-member-modal{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        font-weight: 650;

        
        
        .title{
            background-color: #068c69;
            color: white;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            padding: 8px;
            width: 100%;
        }
        
        
        .disease-selector{
            width: 80%;
        }
        .submit{
            background-color: #068c69;
            border: 2px solid #068c69;
            padding: 8px 12px;
            color: white;

            &:hover{
                cursor: pointer;
                color: #068c69;
                background-color: white;
            }
        }
    }
}