.doctor-home{
    padding: 20px;

    .title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #068c89;
    }

    .content{
        padding: 20px;
    }
    
    .content > .content-options{
        display: flex;   
        background-color: white;
        gap: 4px;
    }
    
    .content > .content-options > div{
        flex: 1;
        background-color: rgb(227, 221, 221);
        padding: 4px 10px;
        text-align: center;
        border-radius: 4px;
        color: black;
    }
    
    .content > .content-options > div:hover{
        cursor: pointer;
        background-color: rgb(99, 99, 99);
        color: white;
    }
    
    .content > .content-options > div.active{
        background-color: rgb(99, 99, 99);
        color: white;
    }
    
    .content > .content-options > div.active:hover{
        background-color: rgb(227, 221, 221);
        color: black;
    }
}



