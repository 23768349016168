.baner-region {
    background: #003c3c;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
}

.baner-region h3 {
    font-weight: 500;
    line-height: 40px;
    font-size: 22px;
    color: #fff;
}

.left-side {

    position: relative;
    z-index: 99;
}
.left-side::after {
    background-image: url("../images/Background-Gradient.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 122px;
    width: 445px;
    height: 100%;
    z-index: -1;
    left: 0px;
}

.baner-region h1 {
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 32px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'GalanoGrotesqueHeavy', sans-serif;
}

.baner-region p {
    color: #fff;
    font-size: 15px;
    line-height: 24px;
}

.baner-region .btn {
    background: #FB9F40;
    border: 1px solid #FB9F40;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 16px 12px 16px;
}
.aft {
    position: absolute;
    top: -90px;
    right: -109px;
}

.right-side {
    position: relative;
}

.right-side::before {
    background-image: url("../images/Ellipse-3032.svg");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 92px;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: -20px;
    background-size: contain;
}
.right-side::after {
    background-image: url("../images/Tags.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    bottom: 0;
    z-index: 1;
    width: 128px;
    height: 61px;
    left: -20px;
}
img.bnrimg {
    z-index: 1;
    position: relative;
}

.tag {
    position: absolute;
    right: 25px;
    z-index: 9;
    top: 167px;
}

@media only screen and (max-width: 767px) {



    .right-side::before {
        top: 85px;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0px;
    }

    .tag {
        position: absolute;
        right: -10px;
        z-index: 999;
        top: 125px;
    }

    .aft {
        position: absolute;
        top: -390px;
        right: inherit;
        left: 0;
    }

    .aft img {
        max-width: 100%;
    }

    .left-side::after {
        top: 130px;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: -53px;
        background-size: cover;
        opacity: 0.7;
    }

    img.bnrimg {
        z-index: 111 !important;
        position: relative;
    }

    .right-side::after {
        bottom: 0;
        z-index: 111;
        width: 128px;
        height: 61px;
        left: -20px;
    }

    .ml-70 {
        margin-left: 0px;
    }


}

@media only screen and (max-width: 600px) {

    p.mob-noee {
        display: none;
    }




}

.new-content {
    /* background: linear-gradient(180deg, rgba(201, 232, 226, 0) 0%, #C9E8E2 80.2%) !important; */
    color: black;
    /* background-image: url('../images/hero_v2.png'); */
    background: linear-gradient(180deg, rgba(201, 232, 226, 0) 41%, #C9E8E2 60%) !important;
  height: 90vh; /* Adjust as needed */
  width: 100%;
  font-family: "Inter", sans-serif !important;
}
.new-content .left-side::after {
    /* padding: 0px 0px 0 20px !important; */
    background-image:unset !important;
}
.new-content h3 {
    font-size: 22px !important;
    font-weight: 700 !important;
}
.new-content h1, .new-content h3{
    color: #083E3E !important;
    font-family: "Inter", sans-serif !important;
    width: 90%;
    margin: 0 auto;
    text-transform: unset !important;
}
.new-content h1 {
    font-size: 32px !important;
}
.new-content .input_custom_wrapper {
    margin-bottom: 20px;
    max-width: 281px !important;
    margin: 0 auto;
    gap: 10px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    box-shadow: 0px 7px 16px 0px #083E3E0A;
    box-shadow: 0px 29px 29px 0px #083E3E08;
    box-shadow: 0px 66px 40px 0px #083E3E05;
    box-shadow: 0px 118px 47px 0px #083E3E03;
    box-shadow: 0px 184px 51px 0px #083E3E00;
    background: white;
    height: Hug(298px) px;
    top: 217px;
    left: 26px;
    padding: 24px 16px 24px 16px;
    gap: 24px;
    border-radius: 16px;
    opacity: 0px;
}

.new-content .input_custom_wrapper  h2{
    font-size: 18px;
}

.new-content .featuresContainer {
    margin: 0px -10px;
    padding: 10px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #d2ebe6), url('../images/hero_v2.png') !important;
    background-size: 100% !important;
    background-repeat: no-repeat  !important;
    height: 40vh;
  }
  
  .new-content .featureList {
    list-style-image: url("../images//whitegreentick.png");
    padding-left: 20px;
    width: 90%;
    margin: 0 auto;
  }
  .new-content .right-side {
    background: url('../images/hero_v2.png') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    width: 100%;
    display: none;
;
    /* justify-content: space-between; */
    /* align-items: self-end !important; */
    height: 100%;
    background-size: 100% !important;
    padding-right: -26px;
    margin-left: 69px;
    margin-top: 100px;

  }
  .new-content  .right-side::before {
    background: unset !important
  }
  .new-content .featureList > li {
      margin: 10px 0;
      font-size: 18px;
      color: #179D82;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    font-family: "Inter", sans-serif !important;

    }
  
    
