// @import "constant.scss";

.sales-book-appointment{

    .logo{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 13%;

            &:hover{
                cursor: pointer;
            }
        }

        .back-btn{
            background-color: #F4F1F1;
            font-weight: 700;
            font-size: 20px;
            color: black;
            padding: 8px 12px;
            border-radius: 12px;

            &:hover{
                background-color: grey;
                color: white;
                cursor: pointer;
            }
        }
    }


    .book-appointment-form{
        padding: 20px 100px;
        background-color: #F4F1F1;
        display: flex;
        flex-direction: column;
        gap: 6px;
        // height: 800px;

        .title{
            background-color: #068C69;
            padding: 8px  12px;
            font-size: 24px;
            color: white;
            font-weight: 700;
            text-align: center;
        }

        .submit{
            background-color: white;
            color: #068C69;
            padding: 8px 12px;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            border: 2px solid #068C69;

            &:hover{
                background-color: #068C69;
                color: white;
                cursor: pointer;
            }
        }

        .procedure-amount{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .subtotal{
                .cost{
                    padding: 8px 12px;
                    background-color: white;
                }
            }
        }
        .procedure-cost{
            display: flex;
            flex-direction: row;
            gap: 20px;
            font-weight: 700;
            div{
                flex: 1;
                padding: 8px 12px;
                background-color: white;
            }
        }

    }
    .msg{
        position: sticky;
        width: fit-content;
        right: 20px;
        bottom: 20px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: #068C69;
        margin-left: 20px;
    }
    .name-option{
        display: flex;
        flex-direction:  row;
        justify-content: space-between;
        padding: 4px  16px;
        
    }

    .service-type-option{
        display: flex;
        flex-direction:  row;
        justify-content: space-between;
        padding: 4px  16px;
    }

    .consultation-type-option{
        display: flex;
        flex-direction:  row;
        justify-content: space-between;
        padding: 4px  16px;
    }
}



.create-new-patient-content{
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    border: 0px;
    .title{
        background-color: #068C69;
        color: white;
        font-weight: 700;
        text-align: center;
        contain-intrinsic-size: 20px;
    }

    .content{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        font-weight: 650;

        .select-disease-container{
            width: 100%;
        }


        .submit{
            background-color: #068C69;
            font-weight: 700;
            font-size: 20px;
            color: white;
            text-align: center;
            width: fit-content;
            padding: 8px  12px;
            border-radius:8px;
            border: 2px solid #068C69;

            &:hover{
                cursor: pointer;
                background-color: white;
                color: #068C69;
            }
        }
    }


    

}