.why-choose-kyno {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 108px;
  gap: 1em;
  background: #E9F8F5;
}

.kyno-section_title {
  font-weight: 700;
  font-size: 50px;
  color: #083E3E;
}

.feature-card-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  /* width: 1224px; */
}

.feature-card {
  width: 380px;
  height: 237px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 16px;
  padding-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 24px 24px 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .why-choose-kyno {
    width: auto;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    /* background-color: #f9fdfc; */
    color: #004d40;
  }

  .feature-card-section {
    display: flex;
    flex-wrap: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    gap: 15px;
  }

  .kyno-section_title {
    text-align: center;
    color: black;
    font-size: 28px;
    font-weight: 600;
  }

  .feature-card-section h3 {
    color: #083E3E;
    font-weight: 600;
  }

  .feature-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background: #fff; */
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 8px; */
    /* margin: 5px 0; */
    padding: 15px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    width: 343px;
    height: auto;
    justify-content: start;
    gap: 1em;
  }

  .feature-card-icon {
    font-size: 28px;
    margin-right: 15px;
    color: #00796b;
  }

  .content {
    padding: unset;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
  }

  .content h3 {
    margin: 0 0 5px;
    font-size: 18px;
    color: #004d40;
  }

  .content p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
}