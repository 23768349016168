.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  /* background-color: #f8f9fa; */
  font-family: "Inter", sans-serif;
}

.mobile-container h2 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
  margin-bottom: 8px !important;
}

.mobile-input-wrapper {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  padding: 0px;
  background: white;
  /* width: 360px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.mobile-container .select-container {
  width: 80px;
  margin-right: 8px;
  border: 0.5px solid #ccc;
  border-radius: 12px;
  padding: 5px 0px;
}

.mobile-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  background: inherit;
}

.mobile-container  .otp-button,
.mobile-container  .verify-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  transition: background 0.3s ease;
}

.mobile-container  .otp-button:hover,
.mobile-container  .verify-button:hover {
  background: #0056b3;
}

.otp-boxes {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.otp-input {
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin: 0 5px;
  color: #179d82;
}
.otp-input:focus {
  border: 1px solid #179d8280;
}
.otp-input-have-value {
  border: 1px solid #179d82;
}

.timer-text {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.resend-text {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.resend-text span {
  color: #179d82;
  cursor: pointer;
}
.resend-timer-text {
  color: #179d82;
  font-weight: 600;
}

.otp-button,
.verify-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  transition: background 0.3s ease;
}

.otp-button.disabled,
.verify-button.disabled {
  background: #ccc;
  cursor: not-allowed;
}

.otp-button:hover:not(.disabled),
.verify-button:hover:not(.disabled) {
  background: #0056b3;
}

/* Responsive */
@media (max-width: 400px) {
  .mobile-input-wrapper,
  .otp-input-wrapper {
    width: 90%;
  }

  .otp-button,
  .verify-button {
    padding: 8px 12px;
  }
}

.mobile-container  h3 {
  font-size: 16px;
  margin-top: 0px;
}

.mobile-sub-text {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
}

.input-wrapper {
  display: flex;
  border: 0.5px solid #ccc;
  border-radius: 12px 14px 14px 12px;
  overflow: hidden;
  flex: 1;
}

.second-component-wrapper {
  padding: 8px 16px 18px;
  position: relative;
}

.icon-text-containers {
  padding: 12px 24px;
  display: flex;
  gap: 6px;
  background: #f6f6f6;
  margin-top: 18px;
  width: 100%;
}

.icon-container {
  padding-inline: 6px;
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.icon-container:first-child {
  border-right: 1px solid #ccc;
}
.icon-text {
  padding: 0;
  margin: 0;
  color: #179d82;
  font-weight: 600;
  font-size: 12px;
}

.span-element {
  font-weight: 400;
  font-size: 12px;
}

.verification-text {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 18px;
}
.enter-digit {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #999999;
  margin-bottom: 0;
}
.mobile-container  .mobile {
  margin-bottom: 32px;
}

.mobile-container  .change-btn {
  all: unset;
}

.verify-otp-btn {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 6px;
}

.success-container {
  padding: 32px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-text-typo-1 {
  font-weight: 600;
  font-size: 16px;
  color: #161616;
  margin-bottom: 0;
  margin-top: 24px;
  text-align: center;
}

.success-text-typo-2 {
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: #161616;
}

.booking_slot_new_flow-v2 {
  margin-top: 0px !important;
}

.header-logo {
  padding: 18px;
}

.mobile-container  .w-full {
  width: 100%;
}
