.kyno-container {
  color: #fff;
  font-family: Arial, sans-serif;
  text-align: center;
  background: #e9f7f5;
  // padding: 1rem 0rem 0rem;
}

.comparison-section {
  .title {
    background: url('../Images/otherKyno.png') no-repeat center center/cover;
    // width: 1440px;
    // height: 381px;
    aspect-ratio: 480/127;
    margin-bottom: 0px;
  }

  .comparison-table {
    background: #083E3E;
    display: flex;
    justify-content: space-evenly;
    height: 240px;
    // width: 1440px;

    .comparision_type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .comparision_count {
        display: flex;
        gap: 1em;
        align-items: center;

        .others-time {
          text-decoration: line-through;
          color: #E9F8F5;
          font-size: 40px;
          font-weight: 500;
        }

        .kyno-time {
          color: #FB9F40;
          font-size: 50px;
          font-weight: 700;
        }
      }
    }
  }
}

/* Pricing Section */
.pricing-section {
  background-color: #179D82;
  padding: 30px clamp(30px, 0.5vw, 108px);
  // width: 1440px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  .pricing_title {
    font-size: 40px;
    color: white;
    font-weight: 700;
    width: 500px;
    text-align: start;
  }

  .pricing_card_section {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 650px;
  }

  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    height: auto;
  }

  .consulting_card {
    background-color: #068c69;
    color: white;
    border-radius: 6px;
    text-align: center;
    width: clamp(240px, 1vw, 300px);
    padding: 15px;
    display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-weight: 700;
  }

  .consulting_card .price {
    font-size: 30px;
    font-weight: bold;
  }

  .consulting_card .strike_through {
    text-decoration: line-through;
  }

  .consulting_card .time {
    font-size: 14px;
  }

  .nursing-text {
    margin-top: 1rem;
    font-size: 1rem;
    margin-bottom: -20px;
  }

  .price-highlight {
    color: #ffb300;
    font-weight: bold;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .kyno-container {
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: unset;
  }

  /* Title Section */
  .comparison-section {
    .title {
      background: url('../Images/otherKyno.png') no-repeat center center/cover;
      width: auto;
      // height: 119px;
    }

    .comparison-table {
      display: flex;
      grid-template-columns: 1fr 0.5fr 0.5fr;
      margin: auto;
      flex-direction: column;
      align-items: center;
      width: auto;
      height: auto;
      gap: 20px;
      justify-content: space-between;
      padding: 20px 0 40px;

      .comparision_type {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .comparision_count {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5em;

          .others-time {
            color: rgba(255, 255, 255, 0.6);
            text-decoration: line-through;
            font-size: 24px;
          }

          .kyno-time {
            color: #ffb300;
            font-size: 32px;
          }
        }

      }
    }
  }

  .label {
    color: white;
    text-align: left;
  }

  .value {
    font-size: 1.2rem;
    font-weight: bold;
  }


  /* Pricing Section */
  .pricing-section {
    background-color: #179D82;
    padding: 2rem 1rem;
    width: auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    .pricing_title {
      font-size: 32px;
      margin-bottom: 1rem;
      color: white;
      // width: 343px;
      text-align: center;
    }

    .pricing-cards {
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }

    .pricing_card_section {
      width: 100%;
      margin: 0 auto;
    }

    .consulting_card {
      background-color: #068c69;
      color: white;
      border-radius: 6px;
      padding: 15px;
      text-align: center;
      width: auto;
      margin: 0 auto;
      height: auto;
      height: 90px;
      width: 158px;
    }

    .consulting_card .price {
      font-size: 22px;
      font-weight: bold;
    }

    .consulting_card .time {
      font-size: 0.9rem;
    }

    .nursing-text {
      margin-top: 1rem;
      font-size: 1rem;
      margin-bottom: -10px;
    }

    .price-highlight {
      color: #ffb300;
      font-weight: bold;
    }
  }


}