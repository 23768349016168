.stats-counter {
  display: flex;
  background: #179D82;
  color: white;
  align-items: center;
  justify-content: center;
  gap: 10em;
  padding: 40px 108px;
  // width: 1440px;

  .stat-item {
    margin: 15px 0;
    text-align: center;
  }

  .stat-item h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }

  .stat-item p {
    font-size: 1rem;
    margin: 5px 0 0;
  }
}

@media (max-width: 768px) {
  .stats-counter {
    display: flex;
    flex-direction: column;
    gap: 0em;
    width: auto;
    align-items: center;
    justify-content: center;
    // max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #00a884;
    color: #fff;
    font-family: Arial, sans-serif;
    /* border-radius: 10px; */
  }

  .stats-counter h2 {
    color: white;
  }

  .stat-item {
    margin: 15px 0;
    text-align: center;
  }

  .stat-item h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }

  .stat-item p {
    font-size: 1rem;
    margin: 5px 0 0;
  }

  .star {
    color: #ffeb3b;
    font-size: 1.5rem;
  }
}