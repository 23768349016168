.refund-policy {
    padding: 20px;

    .logo{
        img{
            width: 150px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .title{
        color: #068c69;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
    }

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .subtitle{
            font-size: 24px;
            font-weight: 600;
            color: #f58625;
        }

        .point{
            width: 80%;
            text-align: left;

            .point-title{
                font-weight: 650;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .tnc{
        .content{
            .subtitle{
                font-size: 16px;
            }
        }
    }
}