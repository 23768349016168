.ops-home{
    padding: 20px;


    .title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #068c89;
    }


    .home{

        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logout{
                padding: 5px 12px;
                background-color: rgb(227, 221, 221);
                border-radius: 4px;

                &:hover{
                    cursor: pointer;
                    background-color: grey;
                }
            }


            .title{
                font-size: 20px;
                font-weight: 600;
                color: black;
            }
        }

        .content{
            padding: 20px;
            .content-options{
                display: flex;   
                background-color: white;
                gap: 4px;

                div{
                    flex: 1;
                    background-color: rgb(227, 221, 221);
                    padding: 4px 10px;
                    text-align: center;
                    border-radius: 4px;
                    color: black;

                    &:hover{
                        cursor: pointer;
                        background-color: rgb(99, 99, 99);
                        color: white;
                    }
                    &.active{
                        background-color: rgb(99, 99, 99);
                        color: white;
                    }

                    &.active:hover{
                        background-color: rgb(227, 221, 221);
                        color: black;
                    }
                }
            }
        }
    }
}
