/* General Styling */
.healthy_container {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 40px auto;
  max-width: 90%;
  padding: 20px;
  background: white;
}

.healthy_container-card {
  display: flex;
  gap: 2em;
}

.healthy_container .title {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #00393c;
}

/* Card Styling */
.healthy_container-card .card {
  border: 1px solid #e0f2f1;
  border-radius: 12px;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9fdfc;
  max-width: 600px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #00bfa5;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
  color: #004d40;
}

.card-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .healthy_container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 800px;
    padding: 40px 20px;
    background: white;
  }

  .healthy_container-card {
    display: grid;
    gap: 0rem;
  }

  .healthy_container .title {
    font-size: 32px;
  }

  .healthy_container-card .card {
    padding: 15px;
    width: 100%;
    height: auto;
    display: block;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }

  .card-icon {
    font-size: 2rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-text {
    font-size: 0.85rem;
  }
}