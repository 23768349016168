.form-container {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 100vh;
  background: url('../Images/desktopInteraction.png') no-repeat center center/cover;
  /* background: linear-gradient(to bottom, #e6f7f7, #c1f0f0); */
  padding: 0px 110px 0px 0px;
}

.visit-form {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 25px;
  max-width: 400px;
  width: 100%;
  text-align: left;
}

.booking_title {
  margin: 0 0 20px;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 0.9rem;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  border: 1px solid #f9f9f9;
  border-radius: 6px;
  outline: none;
  transition: border 0.3s;
  background: #f9f9f9;
}

input:focus {
  border-color: #26a69a;
}

/* Phone Input Styling */
/* .phone-input {
  display: flex;
  align-items: center;
  gap: 5px;
} */

/* .country-code {
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 6px 0 0 6px;
  color: #333;
} */

/* .phone-input input {
  border-radius: 0 6px 6px 0;
} */

/* Error Message */
.error-message {
  color: #d32f2f;
  font-size: 0.8rem;
  margin-top: 5px;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #f9a825;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-btn:hover {
  background-color: #f57f17;
}



/* Bottom Sheet Styles */
.bottom-sheet {
  position: fixed;
  bottom: -100%;
  /* Initially hidden */
  left: 0;
  width: 100%;
  height: 30%;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: bottom 0.3s ease-in-out;
  z-index: 1000;
}

/* Open State */
.bottom-sheet.open {
  bottom: 0;
  /* Slide up */
}

/* Sheet Content */
.sheet-content {
  padding: 20px;
}

.sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sheet_title {
  color: black;
  font-weight: 600;
}

.sheet-content h2 {
  margin-top: 0;
  font-size: 20px;
}

.sheet_description {
  font-size: 12px;
}

.number_btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify_number {
  background: blue;
  border-radius: 30px;
  padding: 10px;
  border: none;
  color: white;
  text-align: center;
  width: 100%;
}


/* Appointment Container */
.appointment-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background: white;
  height: 62vh;
  border-radius: 12px;
}

.appointment_title{
  color: black;
  font-weight: 600;
}

.appointment-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.field-control{
  border-radius: 5px;
  border: 1px solid white;
  padding: 10px;
}

.time-options {
  display: flex;
  gap: 10px;
}

.btn.enable {
  background-color: #179D82;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn.disable {
  background-color: white;
  color: black;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid lightgray;
}

button:focus {
  outline: none;
}

button:focus-visible {
  outline: auto; /* Keeps focus outline for keyboard users */
}

.book-now {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.visit_fee{
  font-size: 12px;
  color: #333333;
}

.bottom_section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.bottom-sheet2 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px;
  max-height: 50%;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll-container {
  display: flex;
  gap: 10px;
  overflow: hidden;
}

.scroll-column {
  flex: 1;
  height: 150px;
  overflow-y: auto;
  text-align: center;
  /* border: 1px solid #ddd; */
  /* border-radius: 10px; */
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

.scroll-item {
  padding: 2px;
  cursor: pointer;
}

.scroll-item:hover {
  background-color: #f0f0f0;
}

.confirm-btn {
  background-color: #179D82;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width:768px){
  .form-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    min-height: 100vh;
    background: url('../Images/Booking\ form-\ mobile.png') no-repeat center center/cover;
    /* background: linear-gradient(to bottom, #e6f7f7, #c1f0f0); */
    padding: 20px;
  }
  
  .visit-form {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 25px;
    max-width: 400px;
    width: 100%;
    text-align: left;
  }
  
  .booking_title {
    margin: 0 0 20px;
    color: #333333;
    font-size: 1rem;
    font-weight: 600;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 0.9rem;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #f9f9f9;
    border-radius: 6px;
    outline: none;
    transition: border 0.3s;
    background: #f9f9f9;
  }
  
  input:focus {
    border-color: #26a69a;
  }
  
  /* Phone Input Styling */
  /* .phone-input {
    display: flex;
    align-items: center;
    gap: 5px;
  } */
  
  /* .country-code {
    background: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 6px 0 0 6px;
    color: #333;
  } */
  
  /* .phone-input input {
    border-radius: 0 6px 6px 0;
  } */
  
  /* Error Message */
  .error-message {
    color: #d32f2f;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  
  /* Submit Button */
  .submit-btn {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: #f9a825;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #f57f17;
  }
  
  
  
  /* Bottom Sheet Styles */
  .bottom-sheet {
    position: fixed;
    bottom: -100%;
    /* Initially hidden */
    left: 0;
    width: 100%;
    height: 30%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: bottom 0.3s ease-in-out;
    z-index: 1000;
  }
  
  /* Open State */
  .bottom-sheet.open {
    bottom: 0;
    /* Slide up */
  }
  
  /* Sheet Content */
  .sheet-content {
    padding: 20px;
  }
  
  .sheet-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .sheet_title {
    color: black;
    font-weight: 600;
  }
  
  .sheet-content h2 {
    margin-top: 0;
    font-size: 20px;
  }
  
  .sheet_description {
    font-size: 12px;
  }
  
  .number_btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verify_number {
    background: blue;
    border-radius: 30px;
    padding: 10px;
    border: none;
    color: white;
    text-align: center;
    width: 100%;
  }
  
  
  /* Appointment Container */
  .appointment-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background: white;
    height: 62vh;
    border-radius: 12px;
  }
  
  .appointment_title{
    color: black;
    font-weight: 600;
  }
  
  .appointment-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .field-control{
    border-radius: 5px;
    border: 1px solid white;
    padding: 10px;
  }
  
  .time-options {
    display: flex;
    gap: 10px;
  }
  
  .btn.enable {
    background-color: #179D82;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn.disable {
    background-color: white;
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid lightgray;
  }
  
  button:focus {
    outline: none;
  }
  
  button:focus-visible {
    outline: auto; /* Keeps focus outline for keyboard users */
  }
  
  .book-now {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }
  
  .visit_fee{
    font-size: 12px;
    color: #333333;
  }
  
  .bottom_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }
  
  .bottom-sheet2 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 20px;
    max-height: 50%;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .scroll-container {
    display: flex;
    gap: 10px;
    overflow: hidden;
  }
  
  .scroll-column {
    flex: 1;
    height: 150px;
    overflow-y: auto;
    text-align: center;
    /* border: 1px solid #ddd; */
    /* border-radius: 10px; */
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }
  
  .scroll-item {
    padding: 2px;
    cursor: pointer;
  }
  
  .scroll-item:hover {
    background-color: #f0f0f0;
  }
  
  .confirm-btn {
    background-color: #179D82;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }  
}


.phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  background: #f9f9f9;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  font-family: Arial, sans-serif;
}

.country-code {
  color: #555;
  font-size: 1rem;
  margin-right: 10px;
}

.phone-input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
  color: #666;
  background: transparent;
}

.phone-input::placeholder {
  color: #bbb;
}

.phone-input-container:hover,
.phone-input-container:focus-within {
  border-color: #aaa;
}

.phone-input-container:focus-within .phone-input {
  color: #333;
}