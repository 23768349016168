  .patient_card {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 108px 108px;
    height: 472px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 80px;
    background-image: url("./../../ServiceList/Images/footerDesktopImage.png");
    background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
  }
  
  .patient_card-title {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    width: 668px;
    height: 144px;
  }
  
  .highlight {
    color: #004d40;
  }
  
  .patient_card-description {
    font-size: 1em;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 15px;
    width: 668px;
  }
  
  .patient_card-availability {
    background-color: #e6f7f1;
    color: #00897b;
    font-weight: bold;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  
  .cities {
    font-weight: bold;
  }
  
  .book-button {
    background-color: #ffa000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .book-button:hover {
    background-color: #ff8f00;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .patient_card {
      background-color: #fff;
      text-align: center;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 30px 12px;
      display: block;
      height: auto;
      margin-top: 60px;
    }
    
    .patient_card-title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
      width: auto;
      height: auto;
    }
    
    .highlight {
      color: #004d40;
    }
    
    .patient_card-description {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 15px;
      width: auto;
    }
    
    .patient_card-availability {
      background-color: #e6f7f1;
      color: #00897b;
      font-weight: bold;
      display: inline-block;
      padding: 8px 12px;
      border-radius: 15px;
      margin-bottom: 20px;
    }
    
    .cities {
      font-weight: bold;
    }
    
    .book-button {
      background-color: #ffa000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .book-button:hover {
      background-color: #ff8f00;
    }
  }
  