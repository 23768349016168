.active-lead{
    display: flex;
    padding: 10px 16px;
    height: 100%;

    div{
        flex: 1;
    }

    .details-1{

        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        padding: 80px 30px;
        div{
            // display: flex;
            flex: none;
            // flex-direction: column;

            div{
                padding: 4px 10px;
            }

            div:nth-child(1){
                background-color: rgb(99, 99, 99);
                color: white;
                border-radius: 4px 4px 0px 0px;
            }
            div:nth-child(2){
                background-color: rgb(227, 221, 221);
                color: black;
                border-radius: 0px 0px 4px 4px;
            }

        }
    }

    .details-2{

        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        padding: 80px 30px;
        div{
            // display: flex;
            // flex-direction: column;
            // display: flex;
            // flex-direction: column;

            flex: none;

            div{
                padding: 4px 10px;
            }

            div:nth-child(1){
                background-color: rgb(99, 99, 99);
                color: white;
                border-radius: 4px 4px 0px 0px;
            }
            div:nth-child(2){
                background-color: rgb(227, 221, 221);
                color: black;
                border-radius: 0px 0px 4px 4px;
            }

        }
    }
}

.popup-content{
    height: 600px;
    width: 90% !important;
}