.footer-mobile {
  display: none;
}

/* Hero Section Styling */
.footer-hero-section {
  // background: url('../Images/footerDesktopImage.png') no-repeat center center/cover;
  background-color: #003d3c;;
  color: #ffffff;
  text-align: center;
  padding: 40px 40px;
  position: relative;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 450px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.site_list a{ 
  text-decoration: none;
}

.hero-button {
  background-color: #ff9f29;
  color: #ffffff;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hero-button:hover {
  background-color: #f0861b;
}

/* Footer Styling */
.hero-footer {
  color: #ffffff;
  padding: 1.5rem 1rem;
  position: absolute;
  bottom: 0;
  // width: 100%;
  display: flex;
  align-items: end;
  gap: 1rem;
}

.footer-content {
  width: 373px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1em;
}

.footer-content .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.footer-content .logo img {
  width: 142px;
  height: 41px;
}

.footer-description-desktop{
  text-align: start;
}

.footer-links a,
.footer-socials a {
  margin: 0 0.5rem;
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-links a:hover,
.footer-socials a:hover {
  text-decoration: underline;
}

.footer-socials a {
  font-size: 1.2rem;
}

.footer-copyright {
  font-size: 0.8rem;
}

/* Responsive Design */

@media (max-width:768px) {
.footer-hero-section{
  display: none;
}
  /* Footer container */
  .footer-mobile {
    display: flex;
    background-color: #003d3c;
    /* Dark teal background */
    color: #ffffff;
    /* White text */
    padding: 20px 10px;
    text-align: center;
    font-family: Arial, sans-serif;

    /* Footer content layout */
    .footer-content {
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: start;
      background-color: #003d3c;

    }

    /* Logo styling */
    .logo-text {
      font-size: 24px;
      font-weight: bold;
      color: #00d084;
      display: flex;
    }

    .logo-highlight {
      color: #ff8500;
      /* Orange */
    }

    .logo-icon {
      margin-right: 5px;
    }

    /* Footer description */
    .footer-description {
      margin: 10px 0 20px;
      font-size: 14px;
      line-height: 1.5;
      color: #e0e0e0;
      text-align: left;
    }

    /* Sitemap */
    .sitemap h3 {
      margin: 10px 0;
      font-size: 16px;
      color: #ffffff;
      text-align: left;
    }

    .sitemap ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .sitemap li {
      margin: 5px 0;
      font-size: 14px;
      color: #e0e0e0;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .sitemap li:hover {
      color: #00d084;
    }

    /* Social Icons */
    .social-icons {
      margin: 15px 0;
    }

    .social-icons .icon {
      font-size: 18px;
      margin: 0 5px;
      display: inline-block;
      cursor: pointer;
      transition: transform 0.3s ease;
      background: #003d3c;
    }

    .social-icons .icon:hover {
      transform: scale(1.2);
    }

    /* Footer copyright */
    .footer-copyright {
      margin-top: 10px;
      font-size: 12px;
      color: #b0b0b0;
      text-align: left;
    }
  }


}