.cuf-main{
    display: flex;
    flex-direction: row;
    padding: 20px 160px;
    gap: 60px;

}

.leftbar > img{
    width: 400px;
}


.rightbar {
    padding-top: 40px;
}

.rightbar > .rightbar-title{
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0px;
}

.rightbar > .rightbar-desc {
    font-size: 10px;
    font-weight: 400;
    opacity: 0.75;
    margin: 10px 0px;
    width: 66%;
    word-spacing: 4px;
}


.rightbar > .rightbar-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 60px;
    padding-top: 12px;
}

.rightbar-content-left{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rightbar-content-left > div{
    display: flex;
    gap: 10px;
    opacity: 0.75;
    font-size: 12px;
}

.rightbar-content-right{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rightbar-content-right > div{
    display: flex;
    gap: 10px;
    opacity: 0.75;
    font-size: 12px;
}


@media screen and (max-width: 1100px){
    .cuf-main{
        padding: 20px 120px;
    }
}
@media screen and (max-width: 1000px){
    .cuf-main{
        padding: 20px 110px;
    }
}
@media screen and (max-width: 800px){
    .cuf-main{
        padding: 20px 80px;
        gap: 40px;
    }
    
    .leftbar{
        padding-top: 20px;
    }
    .rightbar{
        padding-top: 0px;
    }
    .rightbar > .rightbar-title{
        font-size: 24px;
    }
    .leftbar > img{
        width: 300px;
    }
    
    .rightbar > .rightbar-content{
        padding-right: 20px;
    }

    .rightbar-content-left > div{
        gap: 4px;
    }
}


@media screen and (max-width: 600px){

    .cuf-main{
        flex-direction: column-reverse;
    }

    .leftbar > img{
        width: 100%;
    }
    .rightbar > .rightbar-title{
        font-size: 32px;
    }
    .rightbar > .rightbar-desc{
        font-size: 14px;
    }

    .rightbar-content{
        justify-content: normal;
        gap: 40px;
    }

}
@media screen and (max-width: 500px){

    .cuf-main{
        padding: 20px 40px;
    }

    /* .rightbar > .rightbar-title{
        font-size: 32px;
    }
    .rightbar > .rightbar-desc{
        font-size: 14px;
    }

    .rightbar-content{
        justify-content: normal;
        gap: 40px;
    } */

}