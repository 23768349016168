@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        -ms-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

.ad-cms{
    .title{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo{
            width: 40%;
            img{
                width: 100%;
            }
        }

        .call-btn{
            display: flex;
            gap: 20px;
            color: #068c69;
            border: 3px solid #068c69;
            border-radius: 24px;
            padding: 6px 13px;
            align-items: center;
            font-weight: 600;

            svg{
                width: 20px;
                -webkit-animation: ring 2s ease infinite;
                animation: ring 2s ease infinite;
            }

            &:hover{
                cursor: pointer;
                background-color: #068c69;
                color: white;
                svg{
                    color: white;
                    fill: white;
                }
            }
        }   
    }

    .main-banner{
        // background-image: url("../utilities/images/ad_cms/heading_frame.png");
        img{
            position: absolute;
            width: 100%;
        }
        .oc1{
            display: none;
        }
        .content{
            position: relative;
            padding: 20px;
            .title{
                padding: 0px;
                font-size: 20px;                
                font-weight: 700;
            }
            .advert{
                font-size: 18px;
                font-weight: 700;
                opacity: 0.8;
                margin-top: 16px;

                span{
                    color: #068c69;
                    img{
                        width: 20px;
                        margin: 5px;
                        color: yellow;
                    }
                }
            }

            .advert-points{
                margin-top: 32px;
                font-size: 12px;
                font-weight: 700;
                opacity: .9;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .point{
                    display: flex;
                    gap: 8px;
                    .radio{
                        background-color: #F8AF22;
                        padding: 8px;
                        border-radius: 30px 0px 0px 30px;
                    }
                }
            }
        }
    }

    .price-banner{
        display: flex;
        padding: 8px 12px;
        background-color: grey;
        font-weight: 700;
        font-size: 20px;
        color: white;
        border-radius: 8px;
        margin: 0px 0px 0px 0px;
        justify-content: space-evenly;
        position: relative;
        width: 100%;
        align-items: center;
        text-shadow: 0px 2px rgba(0, 0, 0, 0.322);
        div:nth-child(1){
            width: 40%;
        }
        div:nth-child(2){
            font-size: 30px;
        }
    }

    .doc-availability{
        background-color: #F8AF22;
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        position:  relative;
    }

    .cta-form{
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0px;
        gap: 20px;
        background-color: rgba(0, 0, 0, 0.15);
        .cta{
            width: 100%;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.491);
            background-color: #068c69;
            padding: 8px 12px;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            color: white;
            span:nth-child(1){
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                svg{
                    width: 16px;
                    fill: white;
                    -webkit-animation: ring 2s ease infinite;
                    animation: ring 2s ease infinite;
                }
            }

            span:nth-child(2){
                text-align: center;
                font-size: 20px;
                font-weight: 600;
            }
        }

        .or{
            text-align: center;
            font-size: 20px;
            font-weight: 700;
        }

        .request-callback{
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: #068C69;
            text-shadow: 0px 2px rgba(128, 128, 128, 0.432);
        }

        input{
            width: 90%;
            padding: 10px 20px;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        .locality-select{
            width: 90%;
            padding: 10px 20px;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border: 2px solid black;
            color: rgba(0, 0, 0, 1);
        }

        .msg{
            font-size: 20px;
            font-weight: 600;
        }

        .submit{
            width: 50%;
            padding: 10px;
            background-color: #F8AF22;
            border-radius: 20px;
            text-align: center;
            color: black;
            font-weight: 700;
            font-size: 18px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .sub-headings{
        position: relative;
        top: 50px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .sub-heading{
            padding: 20px;
            .heading-title{
                font-size: 18px;
                font-weight: 600;
            }
            .heading-point{
                font-weight: 600;
                font-size: 14px;
                opacity: 0.7;
                display: flex;
                align-items: flex-start;
                gap: 16px;

                img{
                    padding-top: 7px;
                }
            }

            &:nth-child(2n+1){
                background-color: #d4f5ef;
            }
            &:nth-child(2n){
                background-color: #faf3e6;
            }

        }
    }

    .cta-buttons{
        padding: 20px;
        position: relative;
        top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .request-callback{
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: black;
            text-shadow: 0px 2px rgba(128, 128, 128, 0.432);
        }
        .request-callback-1{
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            color: black;
            text-shadow: 0px 2px rgba(128, 128, 128, 0.432);
        }

        input{
            width: 90%;
            padding: 10px 20px;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .msg{
            font-size: 20px;
            font-weight: 600;
        }

        .submit{
            width: 50%;
            padding: 10px;
            background-color: #F8AF22;
            border-radius: 20px;
            text-align: center;
            color: black;
            font-weight: 700;
            font-size: 18px;

            &:hover{
                cursor: pointer;
            }
        }


        .cta-call{
            width: 100%;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.491);
            background-color: #068c69;
            padding: 8px 12px;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            color: white;
            span:nth-child(1){
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                svg{
                    width: 16px;
                    fill: white;
                    -webkit-animation: ring 2s ease infinite;
                    animation: ring 2s ease infinite;
                }
            }

            span:nth-child(2){
                text-align: center;
                font-size: 20px;
                font-weight: 600;
            }
        }

        .cta-whatsapp{
            display: flex;
            align-items: center;
            gap: 30px;
            font-size: 24px;
            font-weight: 600;
            justify-content: center;
            background-color: #F8AF22;
            border-radius: 30px;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.283);
            svg{
                width: 36px;
            }
        }
    }

    .ad-cms-footer{
        position: relative;
        top: 50px;
        margin-bottom: 50px;
    }
    
    .footer{
        position: relative;
        // margin-bottom: 50px;
        top: 50px;
    }

    .numbers{
        background-color: #AC7100;
        display: flex;
        position: relative;
        top: 50px;
        padding: 12px;
        gap: 10px;
        justify-content: space-evenly;

        div{
            color: white;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            div:nth-child(2){
                font-size: 12px;
                text-align: center;
            }
        }

        .consultations, .doctors{
            border-right: 2px solid white;
        }
        .consultations, .doctors, .google{
            padding: 10px;
        }
    }

    .testimonial-faq{
        position: relative;
        top: 50px;
        background-color: rgba(0, 0, 0, 0.07);
        .tmonial{
            padding: 20px 20px 0px 20px;
            .tmonial-title{
                text-align: center;
                font-size: 28px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .tmonial-video{
                background-color: white;
                height: 100px;

                iframe{
                    width: -webkit-fill-available;
                }
            }
        }

        .faq{
            margin-top: 20px;
        }
    }

    .social-media-handles{
        margin-bottom: 50px;
    }

}


.sticky-bottom {
    display: none;
}


@media screen and (max-width: 500px) {
    .sticky-bottom {
        display: flex !important;
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 10;
    }

    .sticky-bottom>div {
        flex: 1;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    // .sticky-bottom>div:nth-child(1) {
    //     background-color: white;
    //     color: #068C69;
    //     font-weight: 700;
    //     box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px 8px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    // }

    .sticky-bottom>div:nth-child(1) {
        background-color: #068C69;
        color: white;
        font-weight: 700;
        box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px 8px, rgb(0 0 0 / 30%) 0px 3px 7px -3px
    }

    .sticky-bottom>div:nth-child(1)>svg {
        width: 28px;
        height: 20px;
        margin-right: 16px;
    }

    .sticky-bottom > .phone > svg {
        -webkit-animation: ring 2s ease infinite;
        animation: ring 2s ease infinite;
        /* transform-origin-x: 50%;
        transform-origin-y: 0px;
        transform-origin-z: initial */
    }
}


@media screen and (min-width: 1000px) {
    .ad-cms{
        padding: 20px 160px 0px 160px;
        .title{
            padding: 0px;
            .logo { 
                img{
                    width: 25%;
                }
            }
        }

        .main-banner{
            padding: 20px 0px;
            height: 480px;
            filter: drop-shadow(-400px -90px 600px #068C69);
            .banner{
                display: none;
            }
            .oc1{
                display: block;
                width: 42%;
                right: 20px;
            }
            .content{
                padding: 20px 0px;
                .title{
                    font-size: 24px;
                }

                .advert-points {
                    .point{
                        font-size: 16px;
                    }
                }
            }
        }

        .social-media-handles{
            margin-bottom: 0px;
            margin-top: 40px;
        }

        .testimonial-faq {
            .tmonial {
                .tmonial-video{
                    text-align: center;
                    height: 300px;

                    iframe{
                        width: 400px;
                        height: 300px;
                    }
                }
            }
        }
    }
}