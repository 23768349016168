.faqs{
    padding: 20px 160px;
}

.faqs > .title{
    font-size: 36px;
    font-weight: 700;
}

.faqs > .content{
    padding-top: 20px;
}

/* .faqs > .content > .faq-box{
    /;
} */

.faqs > .content > .faq-box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 20px; */
}

.faqs > .content > .faq-box > .t{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* float: left; */
    /* width: 40%; */
    margin-bottom: 20px;
    margin-right: 10%;
    /* width: 40%; */
    /* padding-top: 20px; */
}

.accordion-button{
    font-weight: 600;
}
.accordion-button::after{
    background-image: url("../utilities/images/plus.png") !important;
}

.accordion-button:not(.collapsed)::after{
    background-image: url("../utilities/images/minus.png") !important;
}

.accordion-button:not(.collapsed){
    color: black;
}

@media screen and (max-width: 1100px) {
    .faqs{
        padding: 20px 120px;
    }
}
@media screen and (max-width: 1000px) {
    .faqs{
        padding: 20px 100px;
    }
}
@media screen and (max-width: 800px) {
    .faqs{
        padding: 20px 80px;
    }
    
    .accordion-button{
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .faqs{
        padding: 20px 40px;
    }
}
@media screen and (max-width: 500px) {
    .faqs > .content > .faq-box{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* gap: 20px; */
    }

    .faqs> .content{
        padding-top: 10px;
    }
}