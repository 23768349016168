.sales-all-appointments{
    
    .filter-bar{
        height: 80px;
        width: 100%;
        background-color: rgba(255, 192, 203, 0.3137254902);;
    }

    .content{

        overflow-x: scroll;
        overflow-y: scroll;

        .headers{
            background-color: rgba(255, 192, 203, 0.9);
            padding: 8px 12px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            font-size: 12px;
            width: 120%;

            div{
                text-align: center;
            }

            .sno{
                width: 6%;
            }
            .patient-name{
                width: 10%;
            }
            .city{
                width: 8%;
            }
            .appointment-date{
                width: 12%;
            }
            .appointment-status{
                width: 12%;
            }
            .fees-charged{
                width: 6%;
            }
            .appointment-type{
                width: 8%;
            }
            .doctor{
                width: 8%;
            }
            .bd-name{
                width: 8%;
            }
            .payment-status{
                width: 8%;
            }
            .open-appointment{
                width: 8%;
            }
        }

        .appointment{
            padding: 8px 12px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            font-size: 12px;
            width: 120%;

            div{
                text-align: center;
            }

            .sno{
                width: 6%;
            }
            .patient-name{
                width: 10%;
            }
            .city{
                width: 8%;
            }
            .appointment-date{
                width: 12%;
            }
            .appointment-status{
                width: 12%;
            }
            .fees-charged{
                width: 6%;
            }
            .appointment-type{
                width: 8%;
            }
            .doctor{
                width: 8%;
            }
            .bd-name{
                width: 8%;
            }
            .payment-status{
                width: 8%;
            }
            .open-appointment{
                width: 8%;

                &:hover{
                    background-color: rgba(128, 128, 128, 0.4);
                    cursor: pointer;
                    padding: 4px 10px;
                    border-radius: 8px;
                }
            }

            &:nth-child(2n){
                background-color: rgba(128, 128, 128, 0.3);
            }
        }
    }
}