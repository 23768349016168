  .visit_container {
    display: flex;
    margin-top: 80px;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    background-color: rgb(249, 249, 249) !important;
    background-image: url("../Images/banner_kh_dp.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 0px 0px 0%;
    aspect-ratio: 2/1;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 40%; /* Adjust the width of the gradient */
      height: 100%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
      pointer-events: none;
    }
    
    .patient-carousel {
      display: none;
    }

    .main-menu {
      display: none;
    }
    .booking_slot_new_flow .feature-list {
      display: none;
    }
    .booking_slot_new_flow {
      padding: 50px 0;
      background-color: transparent !important;
      max-width: 100% !important;
      height: auto !important;
      h2 {
        color: black;
      }
      img {
        // border-radius: 20px;
        border: none !important;
      }
      .input_custom_wrapper {
        display: none !important;
      }
      .carousel-container {
        display: none;
      }
    }
    .onlyDesktop {
      z-index: 1;
      .carousel-container {
        display: none;
      }
      padding: 0px 70px 0 0px;
      .booking_slot_new_flow {
        padding: 0px 0;
        background-color: transparent !important;
        // max-width: 100% !important;
        height: auto !important;
        .input_custom_wrapper {
          background: rgb(255 255 255 / 70%);
          max-width: 450px !important;
          width: 450px !important;
          display: flex !important;
          border-radius: 16px;
        }
      }
    }

    .booking_slot_new_flow .carousel-container {
      margin-bottom: 0px;
      width: 100%;
      /* Adjust the value as per your layout needs */
  }
  
   .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
   .slick-slide img {
      width: 100%;
      /* Ensure image takes full width of the container */
      height: auto;
      /* Maintain aspect ratio */
      object-fit: contain;
      /* Ensure the image covers the entire container */
      max-height: 400px;
      border: 1px solid black;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      /* You can set a max-height based on your needs */
  }
  
   .slick-prev:before,
   .slick-next:before {
    content: ''; /* Remove the default arrow content */
  }
  
   .slick-prev,
   .slick-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px; /* Circle width */
    height: 25px; /* Circle height */
    border-radius: 50%; /* Makes it a circle */
    background-color: gray; /* Background color */
    z-index: 1;
  }
  
   .slick-prev {
    left: 10px; /* Adjust inside slider */
  }
  
   .slick-next {
    right: 10px; /* Adjust inside slider */
  }
  
   .slick-prev:after,
   .slick-next:after {
    content: '';
    display: inline-block;
    width: 10px; /* Arrow width */
    height: 10px; /* Arrow height */
    border-top: 2px solid black; /* Arrow styling */
    border-right: 2px solid black;
    transform: rotate(135deg); /* Rotate for arrow direction */
  }
  
   .slick-prev:after {
    transform: rotate(-135deg); /* Left arrow */
  }
  
   .slick-next:after {
    transform: rotate(45deg); /* Right arrow */
  }
    .visit_card {
      // background-color: #fff;
      padding: 0px 70px 0 0px;
      width: 720px;
      display: flex;
      flex-direction: column;
      align-items: start;
      height: auto;
      z-index: 1;
    }
    
    .visit_title {
      color: #fff;
      font-size: 50px;
      font-weight: bold;
      // margin-bottom: 20px;
    }
    
    .highlight {
      // background: #f49c3f;
      padding: 5px;
      // border-radius: 10px;
      color: white;
      font-size: 60px;
      // line-height: 0px;
    }
    
    .description {
      font-size: 1em;
      color: #333;
      line-height: 1.5;
      margin-bottom: 15px;
    }
    
    .availability {
      color: #C8E7E1;
      background-color: transparent;
      display: inline-block;
      border-radius: 15px;
      margin: 10px 70px 20px 108px;
      z-index: 1;
      // padding: 50px 70px 0 0px;
      // width: 720px;
  //     display: flex
  // ;
  //     flex-direction: column;
  //     align-items: start;
      height: auto;
      // padding: 50px 70px 0 108px;

    }
    
    .cities {
      // font-weight: bold;
    }
    
    .book-button {
      background-color: #ffa000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .book-button:hover {
      background-color: #ff8f00;
    }
  
    .doc_image_section{
      width: 720px;
      height: 480px;

      display:none;
      // background: white;
      .doctor-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  

  }
  

  
  /* Responsive Styling */
  @media (max-width: 768px) {

    .visit_container {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      width:auto;
      background-image: unset;
      height: auto;
      padding: 0;
      margin-top: 57px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40%; /* Adjust the width of the gradient */
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
        pointer-events: none;
        display: none;
      }
      .highlight {
        font-size: 32px;
        color: #00303f;
      }
      .booking_slot_new_flow {
        padding: 0px 0 20px;
        background-color: rgb(249, 249, 249) !important;
        .input_custom_wrapper {
          // margin-top: 20px;
          display: flex !important;
        }
      }
      .onlyDesktop {
        display: none;
        .carousel-container {
          display: block;
        }
        .input_custom_wrapper {
          display: none;
        }
      }

      .visit_card{
        padding: 32px 10px 0px;
        display: flex;
        flex-direction: column;
        // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        align-items: center;
        width: 100%;
        height: auto;
        position: relative;
        height: 40vh;
        background-image: url("../Images/banner_mobile_kh.png");
        justify-content: end;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .visit_card::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 40%; /* Adjust the height of the blur */
        background: linear-gradient(to top, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0));
        // filter: blur(40px); /* Adjust blur intensity */
        pointer-events: none;
      }
      .visit_title {
        font-size: 28px;
        margin-bottom: 0;
        z-index: 1;
        text-align: center;
        color: #00303f;
      }
    
      .description {
        font-size: 0.9em;
        margin-bottom: 15px;
        color: #083E3E;
        text-align: center;
      }
      .availability{
        margin: 0 auto;
        margin-bottom: 15px;
        border: 1px solid #179D82;
        font-size: medium;
        color: #179D82;
        // padding: 0px;
        padding: 8px 12px;
        z-index: 1;
      }
    
      .book-button {
        padding: 8px 16px;
        width: 95%;
        border-radius: 10px;
      }

      .doc_image_section{
        width: 100%;
        height: auto;
        background: white;
        display: block;
        .carousel-container {
          display: none;
        }
        .doctor-image{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0px;
        }
      }
    }

  }
  