.sales-team-management {
  padding: 20px;
  background-color: #f9f7f7;

  .sales-employees {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // background-color: white;
    border-radius: 12px;

    .headers {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      div {
        flex: 1;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .sales-employee {
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      div {
        font-size: 16px;
        font-weight: 550;
        flex: 1;
      }
      .status {
        span {
          padding: 4px 10px;
          color: white;
          background-color: red;
          width: fit-content;
          border-radius: 12px;
          &.active {
            background-color: green;
          }
        }
      }
    }
  }
}
