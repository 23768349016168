.faqs-container {
    padding: 40px 20px;
    background: #f5faf9;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faqs-heading {
    text-align: center;
    font-size: 50px;
    color: #004d40;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .faqs-list{
    width: 800px;
    /* height: 416px; */
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 10px;
    cursor: pointer;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question h3 {
    font-size: 1.1rem;
    margin: 0;
    color: #00796b;
  }
  
  .arrow {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 0.95rem;
    color: #555;
    line-height: 1.5;
  }

  .faq-answer p{
    color: #083E3E;
  }
  
  .view-all-btn {
    margin-top: 20px;
    background-color: white;
      color: #083E3E;
      border: 1px solid #083E3E;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
  }
  
  .view-all-btn:hover {
    background-color: #00796b;
    color: white;
  }
  
  @media (max-width:768px){
    .faqs-container {
      max-width: 600px;
      border-radius: 10px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      font-family: Arial, sans-serif;
    }
    
    .faqs-heading {
      text-align: center;
      font-size: 2rem;
      color: #004d40;
      margin-bottom: 20px;
    }

    .faqs-list{
      width: 343px;
      /* height: 524px; */
    }
    
    .faq-item {
      border-bottom: 1px solid #ddd;
      padding: 15px 10px;
      cursor: pointer;
    }
    
    .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .faq-question h3 {
      font-size: 1.1rem;
      margin: 0;
      color: #00796b;
    }
    
    .arrow {
      font-size: 1.2rem;
      transition: transform 0.3s ease;
    }
    
    .arrow.open {
      transform: rotate(180deg);
    }
    
    .faq-answer {
      margin-top: 10px;
      font-size: 0.95rem;
      color: #555;
      line-height: 1.5;
    }
    
    .view-all-btn {
      /* background-color: white;
      color: #083E3E;
      border: 1px solid #083E3E;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer; */
    }
    
    .view-all-btn:hover {
      background-color: #00796b;
    }
  }