.book-visit img {
    width: 500px;
    height: 250px;
}.book-visit .slick-slider {
    margin-left: 0px;
    margin-top: 0px; z-index: 999;
}
.book-visit .slick-arrow {
    position: absolute;
    z-index: 9;
    border: 0;
    background-repeat: no-repeat;
    font-size: 0;
    top: 100%;
    width: 40px !important;
    height: 40px !important;
    padding: 0;
    cursor: pointer;
    background-color: #FB9F40!important;
    background-size: 18px;
    background-position: center;
    border-radius: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none!important;
}

.book-visit .slick-prev {
    left: -2%!important;
    background-image: url(../images/test-left.png);
}
.book-visit .slick-next {
    right: 2%;
    background-image: url(../images/test-right.png);
}

.book-visit .offer {
    background: #117370;
    border-radius: 12px;
    padding: 25px 10px 25px 10px;

    overflow: hidden;
    position: relative;
}
.book-visit .offer h6{
    color: #548c8a; font-size: 48px;font-family: 'GalanoGrotesqueBold', sans-serif;
}
.book-visit .offer h3 {
    font-size: 12px;
    color: #fff;
    line-height: 30px;
    font-weight: 500;
}
.book-visit .offer h3 span {
    display: block;
}
.book-visit .offer p {
font-size: 13px; color: #fff;}

.bav img{width: 100%;
    height: inherit;}


.book-visit .offer::before {
  position: absolute;
    content: "";
    top: 5px;
    z-index: 1;
    width: 100%;
    height: 100%;
    right: 0;
    background-position: top right; background-size: 35%;
}


/* .book-visit .offer::after {
    background-image: url(../images/Ellipse-3035.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -17px;
    width: 118px;
    height: 100%;
    right: -21px;
    transform: rotate(-0.35turn);
} */

.book-visit .offer::after {
    background-image: url(../images/bx_bxs-check-shield.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 40px;
    width: 42px;
    height: 42px;
    right: 93px;
    background-position: center center;
} 
