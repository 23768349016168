.thrd-fold{background: #F7F7F7;}
.thrd-fold::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 859px;
    height: 100%;
    left: 7px;
    background-size: 82%;
}

.thrd-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
       position: relative;
    z-index: 9; margin-bottom: 40px;font-family: 'GalanoGrotesqueBold', sans-serif;

}
.programitem {
    padding: 50px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;min-height: 360px;
}
.programitem h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
}
.programitem p {
    color: #646464;
    line-height: 1.7;
    margin: 0;
}
.programitem img {
    margin-bottom: 40px;max-height: 80px;
}

@media only screen and (max-width: 767px) { 

  
    .thrd-fold::before {
        width: 100% !important;
        height: 173%;
        left: 7px;
        background-size: 123% !important;
    }
    .programitem {
        padding: 20px 10px 20px 80px!important;
        width: 100%;
        text-align: left;min-height: inherit;
        border-radius: 12px;
    }
    .programitem img {
        margin-bottom: 40px;
        max-height: 60px;
        position: absolute;
        left: 15px;
    }
    .programitem h4 {
        margin-bottom: 10px!important;
    }
    .programitem p {
     
        font-size: 14px;
    }
    .thrd-fold h2 {
        font-size: 29px;       
    }
    
    }