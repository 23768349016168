.successfull_container {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.success-icon {
    font-size: 50px;
    color: #28a745;
    margin-bottom: 20px;
}

.heading {
    font-size: 24px;
    font-weight: bold;
    color: #0f2d3f;
    margin-bottom: 20px;
}

.details {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.details div {
    background: #e8f7f0;
    padding: 10px 20px;
    border-radius: 8px;
}

.details div span {
    display: block;
    font-weight: bold;
    font-size: 18px;
    color: #0f2d3f;
}

.details div small {
    color: #6c757d;
}

.info {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 20px;
}

.info a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.button {
    display: inline-block;
    background: #ff9900;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
}

.button:hover {
    background: #e68a00;
}

@media (max-width: 600px) {
    .details {
        flex-direction: row;
        gap: 10px;
    }

    .details div {
        padding: 10px;
    }
}