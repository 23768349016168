.health-concerns {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 50px;

  .doctor-header {
    .header-content {
      h2 {
        color: #083E3E;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .scrollable-cards {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .view-all {
    display: none;
  }
}




@media (max-width: 768px) {

  .health-concerns {
    font-family: Arial, sans-serif;
    background-color: #f9fdfc;
    color: #004d40;
    /* border-radius: 10px; */
    overflow: hidden;
    width: auto;
    padding: unset;

    .doctor-header {
      position: relative;
    }

    .doctor-image {
      width: 100%;
      height: auto;
      display: block;
    }

    .header-content {
      text-align: center;
      padding: 15px 20px;
      font-size: 32px;
    }

    .header-content h2 {
      color: #004d40;
      font-size: 32px;
      margin: 0;
    }

    .scrollable-cards {
      display: flex;
      // gap: 15px;
      overflow-x: auto;
      // padding: 10px 20px;
      scroll-snap-type: x mandatory;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .scrollable-cards img:nth-child(n+7) {
      display: none;
    }

    .view-all {
      text-align: center;
      margin: 20px 0;
      display: contents;
    }

    .view-all-btn {
      background-color: white;
      color: #083E3E;
      border: 1px solid #083E3E;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      margin: 20px auto;
      width: 100px;
    }

    .view-all button:hover {
      /* background-color: #004d40; */
    }

    /* Bottom Sheet Styles */
    .consult_bottom-sheet {
      position: fixed;
      bottom: -100%;
      /* Initially hidden */
      left: 0;
      width: 100%;
      height: 90%;
      background-color: white;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      transition: bottom 0.3s ease-in-out;
      z-index: 1000;
    }

    /* Open State */
    .consult_bottom-sheet.open {
      bottom: 0;
      /* Slide up */
    }

    /* Sheet Content */
    .sheet-content {
      padding: 20px;
    }

    .sheet-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .sheet_title {
      color: #083E3E;
      font-weight: 600;
    }

    .sheet-content h2 {
      margin-top: 0;
      font-size: 20px;
    }

    /* Grid Layout */
    .health-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin: 20px 0;
      height: 72vh;
      overflow-y: scroll;
    }

    .image_size {
      width: 150px;
      height: 140px;
    }

    /* Close Button */
    .close-btn {
      background-color: white;
      color: white;
      border: none !important;
      /* padding: 10px 20px; */
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
    }

    .health_problems {
      // width: 112px;
      // height: 152px;
    }
  }

  .health-concerns .card {
    width: 100px;
    height: 130px;
  }

  .health-concerns .card img {
    width: 64px;
    height: 64px;
  }
}