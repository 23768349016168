.not-found{
    padding: 20px 200px;
    display: flex;
    align-items: center;
    column-gap: 20px;

    justify-content: center;
}

.not-found > div{
    width: 480px;
}

.not-found > div .not-found-title{
    font-size: 28px;
    color: #068C69;
    font-weight: 700;
}

.not-found > div > .not-found-content{
    font-weight: 500;
}

.not-found > div > .home-link{
    font-weight: 650;
    text-decoration: underline;

    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .not-found{
        padding: 20px 80px;
    }
}
@media screen and (max-width: 500px) {
    .not-found{
        padding: 20px 40px;
    }

    .not-found > div .not-found-title{
        font-size: 24px;
    }
    .not-found > div .not-found-content{
        font-size: 12px;
    }
    .not-found > div .not-found-title{
        font-size: 24px;
    }
}