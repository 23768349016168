.nine-fold {
    position: relative;
    background: #f7f7f7;
}
.nine-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;text-transform: uppercase;
}
.nine-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}
.nine-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    z-index: 9;margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'GalanoGrotesqueBold', sans-serif; 
}

.mlt {
    margin-left: 130px;
}

.row-title {
    padding: 15px 0!important;
}
.faq-row-wrapper {
    background: transparent;
}
section.faq-body {
    padding-top: 0;padding-bottom: 0;
}
.fqs .accordion-item h2 {
    margin-top: 0; margin-bottom: 0;
    font-family: 'GalanoGrotesqueRegular', sans-serif;
}
.fqs .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.fqs .accordion-button {
    
    background-color: transparent;
}


.fqs .accordion-button:not(.collapsed) {
    color: #9c0d43;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);font-weight: 600;
}

@media only screen and (max-width: 767px) { 

.mlt {
    margin-left: 0px;
}

.eight-fold .slick-slider {
    margin-left: 0px;
    margin-top: 30px;
}

.nine-fold h5 {
    padding-left: 40px;
}
.nine-fold h5::before {
  left: 0px;
}
.fqs .accordion-button {
    background-color: transparent;
    padding: 1rem 0.25rem;    font-size: 14px;
}

.nine-fold h2 {
    color: #000;
    font-size: 29px;}

}