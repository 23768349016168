.manage-active-leads{
    padding: 20px 0px;

    .title{
        font-size: 20px;
        font-weight: 500;
        color: #068c89;
    }

    .active-leads{
        padding: 20px;
        border: 1px solid black;
        border-radius: 4px;
    
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:hover{
            cursor: pointer;
        }

        div{
            padding: 4px 10px;
        }
        
        .active-leads-headers{
            display: flex;
            gap: 2px;
            background-color: white !important;
            div{
                flex: 1;
                text-align: center;
                background-color: rgb(227, 221, 221) !important;
            }
            
        }

        .active-lead{
            display: flex;
            gap: 2px;
            &:nth-child(2n+1){
                background-color: rgb(227, 221, 221);
            }
        }

        div{
            flex: 1;
            text-align: center;
        }
    }


}