.header_main-menu {
  background: white;
  font-family: 'Inter', sans-serif;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
.doc_image_section .header_main-menu {
  display: none;
}
.header_main-menu  .container {
  padding: 0;
}

.header_main-menu.hidden {
  transform: translateY(-100%);
}

.header_main-menu .header_button {
  border-radius: 5px;
  padding: 8px;
  color: orange;
  font-size: 14px;
  border: 1px solid orange;
  font-weight: 600;
  font-family: 'GalanoGrotesqueRegular', sans-serif;
  background-color: white;
  /* width: 200px; */
  text-align: center;
  float: right;
  text-decoration: none;
  display: none;
}

.header_button.active {
  color: white;
  border: 1px solid white;
  background-color: orange;

}

.header_main-menu .navbar-light .navbar-nav .nav-link {
  /* color: rgb(255, 255, 255); */
  color: #083E3E;
  padding: 7px 20px;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
}

.header_main-menu .navbar-light .navbar-nav .nav-link:focus,
.header_main-menu .navbar-light .navbar-nav .nav-link:hover {
  color: #083E3E;
}

.header_main-menu .navbar-direction {
  display: flex;
  justify-content: end;
}

/* .collapse:not(.show){
  display: block;
} */

.header_main-menu .navbar-light .navbar-nav .butn .nav-link {
  border-radius: 10px;
  padding: 8px 16px 8px 16px;
  color: white;
  font-size: 14px;
  border: 1px solid #fff;
  font-weight: 600;
  font-family: 'GalanoGrotesqueRegular', sans-serif;
  background-color: orange;
  width: 200px;
  text-align: center;
  float: right;
}

a.navbar-brand img {
  max-width: 115px;
}

.dropdown-menu li a {
  color: #222 !important;
  font-size: 12px !important;
}

.nav-item.butn {
  display: block;
}

.nav-item.butn.seo {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header_main-menu {
    width: 100%;
    padding: 0;
  }
  .header_main-menu  .container-fluid {
   padding-right: 0px;
}
.header_main-menu .navbar-brand {
  width: 20%;
}
  .header_main-menu .header_button {
    display: block;
  }
  .header_main-menu .navbar-direction {
    display: block;
    justify-content: end;
  }

  .header_main-menu .navbar-light .navbar-toggler {
    border-color: transparent;
  }

  .header_main-menu .navbar-light .navbar-toggler-icon {
    background-image: url(../images/menu.png);
  }



}

@media (min-width: 992px){
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
  }
}

@media only screen and (max-width: 380px) {
  .header_main-menu .navbar-brand {
    width: 20%;
  }
  a.navbar-brand img {
    max-width: 70px;
  }
  .header_main-menu .header_button {
    font-size: 11px;
    line-height: 22px;
  }
}