
.our-services-section{
    display: flex;
    flex-direction: row;
    padding: 20px 160px;
    gap: 30px;

}

.our-services-section .leftbar{
    width: 100%;
}

.our-services-section > .rightbar{
    width: 200%;
    padding-top: 0px;
}

.service-content{
    display: none;
}

.service{
    cursor: pointer;
}

.service-title{
    font-size: 26px;
    font-weight: 700;
    color: black;
    opacity: 50%;
}

.service, .active > .service-title{
    color: var(--text-green);
    opacity: 100%;
}

.service, .active > .service-content{
    display: block;
    color: black;
    opacity: 75%;
}

.service > hr{
    border-top: 1px solid;
    opacity: 25%;
}


.active > hr{
    border-top: 2px solid;
    opacity: 1;
}


@media screen and (max-width: 1100px){
    .our-services-section{
        padding: 20px 120px;
    }
}
@media screen and (max-width: 1000px){
    .our-services-section{
        padding: 20px 110px;
    }
}
@media screen and (max-width: 800px){
    .our-services-section{
        padding: 20px 80px;
    }

   .our-services-section > .leftbar-head{
        font-size: 26px;
    }
    .our-services-section > .leftbar-content{
        font-size: 12px;
    }
}
@media screen and (max-width: 600px){
    .our-services-section{
        flex-direction: column;
    }

    .our-services-section > .rightbar{
        width: 100%;
    }

    .active > .service-content{
        padding-left: 36px;
    }
}
@media screen and (max-width: 600px){
    .service-title{
        font-size: 20px;
    }
    .active > .service-content{
        font-size: 14px;
    }
}



.our-services{
    padding: 20px 160px;
}

.our-services > .title{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

.our-services > .desc{
    text-align: center;
    padding: 10px 200px;
    opacity: 0.8;
    font-size: 16px;
}


.our-services > .content{
    display: flex;
    flex-direction: column;
}

.our-services > .content > .row1 {
    display: flex;
    gap: 20px;
    padding: 20px 0px;
}
.our-services > .content > .row2{
    display: flex;
    gap: 20px;
    padding: 20px 0px;
}

.our-services > .content > .row1 > div{
    display: flex;
    flex-direction: row;
    flex: 1;
}
.our-services > .content > .row1 > div > div{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}


.our-services > .content > .row2 > div{
    display: flex;
    flex-direction: row-reverse;
}

.our-services > .content > .row2 > div > div{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-align: right;
}

.our-services > .content > div > div > img{
    width: 60%;
}

@media screen  and (max-width: 1100px){
    .our-services{
        padding: 20px 120px;
    }

    .our-services > .desc{
        padding: 10px 160px;
    }

    .our-services > .content > .row1 > div > div{
        font-size: 13px;
    }
    .our-services > .content > .row2 > div > div{
        font-size: 13px;
    }
    .our-services > .content > div > div > img{
        width: 55%;
    }
}
@media screen  and (max-width: 800px){
    .our-services{
        padding: 20px 40px;
    }
    
    .our-services > .desc{
        padding: 10px 100px;
    }

    .our-services > .content > .row1 > div > div{
        font-size: 12px;
    }
    .our-services > .content > .row2 > div > div{
        font-size: 12px;
    }

    .our-services > .content > div > div > img{
        width: 50%;
    }
}


@media screen  and (max-width: 500px){
    .our-services{
        padding: 20px 20px;
    }

    .our-services > .title{
        text-align: left;
    }
    .our-services > .desc{
        padding: 10px 0px;
        text-align: left;
    }

    .our-services > .content > div{
        display: flex;
        flex-direction: column;
    }

    .our-services > .content > .row1 > div:nth-child(2n){
        display: flex;
        flex-direction: row-reverse;
    }
    .our-services > .content > .row2 > div:nth-child(2n+1){
        display: flex;
        flex-direction: row-reverse;
    }
    .our-services > .content > .row2 > div:nth-child(2n){
        display: flex;
        flex-direction: row;
    }

    .our-services > .content > .row1 > div > img{
        flex: 1;
        width: 70%;
    }
    .our-services > .content > .row2 > div > img{
        flex: 1;
        width: 70%;
    }
    
    .our-services > .content > .row1 >div> div{
        flex: 1;
        font-size: 18px;
    }
    .our-services > .content > .row2 >div> div{
        flex: 1;
        font-size: 18px;
    }
}

@media screen  and (max-width: 450px){
    .our-services > .content > .row1 >div> div{
        font-size: 16px;
    }
    .our-services > .content > .row2 >div> div{
        font-size: 16px;
    }
}
@media screen  and (max-width: 400px){
    .our-services > .content > .row1 >div> div{
        font-size: 14px;
    }
    .our-services > .content > .row2 >div> div{
        font-size: 14px;
    }
}