.doctor-screen-container {
    max-width: 650px;
    min-width: 650px;
    width: 100%;
    background: linear-gradient(to top, #4ebfa8 0%, white 77%);
    margin: 20px auto;
    border: 1px solid;
    padding: 0 20px 20px;
    font-family: Poppins; 

    &-eta {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 35px 0;

        span {
            color: #179d82;
        }
    }

    &-bottom-btn {
        display: flex;
        text-align: center;
        flex-direction: column;
        gap: 20px;
        width: 80%;
        margin: 0 auto;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        img {
            width: 150px;
        }

        .header-right {
            color: #179d82;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            // display: none;
        }
    }

    &-inner {
        background: white;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        border: 1px solid;

        &-doctordetails {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            padding: 20px 30px 50px;
        }

        &-para {
            font-size: 16px;
            color: #666;
            padding: 0 20px 20px;
            margin: 0;
            font-weight: 500
        }

        &-bottom {
            color: #179d82;
            margin-top: 40px;
            margin-bottom: 0;
            padding: 0px;
            font-size: 20px;
        }

        &-medical {
            background: #F0F0F0;
            padding: 20px 10px;
            border-radius: 10px;
            display: flex;
            width: 90%;
            margin: 0 auto;
            border: 1px solid;
            align-items: center;
            justify-content: center;
            gap: 50px;
            font-size: 18px;

            img {
                width: 100px;
            }
        }

        &-image {
            display: flex;
            justify-content: center;
            width: 240px;
            height: 240px;
            overflow: hidden;
            border: 5px solid #ddd;
            border-radius: 40px;
            // background-image: url(./images/d8.jpeg);
            // background-size: cover;
            // background-position: top;
        }

        &-content {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: end;
            gap: 0px;
            color: #575754;

            &-rating {
                display: flex;
                background: #FFA500;
                color: white;
                padding: 5px 8px;
                border-radius: 15px;
                width: auto;
                gap: 5px;
                margin: 10px auto;
                font-weight: 600;
                align-items: center;
                font-size: 18px;
            }

            &-docname {
                margin: 0px 0;
                font-weight: bold;
            }

            &-speciality {
                color: #666;
                margin: 5px 0;
                // font-weight: bold;
            }

            &-details {
                display: flex;
                justify-content: space-between;
                margin: 15px 0 0;
                font-size: 20px;
                align-items: baseline;

                &-label {
                    margin: 5px 0;
                    color: #179d82;
                    font-weight: 600;
                }

                &-number {
                    margin: 5px 0;
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 600;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .doctor-screen-container {
        padding: 00px 10px 10px;
        width: auto;
        max-width: 100%;
        min-width: 100%;
        border: 1px solid;
        &-header {
            img {
                width: 100px;
            }
        }
        
        .header-right {
            font-size: 20px;
            line-height: 25px;
        }

        &-inner {
            color: black;

            &-para {
                font-size: 12px;
            }

            &-bottom {
                font-size: 14px;
                margin-top: 25px;
            }

            &-medical {
                width: 90%;
                padding: 10px 10px;
                gap: 25px;
                font-size: 12px;

                img {
                    width: 60px;
                }
            }

            &-content {

                // width: 100%;
                &-docname {
                    font-size: 14px;
                }

                &-speciality {
                    font-size: 14px;
                }

                &-rating {
                    font-size: 12px;
                    padding: 5px 10px;
                    margin: 5px auto;
                    gap: 10px;
                }

                &-details {
                    font-size: 12px;
                    margin: 0px 0 0;

                    &-number {
                        line-height: 10px;
                        font-size: 15px;

                        span {
                            font-size: 12px;
                        }
                    }
                }
            }

            &-image {
                width: 140px;
                height: 140px;
            }

            &-doctordetails {
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding: 20px 10px 25px;
            }
        }
    }
}