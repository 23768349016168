
.main-menu{ background:#003c3c; font-family: 'Inter', sans-serif;padding: 10px 0;}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  padding: 10px 20px; font-size: 18px;font-weight: 600;
}
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
}
.navbar-light .navbar-nav .butn .nav-link {
  border-radius: 10px;
  padding: 8px 16px 8px 16px;
  color: #fff;
  font-size: 14px;
  border: 1px solid #fff;
  font-weight: 500;font-family: 'GalanoGrotesqueRegular', sans-serif;
  background-color: orange;
}
a.navbar-brand img {
  max-width: 115px;
}
.dropdown-menu li a {
  color: #222 !important;
  font-size: 12px !important;
}

.nav-item.butn {
  display: block;
}

.nav-item.butn.seo {
  display: none;
}

@media only screen and (max-width: 767px) { 
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url(../images/nav-icon.svg);
}


}