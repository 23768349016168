.brand-button {
    /* background: #179D82; Brand color */
    background: linear-gradient(180deg, #179D82 0%, #0B7963 100%);

    color: white;
    border: none;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, opacity 0.3s ease;
    outline: none;
    line-height: 48px;
    border-radius: 12px;
    padding: 16px;
    gap: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0%;


  }
  
  .brand-button:hover:not(.disabled) {
    background: linear-gradient(180deg, #179D82 0%, #0B7963 100%);
  }
  
  .brand-button.disabled {
    background: #ccc; /* Greyish color for disabled state */
    cursor: not-allowed;
    opacity: 0.7;
  }