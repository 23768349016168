.dashboard{
    padding: 20px;
}

.dashboard > img{
    width: 10%;
}

.dashboard-home {
    
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-home > .head{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #068C69;
}

.dashboard-home > .title{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.dashboard-home > .selected-board{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.dashboard-home > .dashboard-options{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.dashboard-options > .submit {
    font-weight: 400;
    font-size: 18px;
    background-color: rgb(185, 180, 180);
    padding: 4px 10px;
    color: black;
    border-radius: 8px;
}

.dashboard-options > .submit:hover{
    cursor: pointer;
    background-color: grey;
    color: white;
}


.home-btn{
    background-color: rgb(185, 180, 180);
    width: fit-content;
    padding: 4px 10px;
    border-radius: 4px;
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    font-weight: 400;
}

.home-btn:hover{
    cursor: pointer;
    background-color: grey;
    color: white;
}