
.service-block {
   min-height: 250px;
}
.service-block img {
    max-height: 70px;
}


@media screen and (max-width: 768px) {
    .service-block {
        min-height: inherit;
     }
  }