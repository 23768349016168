.principles-values {
  margin: 0px auto;
  padding: 80px 0;
  font-family: Arial, sans-serif;
  color: #004d40;
  background: #E9F8F5;
}

.principles-values .section_title {
  text-align: center;
  color: black;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 40px;
}

.principles-values h3 {
  color: #083E3E;
  font-weight: 600;
}

.values-feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  /* margin: 15px 0; */
  padding: 15px;
  width: 350px;
  height: 200px;
}

.values-feature-card .icon {
  font-size: 28px;
  color: #00796b;
}

.values-feature-card .content h3 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #004d40;
}

.values-feature-card .content p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

@media (max-width: 768px) {
  .principles-values {
    width: auto;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    color: #004d40;
    background: #E9F8F5;
  }
  .principles-values .section_title { 
    font-size: 32px;
  }

  .values-feature-card {
    display: flex;
    background: white;
    border-radius: 8px;
    margin: 0px 0;
    padding: 15px;
    align-items: flex-start;
    flex-direction: row;
    width: 343px;
    height: auto;
    gap: 15px;
  }

  .icon {
    margin-bottom: 10px;
  }
}