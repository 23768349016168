/* Container Styling */
.kyno_container {
  display: flex;
  flex-direction: column;
  background-color: #f8fff8;
  color: #213547;
  // font-family: Arial, sans-serif;
  text-align: center;
  margin: 80px auto 0;
  // max-width: 1200px;

  /* Text Content */

  /* Image Section */
  .kyno-image-section {
    display: flex;
    justify-content: center;

    .kyno-image-mobile {
      display: none;
    }

    .kyno-image-desktop {
      display: block;
      width: 100%;
      // width: 1440px;
      // height: 626px;
      // width: 1279px;
    }
  }
}

.kyno-highlight {
  font-size: 1.1rem;
  color: #1f6f45;
  margin-top: 10px;
  font-weight: bold;
}

.kyno-content {
  padding: 0px 25px 0px;
}

/* Responsive Design */
@media (max-width: 768px) {

  /* Container Styling */
  .kyno_container {
    display: flex;
    flex-direction: column;
    background-color: #f8fff8;
    color: #213547;
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 60px auto 0;
    // max-width: 1200px;

    /* Image Section */
    .kyno-image-section {
      display: flex;
      justify-content: center;
      // margin-bottom: 20px;

      .kyno-image-desktop {
        display: none;
      }

      .kyno-image-mobile {
        display: block;
        width: 100%;
        max-width: 600px;
        height: auto;
      }
    }
  }

 
  .kyno-highlight {
    font-size: 1.1rem;
    color: #1f6f45;
    margin-top: 10px;
    font-weight: bold;
  }

  
}