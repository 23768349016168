.top-physicians {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 108px;
  background-color: #f5faf9;
}

.top-physicians h2 {
  color: #004d40;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}

.top-physicians  .doctor_name {
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
}

.top-physicians p {
  width: 528px;
  color: #083E3E;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
}

.physicians-grid {
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 1112px;
  height: auto;
  justify-content: center;
}

/* Flip container */
.flip-container {
  perspective: 1000px;

}

.flipper {
  position: relative;
  width: 160px;
  height: 160px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border-radius: 50%;
}

.flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50%;
  overflow: hidden;
  background-color: #153e3c;
  /* aspect-ratio: 1 / 1; */
}

/* Front side */
.front img,
.back img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;aspect-ratio: 1 / 1;
}

/* Back side */
.back {
  transform: rotateY(180deg);
  /* background-color: transparent; */
  background: #163f3c;
    color: white;
    display: flex
;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-physicians  .degree_name {
  text-align: center;
  /* margin-top: 10px; */
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
  color: orange;
}

@media (max-width: 768px) {
  .top-physicians {
    text-align: center;
    padding: 2rem 1rem;
    width: auto;
  }

  .top-physicians h2 {
    color: #004d40;
    font-size: 32px;
    margin-bottom: 10px;
  }

  .top-physicians p {
    color: #555;
    font-size: 1em;
    margin-bottom: 30px;
    width: 343px;
  }

  /* Grid layout for physicians */
  .physicians-grid {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 15px;
    justify-items: center;
    width: auto;
    height: auto;
  }

  /* Flip container */
  .flip-container {
    perspective: 1000px;
    text-align: center;
    display: flex
;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 30%;
    margin-bottom: 10px;
    /* height: 128px; */
  }

  .flipper {
    position: relative;
    width: 100px;
    height: 100px;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .flip-container:hover .flipper {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 50%;
    overflow: hidden;
  }

  /* Front side */
  .front img,
  .back img {
    width: 100%;
    height: 100%;
  object-fit: contain;
  object-position: top;
  }

  /* Back side */
  .back {
    transform: rotateY(180deg);
    background: #163f3c;
    color: white;
    display: flex
;
    align-items: center;
    justify-content: center;
  }
  .top-physicians  .doctor_name {
    font-size: 10px;
    text-align: center;
    padding: 0 10px;
    margin-top: 0px;
  }
  .top-physicians  .degree_name {
    text-align: center;
    margin-top: 0px;
    font-weight: 700;
    font-size: 8px;
    padding: 0 5px;
  }
}

.top-physicians .swiper {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  max-width: 500px;
  margin-bottom: 30px;
}

.top-physicians .swiper-wrapper {
  margin-top: 100px;
}
.top-physicians .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 20px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-physicians .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-physicians .swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 20px;
  /* font-size: 12px; */
  opacity: 1;
  font-size: 14px;
  background: #c8e7e1;
  color: #1fa58a;
  line-height: 40px;
  font-weight: bold;
}

.top-physicians .swiper-pagination-bullet-active {
  color: white;
  background: #169d82;
}
.top-physicians .swiper-pagination {
  top: 0 !important;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  max-width: 500px;
  margin: 0 20p;
  padding: 0 30px;
  
}
.top-physicians .slider_heading {
  font-weight: 600;
}

.top-physicians .slider_desc {
  font-size: 14px;
  text-align: center;
}
