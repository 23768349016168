.thrd-fold{background: #F7F7F7;}

.thrd-fold::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 859px;
    height: 100%;
    left: 7px;
    background-size: 82%;
}

.thrd-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
       position: relative;
    z-index: 9; margin-bottom: 40px;font-family: 'GalanoGrotesqueBold', sans-serif;

}

.white-bg{background: #fff;}

.white-bg::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 7px;
    background-size: 82%;
}

.white-bg h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
       position: relative;
    z-index: 9; margin-bottom: 40px;font-family: 'GalanoGrotesqueBold', sans-serif;

}



.programitem {
    padding: 50px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;min-height: 360px;
}
.programitem h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
}
.programitem p {
    color: #646464;
    line-height: 1.7;
    margin: 0;
}
.programitem img {
    margin-bottom: 40px;max-height: 80px;
}
.programitem2 {
    padding: 20px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;
    min-height: 230px;
    margin-bottom: 25px;    
}
.programitem2 h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.programitem2 p {
    color: #646464;
    line-height: 1.3;
    margin: 0;
}
.programitem3 {
    padding: 20px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;
    min-height: 170px;
    margin-bottom: 25px;    
}
.programitem3 h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.programitem3 p {
    color: #646464;
    line-height: 1.3;
    margin: 0;
}
.programitem img {
    margin-bottom: 40px;max-height: 80px;
}
.programitem4 {
    padding: 20px 10px;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;
    min-height: 70px;
    margin-bottom: 25px;    
}
.programitem4 .green {
    background-color: #085a58;
 }
.programitem4 .orange {
   background-color: orange;
}
.programitem4 h4 {
    margin-top: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.programitem4 p {
    color: #fff;
    line-height: 1.3;
    margin: 0;
}

.programitem5 {
    padding: 20px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;
    height: 360px;
    margin-bottom: 25px;    
}
.programitem5 h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.programitem5 p {
    color: #646464;
    line-height: 1.3;
    margin: 0;
}
.programitem6 {
    padding: 20px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 100%;
    z-index: 9;
    position: relative;
    height: auto;
    margin-bottom: 25px;    
}
.programitem6 img {
    border-radius: 20px;
    width: 50%;
  }
.programitem6 h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.programitem6 p {
    color: #646464;
    line-height: 1.3;
    margin: 0;
}

.fourth-fold {
    background: #003C3C;
    padding: 0px 0;overflow: hidden;
}
.fourth-fold h5 {
    font-size: 25px;
color: #fff;
position: relative;
z-index: 9;
font-weight: bold;
line-height: 42px;
}
.fourth-fold h5 b {
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    left: 0;
    bottom: -18px;
  }

.fourth-fold h5 span {
    font-size: 32px;
    font-weight: 800;    
    font-family: 'GalanoGrotesqueBold', sans-serif;
}
.schedule p{color: white;}

@media only screen and (max-width: 767px) { 

  
    .thrd-fold::before {
        width: 100% !important;
        height: 173%;
        left: 7px;
        background-size: 123% !important;
    }
    .programitem {
        padding: 20px 10px 20px 80px!important;
        width: 100%;
        text-align: left;min-height: inherit;
        border-radius: 12px;
    }
    .programitem img {
        margin-bottom: 40px;
        max-height: 60px;
        position: absolute;
        left: 15px;
    }
    .programitem h4 {
        margin-bottom: 10px!important;
    }
    .programitem p {
     
        font-size: 14px;
    }
    .thrd-fold h2 {
        font-size: 29px;       
    }
    .fourth-fold h5 span {
        padding-left: 0px;
        display: block;
    }
    .fourth-fold h5 {
        
        line-height: 25px;font-size: 12px;
    
    }
    
    }