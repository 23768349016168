.doctor-management-panel{
    .headers{
        display: flex;
        padding: 10px;
        background-color: #f9f7f7;
        div{
            flex: 1 1;
            font-weight: 650;
            font-size: 20px;
        }

        .locality{
            flex: 2;
        }
    }

    .doctors{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .doctor{
            background-color: white;
            padding: 4px 10px;
            display: flex;
            flex-direction: row;

            div{
                flex: 1 1;
                font-weight: 600;
            }
            .locality{
                flex: 2 2;  
            }

            .status{
                span{
                    padding: 4px 10px;
                    border-radius: 8px;
                    color: white;
                    background-color: red;
                    width: fit-content;
                    &.active{
                        background-color: green;
                    }
                }
            }
        }
    }
}