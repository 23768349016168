.sec_fold_abt { background-color: #fff; padding-bottom: 0px;}


.sec_fold_abt_qts {
    width: 75%;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 10px 176px 0 28px;
}

.sec_fold_abt_img {
    width: 25%;
    text-align: right;
    top: -60px;
    position: relative;
}

.sec_fold_abt_img img {width: 255px; }

.d_qts_icon {
    background-image: url(../images/d_qts_icon.svg);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 30px;
    z-index: 1;
    width: 91px;
    height: 60px;
    background-size: 95%;
    left: 15px;
}

.d_qts_icon2 {
    background-image: url(../images/d_qts_icon2.svg);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 10px;
    z-index: 1;
    width: 91px;
    height: 60px;
    background-size: 95%;
    rotate: 180deg;
    right: 460px;
}

@media only screen and (max-width: 500px) {

    .d_qts_icon {
        top: 15px;
        left: 15px;
        background-size: 50%;
    }
    
    .d_qts_icon2 {
        top: inherit;
        left: 237px;
        bottom: 222px;
        background-size: 50%;
    }

 }


@media only screen and (max-width: 767px) { 
    .sec_fold_abt_qts {
        width: 100%;
        text-align: center;
        padding: 10px;
    }

    .sec_fold_abt_img {
        width: 100%;
        text-align: center;
        top: 0;
        position: relative;
    }

    /* .d_qts_icon {display: none;}
    .d_qts_icon2 {display: none;} */

    .sec_fold_abt_img img {
        width: 155px;
        
    }
}


/* .sec_fold_abt_qts::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -203px;
    z-index: 1;
    width: 1120px;
    height: 754px;
    left: 50px;
    background-size: 82%;
} */
