.testimonials{
    padding: 20px 160px;
}

.testimonials-bg > img{
    width: 100%;
}

.testimonials > .heading{
    transform: translateY(-140px);
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: white;
}

.testimonials-content{
    padding: 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    transform: translateY(-100px);
}

.testimonial{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.testimonial > div{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 40px;
    padding: 20px 40px;
    border-radius: 20px;
    width: fit-content;
}

.testimonial > div > .t-head{
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}
.testimonial > div > .t-by{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.testimonial > div > .t-by > .hr{
    border-top: 2px solid #959595;
    opacity: 0.8;
    width: 15px;
}


.testimonial > div > .t-head > span:nth-child(1){
    position: relative;
    width: 48.57px;
    height: 51px;
    left: -20px;
    top: 20px;

    font-family: 'Neuton';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 101px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #EAEAEA;
}
.testimonial > div > .t-head > span:nth-child(2){
    position: relative;
    width: 48.57px;
    height: 51px;
    left: 75%;
    top: -30px;

    font-family: 'Neuton';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 101px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #EAEAEA;

    transform: rotate(-180deg);
}

.testimonial > div > .t-by > span{
    clear: both;
    display: inline-block;
    overflow: visible;
    white-space: nowrap;
}

.react-multi-carousel-list{
    transform: translateY(-100px);
}

.react-multiple-carousel__arrow{
    /* display: none; */
}

@media screen and (max-width: 1100px) {
    .testimonials{
        padding: 20px 120px;
    }
    .testimonial > div{
        padding: 20px 30px;
    }
}
@media screen and (max-width: 1000px) {
    .testimonials{
        padding: 20px 100px;
    }
    .testimonials-content{
        padding: 0px 24px;
    }
}
@media screen and (max-width: 800px) {
    .testimonials{
        padding: 20px 80px;
    }
    .testimonials-content{
        padding: 0px 20px;
    }
    .testimonials > .heading{
        transform: translateY(-120px);
    }
    .testimonial > div > .t-head{
        font-size: 12px;
    }
    /* .testimonial > div > .t-by{
        font-size: 12px;
    } */
    .testimonial > div > .t-by > span{
        font-size: 14px;
    }
    .testimonial > div > .t-by > img{
        font-size: 14px;
    }
}
@media screen and (max-width: 500px) {
    .testimonials{
        padding: 20px 0px;
        /* transform: translateY(100px); */
        /* margin-bottom: 80px; */
    }

    .testimonials > .testimonials-bg > img{
        height: 150px;
    }

    .testimonial{
        width: 100%;
    }

}