.sales-auth{
    .submit{
        background-color: rgb(185, 180, 180);
        width: fit-content;
        padding: 4px 10px;
        border-radius: 4px;
        font-weight: 500;
        color: black;
        font-weight: 400;
        margin-top: 16px;
        &:hover{
            cursor: pointer;
            background-color: grey;
            color: white;
        }
    }
}