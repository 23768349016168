.form-container-v1 .form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}
.form-container-v1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  max-width: 500px;
  margin: 0 auto;
}

.form-container-v1 h2 {
  /* margin-bottom: 20px; */
  margin-top: 8px;
  color: #333;
  width: 100%;

  font-weight: 600;
  font-size: 16px;
}

.form-container-v1 form {
  width: 100%;
  margin-top: 16px;
  padding-inline: 8px;
}

.form-container-v1 .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.form-container-v1 .form-group {
  flex: 1;
  margin-right: 10px;
}

.form-container-v1 .form-group:last-child {
  margin-right: 0;
}

.form-container-v1 label {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.form-container-v1 input,
.form-container-v1 select,
.form-container-v1 textarea {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 12px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: inherit;
}

.form-container-v1 input:focus,
.form-container-v1 select:focus,
.form-container-v1 textarea:focus {
  border-color: #007bff;
}

.form-container-v1 textarea {
  resize: vertical;
}

.form-container-v1 .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.form-container-v1 .book-fee-banner {
  padding: 10px 16px;
  background-color: #f6f6f6;
  width: 100%;
  text-align: center;
  color: #161616;
  margin-bottom: 16px;
}

.form-container-v1 .book-fee-text-typo-1 {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
}

.form-container-v1 .book-fee-text-typo-2 {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
}

.form-container-v1 .text-1999 {
  font-weight: 600;
  font-size: 14px;
  color: #aaaaaa;
  text-decoration: line-through;
  margin-inline: 6px;
}
.form-container-v1 .text-1499 {
  font-weight: 600;
  font-size: 14px;
  color: #179d82;
}

.form-container-v1 .m-y {
  margin-block: var(--m-y);
}

.form-container-v1 ol {
  display: flex;
  gap: 8px;
}

.form-container-v1 li::marker {
  font-size: 20px; /* Change the size of the number */
  color: #179d82; /* Change the color of the number */
}

.stepper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 8px;
  padding: 28px 20px;
}

.stepper .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #008060;
  width: 50px;
  text-align: center;
}

.stepper .step.active {
  color: #008060;
}

.stepper .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.stepper .step.active .circle {
  background: #008060;
  color: white;
}

.stepper .line {
  width: 40px;
  height: 2px;
  border-top: 1px dashed #008060;
  margin-top: 16px;
}

.stepper .step span {
  margin: 0;
  color: #000000;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  margin-top: 8px;
}

.form-container-v1 .just-few-text {
  margin: 0;
  margin-top: 8px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
}
.form-container-v1 .separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 100%;
  margin-block: 12px;
  font-size: 14px;
}
.form-container-v1 .separator-line {
  flex: 1;
  padding: 1px;
  width: 60px;
  background: linear-gradient(270deg, #fdfffe 0%, #aaaaaa 100%);
}
.form-container-v1 .separator-line:first-child {
  background: linear-gradient(90deg, #fdfffe 0%, #aaaaaa 100%);
}

.form-container-v1 .existing-name {
  width: 210px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.form-container-v1 .name-container {
  width: 30px;
}

.form-container-v1 .existing-para {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
}

.form-container-v1 .existing-card {
  display: flex;
  padding: 12px 20px;
  background: #f6f6f6;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 18px;
  min-width: 180px;
  border: 1px solid transparent;
}

.form-container-v1 .existing-check {
  height: 18px;
  width: 18px;
  accent-color: #179d82;
  /* outline: 2px solid #179d82; */
  /* outline-offset: -2px; */
  border-radius: 12px;
}

.form-container-v1 .existing-check:hover {
  accent-color: #179d82;
}

.form-container-v1 .existing-container {
  width: 100%;
  display: flex;
  padding-block: 12px;
  gap: 12px;
  overflow-x: auto;
  scrollbar-width: none;
  padding-inline: 8px; /* ✅ added */
  box-sizing: border-box; /* ✅ added */
}

.form-container-v1 .active-selection {
  border: 1px solid #179d82;
  background: #179d8210;
}

.form-container-v1 .existing-address {
  min-width: 280px;
}
.form-container-v1 .detect-btn {
  all: unset;
  background: #179d8220;
  padding: 10px 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #179d82;
  flex: 1;
}

.form-container-v1 .detect-container {
  padding-inline: 8px;
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 12px;
}

.form-container-v1 .clear-btn {
  all: unset;
  padding: 10px 28px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #179d82;
}
