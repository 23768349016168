.mobile-bottom {
    display: none;
}


@media screen and (max-width: 500px) {
    .mobile-bottom{
        display: flex;
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 4;
    }
    /* .mobile-bottom, .unshow{
        display: none ;
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 4;
    } */

    .mobile-bottom>div {
        flex: 1;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .mobile-bottom>div:nth-child(1) {
        background-color: white;
        color: #068C69;
        font-weight: 700;
        box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px 8px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    }

    .mobile-bottom>div:nth-child(2) {
        background-color: #068C69;
        color: white;
        font-weight: 700;
        box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px 8px, rgb(0 0 0 / 30%) 0px 3px 7px -3px
    }

    .mobile-bottom>div:nth-child(2)>svg {
        width: 28px;
        height: 20px;
        margin-right: 16px;
    }

    @-webkit-keyframes ring {
        0% {
            -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg)
        }

        2% {
            -webkit-transform: rotate(15deg);
            transform: rotate(15deg)
        }

        4% {
            -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        6% {
            -webkit-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        8% {
            -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg)
        }

        10% {
            -webkit-transform: rotate(22deg);
            transform: rotate(22deg)
        }

        12% {
            -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        14% {
            -webkit-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        16% {
            -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg)
        }

        18% {
            -webkit-transform: rotate(12deg);
            transform: rotate(12deg)
        }

        20% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    }

    @keyframes ring {
        0% {
            -webkit-transform: rotate(-15deg);
            -ms-transform: rotate(-15deg);
            transform: rotate(-15deg)
        }

        2% {
            -webkit-transform: rotate(15deg);
            -ms-transform: rotate(15deg);
            transform: rotate(15deg)
        }

        4% {
            -webkit-transform: rotate(-18deg);
            -ms-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        6% {
            -webkit-transform: rotate(18deg);
            -ms-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        8% {
            -webkit-transform: rotate(-22deg);
            -ms-transform: rotate(-22deg);
            transform: rotate(-22deg)
        }

        10% {
            -webkit-transform: rotate(22deg);
            -ms-transform: rotate(22deg);
            transform: rotate(22deg)
        }

        12% {
            -webkit-transform: rotate(-18deg);
            -ms-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        14% {
            -webkit-transform: rotate(18deg);
            -ms-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        16% {
            -webkit-transform: rotate(-12deg);
            -ms-transform: rotate(-12deg);
            transform: rotate(-12deg)
        }

        18% {
            -webkit-transform: rotate(12deg);
            -ms-transform: rotate(12deg);
            transform: rotate(12deg)
        }

        20% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    }

    .mobile-bottom > .phone > svg {
        -webkit-animation: ring 2s ease infinite;
        animation: ring 2s ease infinite;
        /* transform-origin-x: 50%;
        transform-origin-y: 0px;
        transform-origin-z: initial */
    }
}