.bookAppointmentCard{
    display: flex;
    flex-direction: row;
    padding: 20px 240px 20px 160px;
    justify-content: space-between;
}
.bookAppointmentCard > img{
    width: 500px;
}

.bookAppointmentForm{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    /* box-shadow: ; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    height: fit-content;
}

.bookAppointmentForm > .title{
    font-size: 32px;
    font-weight: 700;
}

.bookAppointmentForm > .name{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.bookAppointmentForm > .phone > .pnoinput{
    display: flex;
    flex-direction: row;
    gap: 20px
}

.bookAppointmentForm > .name > .fn > div{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}
.bookAppointmentForm > .name > .fn > input{
    font-size: 16px;
    opacity: 0.5;
    padding: 6px 10px;
    border: 2px solid #00000040;
    border-radius: 6px;
}
.bookAppointmentForm > .name > .ln > div{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}
.bookAppointmentForm > .name > .ln > input{
    font-size: 16px;
    opacity: 0.5;
    padding: 6px 10px;
    border: 2px solid #00000040;
    border-radius: 6px;
}

.bookAppointmentForm > .phone > .pno{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}

.bookAppointmentForm > .phone > .pnoinput > div{
    /* margin-bottom: ; */
    font-size: 16px;
    opacity: 0.5;
    padding: 6px 10px;
    border: 2px solid #00000040;
    border-radius: 6px;
}

.bookAppointmentForm > .phone > .pnoinput > input{
    font-size: 16px;
    opacity: 0.5;
    padding: 6px 10px;
    border: 2px solid #00000040;
    border-radius: 6px;
}

.bookAppointmentForm > .message > div{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.bookAppointmentForm > .message > input{
    padding: 6px 10px;
    font-size: 16px;
    opacity: 0.75;
    border: 2px solid #00000040;
    border-radius: 6px;
    height: 100px;
    width: 100%;
}

.bookAppointmentForm > .btn{
    font-size: 16px;   
    color: white;
    font-weight: 500;
    padding: 10px 16px;
    background-color: #068C69;
    width: fit-content;
    border: 2px solid #068C69;
    border-radius: 24px;
}

.bookAppointmentForm > .btn:hover{
    background-color: white;
    color: #068C69;

}

@media screen and (max-width: 1100px) {
    .bookAppointmentCard{
        padding: 20px 120px;
    }
}
@media screen and (max-width: 800px) {
    .bookAppointmentCard{
        flex-direction: column;
        gap: 20px;
        padding: 20px 40px;
    }

    .bookAppointmentCard > img{
        width: 90%;
    }
}
@media screen and (max-width: 500px) {
    .bookAppointmentForm > .name{
        flex-direction: column;
    }
}
@media screen and (max-width: 400px) {
    .bookAppointmentForm > .title{
        font-size: 28px;
    }
    .bookAppointmentForm > .phone > .pnoinput{
        gap: 8px;
    }
    .bookAppointmentForm > .phone > input{
        width: 100%;
    }
}