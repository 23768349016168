.moed{
    padding: 20px 160px;
    /* background-color: rgb(189, 216, 240); */
    /* height: 600px; */
    height: fit-content;
}

.moed-bg > img{
    width: 100%;
}

.moed-bg >span{
    transform: translateY(-130px);
    font-size: 32px;
    font-weight: 700;
    color: white;
}

.moed-content-carousel{
    display: flex !important;
    flex-direction: row;
    /* padding: 0px 40px; */
    /* justify-content: space-evenly; */

    transform: translateY(-100px);

}

.doc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.doc > img{
    width: 80%;
    height: 420px;
}

.doc > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    padding: 16px;
    border-radius: 24px;
    transform: translateY(-60px);

    width: 90%;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    

}

.moed-content-carousel > .doc > div > .doc-name{
    font-size: 20px;
    font-weight: 600;
}

.moed-content-carousel > .doc > div > .doc-type, .doc-exp{
    font-size: 12px;
    opacity: 75%;
}
.moed-content{
    display: none;
}

.moed > .heading{
    transform: translateY(-140px);
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: white;
}



@media screen and (max-width: 1200px) {
    .moed{
        padding: 20px 140px;
    }
}
@media screen and (max-width: 1100px) {
    .moed{
        padding: 20px 120px;
    }

    .doc > img{
        width: 90%;
    }
}
@media screen and (max-width: 1000px) {
    .moed{
        padding: 20px 110px;
    }

    /* .moed-content-carousel{
        padding: 0px 24px;
    } */
}
@media screen and (max-width: 800px) {
    .moed{
        padding: 20px 80px;
    }

    .moed > .heading{
        transform: translateY(-120px);
    }

    .moed-content-carousel{
        /* display: block !important; */
        padding: 0px 20px;
    }

    .moed-content-carousel > .doc > div{
        width: 95%;
    }
    .moed-content-carousel > .doc > div > .doc-name{
        font-size: 15px;
    }
    .moed-content-carousel > .doc > div > .doc-type, .doc-exp{
        font-size: 10px;
    }
}

@media screen and (max-width: 600px) {
    .moed{
        padding: 20px 0px;
        height: fit-content;
    }
    .moed-content-carousel{
        padding: 0px 20px;
        display: none !important;
    }
    .moed-content{
        display: flex !important;
        flex-direction: column;
        gap: 0px;
        transform: translateY(-70px);
        padding: 0px 10px;
    }

    .moed-bg > img{
        height: 150px;
    }
    .moed-bg > span{
        display: block;
        font-size: 24px;
        color: white;
        text-align: center;
        font-weight: 700;
        transform: translateY(-130px);
    }

    .doc{
        flex-direction: row;
        align-items: center;
        /* transform: translateY(); */
    }
    .doc:nth-child(2n){
        flex-direction: row-reverse;
    }
    
    .doc > img{
        width: 40%;
        height: 200px;
    }

    .doc > div{
        transform: translateY(0px);
        transform: translateX(-10px);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .doc:nth-child(2n) > div{
        transform: translateX(10px);
    }

}

@media screen and (max-width: 500px){
    .moed > .heading{
        font-size: 26px;
        transform: translateY(-90px);
    }

    .moed-bg > img{
        height: 120px;
    }
}

@media screen and (max-width: 400px){
    .moed > .heading{
        font-size: 22px;
    }
}
