  .doctor-card {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: unset;
    display: flex;
  }
  .doctor-card-even {
    flex-direction: row-reverse;
  }

  .doctor-image {
    width: 50%;
    background-position: center;
    background-size: cover;
  }

  .doctor-image .doctor-image-mobile {
    display: none;
  }

  .doctor-image .doctor-image-desktop {
    width: auto;
    height: 688px;
  }

  .doctor-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 108px 100px;
    background: #E9F8F5;
    width: 50%;
    height: auto;
    justify-content: center;
  }

  .doctor-title {
    font-size: 50px;
    color: #00303f;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .doctor-description {
    font-size: 16px;
    color: #083E3E;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
  }

  .doctor-features {
    list-style: none;
    margin-bottom: 20px;
    padding-left: unset;
    list-style: none;
    padding: 0;
  }

  .doctor-features li {
    text-align: left;
    color: #333;
    margin: 8px 0;
    font-size: 0.95em;
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    position: relative;
  }

  .doctor-features img  {
    content: "✔";
    font-size: 18px;
    color: #1a9e84;
    position: absolute;
    left: 0;
    top: 4px;
  }

  .availability {
    background-color: #e6f7f1;
    color: #00897b;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 0.9em;
    margin-bottom: 20px;
    border: 1px solid #C8E7E1;
  }

  .book-button {
    background-color: #ffa000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .book-button:hover {
    background-color: #ff8f00;
  }

  /* Responsive Styling */
  @media (max-width: 768px) {
    .doctor-card {
      background-color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      /* max-width: 400px; */
      text-align: center;
      display: block;
    }
    .doctor-card-even {
      background-color: #E9F8F5;
    }

    .doctor-image {
      width: 100%;
      height: auto;
      display: block;
      height: 300px;
    }

    .doctor-image .doctor-image-desktop {
      width: 100%;
      height: auto;
      display: block;
    }

    .doctor-image .doctor-image-desktop {
      /* display: none; */
    }

    .doctor-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      width: 100%;
      height: auto;
    }

    .doctor-title {
      font-size: 32px;
      color: #00303f;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .doctor-description {
      font-size: 0.9em;
      color: #555;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    .doctor-features {
      list-style: none;
      margin-bottom: 20px;
      padding-left: unset;
    }

    .doctor-features li {
      text-align: left;
      color: #333;
      margin: 8px 0;
      font-size: 0.95em;
    }

    .availability {
      background-color: #e6f7f1;
      color: #00897b;
      display: inline-block;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 0.9em;
      margin-bottom: 20px;
    }

    .book-button {
      background-color: #ffa000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .book-button:hover {
      background-color: #ff8f00;
    }
  }