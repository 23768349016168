

.footer {
    background: #000;
}

.footer h3 {
    color: #fff;
    font-size: 35px;
    margin-top: 0;
}
.footer p {
    position: relative;
    padding: 15px 0 5px 0px;
    color: #fff;
    font-size: 14px;

}
.footer p span {
    color: #000000;
    font-size: 20px;
    margin-right: 7px;
    background: #2e2e2e;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}
.footer ul {
    margin: 0;
    padding: 0;
}

.footer ul li {
    list-style: none;
}
.footer ul li a {
    color: #fff;
    padding: 20px 0;
    display: block;
    border-bottom: 1px solid #2a2a2a;
    text-decoration: none;
    font-size: 16px; position: relative;
}

.footer ul li a:after {
    color: #fb9f40;
    content: "\f105";
    font-family: FontAwesome;
    font-size: 22px;
    right: 5px;
    position: absolute;
    top: 20px;
}

.socials a {
    padding-right: 32px;
}

.socials {
    display: inline-block!important;
    border-top: 1px solid #2a2a2a;
    padding-top: 15px;
}
p.copyrgt {
    color: #808F99;
    font-size: 12px;
    margin: 30px 0 0;
}

.fooet img{width: 140px;}

.foot-stick{ display: none;}

@media only screen and (max-width: 767px) { 
.socials.hidd-m {
    display: none!important;
}
.footer p {
     font-size: 13px;margin: 10px 0 0;
}
.socials a {
    padding-right: 15px;
}




.foot-stick a {
    background: #fb9f40;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* padding: 7px 0; */
    font-weight: 700;
    height: 55px;
    line-height: 55px;
    text-decoration: none;
    width: 100%;
}
.foot-stick a i {
    margin-right: 3%;
}

.foot-stick {
    display: block;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    text-align: center;
    left: 0px;
    width: 100%;
}

.phone-icon{
    font-size: 25px;
}

}

.tnc{
    padding: 20px;

    .logo{
        img{
            width: 150px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .title{
        color: #068c69;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
    }

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .subtitle{
            font-size: 24px;
            font-weight: 600;
            color: #f58625;
        }

        .point{
            width: 80%;
            text-align: left;

            .point-title{
                font-weight: 650;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .tnc{
        .content{
            .subtitle{
                font-size: 16px;
            }
        }
    }
}