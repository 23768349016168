.sixth-fold{ position: relative;}

.sixth-fold::before {
    background-image: url(../images/sixth-Topology.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: -1;
    width: 47%;
    height: 100%;
    left: 7px;
    background-size: contain;
}

.sixth-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;
}
.sixth-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}
.sixth-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    z-index: 9;margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'GalanoGrotesqueBold', sans-serif; 
}

.sixth-fold h2 span{
    color: #003C3C;
  
}
.doct {
    background: #1e1406;
    border-radius: 12px 12px 0 0; min-height: 120px; position: relative;
}

.putting-profile h6 {
    font-size: 20px;
    color: #000;
    margin-top: 0px;
}
.putting-profile p span {
    font-size: 12px;
    color: #9C0D38;
    border: 1px solid #9C0D38;
    border-radius: 20px;
    padding: 6px 8px;
    font-weight: 600;
    text-transform: uppercase;
}

.vgy {
    position: relative;
    padding: 20px 15px;
}
.putting-profile p span {
    font-size: 12px;
    color: #9C0D38;
    border: 1px solid #9C0D38;
    border-radius: 20px;
    padding: 2px 8px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    right: 12px;
    top: 60px;
}
.vgy p {
    font-size: 14px; color: #364855;
}

.putting-profile {
    border-radius: 12px;
    border: 1px solid #CFCFCF; max-width: 310px;
}

.alls .btn {
    background: #fff;
    border: 1px solid #FB9F40;
    color: #FB9F40;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px 12px 16px;
    min-width: 100%;
}

.sixth-fold .slick-slider {
    margin-left: 130px;
    margin-top: 0px; z-index: 999;
}
.sixth-fold .slick-arrow {
    position: absolute;
    z-index: 9;
    border: 0;
    background-repeat: no-repeat;
    font-size: 0;
    top: 36%;
    width: 40px !important;
    height: 40px !important;
    padding: 0;
    cursor: pointer;
    background-color: #FB9F40!important;
    background-size: 18px;
    background-position: center;
    border-radius: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sixth-fold .slick-prev {
    left: -2%!important;
    background-image: url(../images/test-left.png);
}
.sixth-fold .slick-next {
    right: 2%;
    background-image: url(../images/test-right.png);
}

.doct img {
    position: absolute;
    top: -146px;
    left: 11%;
    max-height: 266px;
  }


.tww-p p {
    font-size: 12px;
}

.sixth-fold .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 145px 0 0;
}

.grad {
    background-image: url(../images/putting-Gradient.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 520px;
    height: 100%;
    left: 475px;
    background-size: 95%;
}

.grad1 {
    background-image: url(../images/putting-Gradient.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 520px;
    height: 100%;
    left: 774px;
    background-size: 80%;
}

.grad2 {
    background-image: url(../images/putting-Gradient.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 520px;
    height: 100%;
    right: 0;
    background-size: 80%;
}

@media only screen and (max-width: 767px) { 

.grad, .grad1{ display: none;}
.sixth-fold .slick-slider {
    margin-left: 0px;
  
}
.putting-profile {
    max-width: 100%;
}
.sixth-fold h5 {
    padding-left: 40px;
}
.sixth-fold h5::before {
  left: 0px;
}

.sixth-fold h2 {
    font-size: 29px;
}


}

/* MyImage.css */

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) {
    .doc-img {
      /* Set the desired width for small devices */
      max-width: 200px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .doc-img {
      /* Set the desired width for medium devices */
      max-width: 300px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .doc-img {
      /* Set the desired width for large devices */
      max-width: 400px;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .doc-img {
      /* Set the desired width for extra large devices */
      max-width: 500px;
    }
  }
  

  .frame {
    background-color: #9c0d381f;
    border: 1px none;
    border-radius: 16px;
    height: 32px;
    overflow-x: hidden;
    position: relative;
    width: 32px;
  }
  
  .frame .text-wrapper {
    color: #9c0d38;
    font-family: "Galano Grotesque-ExtraBold", Helvetica;
    font-size: 12px;
    font-weight: 800;
    left: 8px;
    letter-spacing: 0.28px;
    line-height: 16px;
    position: absolute;
    top: 7px;
    white-space: nowrap;
  }