.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  font-family: "Inter", sans-serif;
  overflow-y: auto;
  max-width: 500px;
  margin-inline: auto;
  scrollbar-width: none;
}
.arrive-element {
  background: #179d82;
  width: 100%;
  padding: 20px;
  color: white;
  font-weight: 500;
}

.doctor-profile-container {
  padding: 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
}
.doctor-details {
  padding: 14px;
  flex: 1;
}

.doctor-name {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;

}
.doctor-role {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #777;
}

.doctor-rating-overview {
  display: flex;
  width: 100%;
  background: #f6f6f6;
  padding: 20px;
  justify-content: space-around;
  position: relative;
  margin-top: 12px;
}

.doctor-rating-overview::after {
  content: "";
  height: 60%;
  width: 1px;
  position: absolute;
  background: #aaa;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.doctor-rating-overview p {
  margin: 0;
  color: #179d82;
  font-weight: 600;
  font-size: 18px;
}

.doctor-rating-overview > div {
  padding: 0 16px;
}

.subtext {
  font-weight: 500;
  font-size: 12px !important;
}

.experience-container {
  width: 100%;
  margin-top: 16px;
  padding: 32px;
  padding-block: 20px;
}

.experience-container h3 {
  font-weight: 600;
  font-size: 16px;
}

.experience-container p {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.timer-container {
  display: flex;
  align-items: center;
  background: #179d820f;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
}

.timer-container button {
  all: unset;
  border: 1px solid #179d82;
  padding: 12px 20px;
  border-radius: 14px;
  background: #179d8224;
  color: #179d82;
  font-weight: 600;
  font-size: 16px;
}

.timer-container h3 {
  font-weight: 500;
  font-size: 12px;
}

.timer-container p {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin: 0;
}

.pay-container {
  display: flex;
  align-items: center;
  background: #f6f6f6;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  margin-top: 20px;
  position: sticky;
  bottom: 0px;
}

.pay-container h3 {
  font-weight: 800;
  font-size: 14px;
}

.pay-container p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #179d82;
}

.modal-con {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: none !important; */
  offset: 0 !important;
  background: white;
  padding: 20px;
  max-height: 50%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  width: 350px;
  overflow: hidden;
  text-align: center;
}

.booking-container h3 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0%;
  color:#161616;


}

.booking-container h2 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  color:#161616;

}

.modal-con h2 {
  margin: 0;
  padding: 0;
}
.modal-con p {
  margin: 0;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.modal-con .cancel-button {
  all: unset;
  margin-top: 15px;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.instructions p {
  margin-bottom: 0;
}
