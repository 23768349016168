.how-it-works {
    text-align: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background: white;
    /* width: 1440px; */
  }
  
  .how-it-works h2 {
    font-size: 50px;
    margin-bottom: 1.5rem;
    color: #024d4c;
    font-weight: 700;
  }
/*   
  .steps-container {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .step {
    text-align: center;
    max-width: 300px;
  }
  
  .icon {
    font-size: 2.5rem;
    background-color: #e8f7f5;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #024d4c;
  }
  
  .step h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #024d4c;
  }
  
  .step p {
    font-size: 1rem;
    color: #666;
  } */
  
  /* Responsive Design */
  @media (max-width: 768px) {
    /* .steps-container {
      flex-direction: column;
      align-items: center;
    } */
  
    .step {
      max-width: 100%;
    }
    .how-it-works h2 {
      font-size: 32px;
    }
  }
  
  @media (max-width: 480px) {
    .how-it-works {
      padding: 2rem 1rem;
      width: auto;
      background: white;
    }
  
    /* .icon {
      font-size: 2rem;
      width: 50px;
      height: 50px;
    }
  
    .step h3 {
      font-size: 1rem;
    }
  
    .step p {
      font-size: 0.9rem;
    } */
  }
  

  .stepsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 40px 0;
    position: relative;
    z-index: 2;
  
   
  }
  .stepsContainer::before {
    content: '';
    position: absolute;
    top: 18%;
    /* left: 10%; */
    width: 71%;
    height: 1px;
    /* background-color: #d9e4ea; */
    border-top: 2px dashed #C8E7E1;
    z-index: 1;
    margin: 0 auto;
  }
  
  .stepsContainer .step {
    text-align: center;
    max-width: 200px;
    z-index: 2;
    @media (max-width: 768px) {
      .icon {
        margin-bottom: 8px;
      }
  
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -24px;
        width: 2px;
        height: 32px;
        background-color: #d9e4ea;
      }
    }
  }
  
  .stepsContainer .icon {
    width: 64px;
    height: 64px;
    background-color: #eafaf5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px;
  }
  
  .stepsContainer h3 {
    font-size: 18px;
    color: #003d34;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .stepsContainer p {
    font-size: 14px;
    color: #4c6361;
  }

  @media (max-width: 768px) {
    .stepsContainer {
      flex-direction: column;
      align-items: baseline;
      margin: 40px auto 0;
      max-width: 90%;
    }
  
    .stepsContainer::before {
      content: '';
      position: absolute;
      top: 10%;
      left: 32px;
      width: 2px;
      height: 70%;
      /* border-top: 2px dashed #C8E7E1; */
      border-left: 2px dashed #C8E7E1;;
    }
  
    .stepsContainer .step {
      display: flex;
      max-width: 100%;
        gap: 20px;
        text-align: left;
        align-items: center;
        margin-bottom: 30px;
    }
  
    .stepsContainer .step:not(:last-child)::after {
      display: none;
      /* content: '';
      position: absolute;
      left: 50%;
      bottom: -24px;
      width: 2px;
      height: 32px;
      background-color: #d9e4ea; */
    }
  }
  