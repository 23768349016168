.second-fold{position: relative;}


.sepreat {
    background-image: url(../images/thrd-fold-graduint.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 305px;
    height: 326px;
    left: 0;
}
.sepreat1 {
    background-image: url(../images/Background-Gradient2.svg);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    bottom: 0px;
    z-index: 1;
    width: 411px;
    height: 347px;
    left: 0;
}


.secnd-l-side img {
    padding-left: 85px;
}

.second-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;
}
.second-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}

.secnd-r-side{ position: relative;}

.secnd-r-side h2 {
    color: #181818;
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
    padding-top: 110px;
}
.secnd-r-side h2 span {
    color: #003C3C;
    font-weight: 800;
    font-family: 'GalanoGrotesqueHeavy', sans-serif;
}

.secnd-r-side::before{ background-image: url(../images/secnd-Topology.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -95px;
    z-index: 1;
    width: 585px;
    height: 528px;
    left: -160px;}


    .cont {
        margin-top: 80px;
    }
    .count-block {
        position: relative;
        z-index: 9;
        text-align: center;
        margin-bottom: 50px;
    }
    .count-block h4 {
        color: #000!important;
        margin: 20px 0 5px;
        font-weight: 700;margin-top: 0;
        font-size: 30px;
        position: relative;
        padding-bottom: 15px;font-family: 'GalanoGrotesqueBold', sans-serif;
    }
    .count-block h4::after {
        background: #E0E4E6!important;
        content: "";
        height: 1px;
        width: 180px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .count-block h4 b {
        color: #FB9F40;
    }

    .count-block p {
        color: #364855;
    }


    @media only screen and (max-width: 767px) { 

        .thrd-fold .col-md-3:nth-child(1) ~ * {
            margin-top: 30px!important;
        }
        .secnd-l-side img {
            display: none;
        }

        .secnd-r-side img {
            padding-left: 85px;
        }

        .secnd-r-side::before {
            top: 38px;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: inherit;
            right: 0;
        }
        .sepreat1 {
           
            bottom: 0px;
            z-index: 1;
            width: 100%;
            height: 347px;
            left: 0;
        }

        .count-block h4::after {
            
            width: 100%;
           
        }

        .secnd-r-side h2 {
          
            padding-top: 10px;
        }

        .second-fold h5 {
            padding-left: 40px;
        }
        .second-fold h5::before {
          left: 0px;
        }

        .secnd-r-side h2 {            
            font-size: 29px;
          
        }

        .cont {
            margin-top: 50px;
        }
        .count-block p {
            color: #364855;
            font-size: 13px;
        }

}