/* General Section Styling */
.app-promotion {
  background-color: #ffffff;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px 10px;
}

/* Logo Section */
.logo-section {
  display: none;
  margin-bottom: 15px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #003d3c;
}

.logo-highlight {
  color: #ff8500;
  /* Orange */
}

.logo-icon {
  color: #00d084;
  /* Green Heart/Icon */
  margin-right: 5px;
}

/* Promo Description */
.promo-description {
  display: none;
}

.mockup-container-mobile {
  display: none;
}

.desktop-view {
  display: flex;
  justify-content: space-evenly;
  .mockup-container-desktop {
    display: flex;
    justify-content: center;
  }
}

/* App Mockup Styling */


.download_section {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1em;
}

.download_title {
  font-size: 24px;
  font-weight: 600;
}

.download_description {
  width: 413px;
  text-align: start;
}

.logo_image_size{
  width: 116px;
}

.app-mockup-desktop {
  width: 548px;
  height: 384px;
}

.app-mockup {
  width: 250px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

/* Download Button */
.button-container {
  display: none;
  margin-top: 20px;
}

.download-btn {
  background-color: #ff8500;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #e67400;
}

/* Responsive Design */
@media (max-width: 768px) {

  /* General Section Styling */
  .app-promotion {
    background-color: #ffffff;
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px 10px;
  }

  /* Logo Section */
  .logo-section {
    display: contents;
    margin-bottom: 15px;
  }

  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #003d3c;
  }

  .logo-highlight {
    color: #ff8500;
    /* Orange */
  }

  .logo-icon {
    color: #00d084;
    /* Green Heart/Icon */
    margin-right: 5px;
  }

  /* Promo Description */
  .promo-description {
    display: block;
    font-size: 16px;
    color: #555;
    margin: 10px auto 20px;
    max-width: 80%;
    line-height: 1.5;
  }

  .desktop-view {
    display: none;
  }

  .download_section {
    display: none;
  }

  .mockup-container-desktop {
    display: none;
  }

  /* App Mockup Styling */
  .mockup-container-mobile {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }

  .app-mockup {
    width: 250px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }

  /* Download Button */
  .button-container {
    display: contents;
    margin-top: 20px;
  }

  .download-btn {
    background-color: #ff8500;
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .download-btn:hover {
    background-color: #e67400;
  }
}

@media (max-width: 480px) {
  .logo {
    font-size: 20px;
  }

  .app-mockup {
    width: 150px;
  }

  .promo-description {
    font-size: 12px;
    font-weight: 600;
    color: #083E3E;
  }
}