.sales-all-leads{

    .create-new-lead-trigger{
        color: white;
        font-weight: 700;
        width: fit-content;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 8px;
        background-color: blue;
        border: 2px solid blue;

        &:hover{
            cursor: pointer;
            color: blue;
            background-color: white;
        }
    }

    .title{
        margin: 10px 0px;
    }
    

    .leads{
        display: flex;
        flex-direction: row;
        gap: 2%;
        .left-section{
            height: 550px;
            overflow-y: scroll;
            overflow-x: scroll;
            width: 80%;
            .header{    
                background-color: rgb(229, 178, 186);
                display: flex;
                padding: 8px 10px;
                width: 150%;
                position: sticky;
                top: 0px;
                div{
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                }
                .sno{
                    width: 4%;
                }
                .lead-name{
                    width: 10%;
                }
                .patient-name{
                    width: 10%;
                }

                .patient-number{
                    width: 10%;
                }
                .patient-id{
                    width: 9%;
                }
                .enquiry-date{
                    width: 10%;
                }
                .category{
                    width: 7%;
                }
                .city{
                    width: 6%;
                }
                .marketing-source{
                    width: 12%;
                }
                .lead-status{
                    width: 14%;
                }
                .patient-type{
                    width: 8%;
                }
                .remark{
                    width: 10%;
                }
                .bd-name{
                    width: 8%;
                }
            }

            .all-leads{
                // overflow-x: scroll;
                .lead{
                    display: flex;
                    padding: 8px 10px;
                    width: 150%;
                    div{
                        font-size: 12px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                    }

                    &.assigned{
                        border-left: 10px solid green;
                        border-right: 10px solid green;
                    }

                    &:nth-child(2n){
                        background-color: rgba(128, 128, 128, 0.2);
                    }
                    .sno{
                        width: 4%;
                    }
                    .lead-name{
                        width: 10%;
                    }
                    .patient-name{
                        width: 10%;

                        &:hover{
                            cursor: pointer;
                            background-color:#d9d2d2;
                        }
                    }
    
                    .patient-number{
                        width: 10%;
                    }
                    .patient-id{
                        width: 9%;
                    }
                    .enquiry-date{
                        width: 10%;
                    }
                    .category{
                        width: 7%;
                    }
                    .city{
                        width: 6%;
                    }
                    .marketing-source{
                        width: 12%;
                    }
                    .lead-status{
                        width: 14%;
                    }
                    .patient-type{
                        width: 8%;
                    }
                    .remark{
                        width: 10%;
                    }
                    .bd-name{
                        width: 8%;
                    }
                }
            }
        }

        .right-section{
            display: flex;
            flex-direction: column;
            width: 28%;
            gap: 20px;
            .untouched-enquiry{
                background-color: #022C29;
                border-radius: 8px;
                margin: 0px;
                padding: 4px 10px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .title{
                    font-size: 16px;
                    font-weight: 600;
                    color: white;
                    margin: 0px;
                }

                .leads{
                    height: 290px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;

                    &::-webkit-scrollbar{
                        display: none;
                    }
                    .lead{
                        justify-content: space-evenly;
                        font-size: 12px;
                        display: flex;
                        flex-direction: row;
                        font-weight: 550;
                        background-color: white;
                        padding: 2px 4px;
                        border-radius: 8px;
                        gap: 4px;
                    }
                }
            }

            .upcoming-appointments{
                background-color: #022C29;
                border-radius: 8px;
                margin: 0px;
                padding: 4px 10px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .title{
                    font-size: 16px;
                    font-weight: 600;
                    color: white;
                    margin: 0px;
                }

                .appointments{
                    height: 290px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    gap: 6px;
                    &::-webkit-scrollbar{
                        display: none;
                    }

                    .appointment{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        font-size: 12px;
                        font-weight: 550;
                        background-color: white;
                        padding: 2px 4px;
                        border-radius: 8px;
                        gap: 4px;

                        .left{
                            flex: 2;
                            .name{
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                        .right{
                            flex: 3;
                        }


                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }

    }


    .temp-leads{
        padding: 20px;
        border: 2px solid grey;

    
        .temp-lead{
            
            padding: 4px 10px;
            display: flex;
            justify-content: space-around;
    
            &.headers{
                font-weight: 700;
                font-size: 20px;

                .new-lead{
                    background-color: rgb(227, 221, 221);
                    color: black;
                }
            }

            div{
                width: 12%;
            }

            &:hover{
                cursor: pointer;
            }

            .new-lead{
                background-color: red;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: 500;
                border-radius: 20px;

                &.new{
                    background-color: green;
                }
            }
    
            &:nth-child(2n+1){
                background-color: rgb(227, 221, 221);
            }
            &:nth-child(2n){
                background-color: white;
            }


            &.recent{
                background-color: rgba(243, 184, 6, 0.979);
            }
        }
    }

    .filters-bar{
        margin-top: 40px;
        margin-bottom: 20px;
        background-color: #ffc0cb50;
        width: 100%;
        // height: 40px;

        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 20px;

        .headers{
            display: flex;
            flex-direction: row;
            text-align: center;

            .clear{
                padding: 4px 10px;
                border-radius: 6px;
                background-color: rgb(185, 180, 180);
                font-size: 12px;

                &:hover{
                    background-color: grey;
                    cursor: pointer;
                    color: white;
                }
            }

            div{
                flex: 1;
                &:nth-child(1){
                    flex: 0.3;
                }
                .btn{
                    width: 20px;
                    height: 20px;
                    // background-color: #f9f7f7;
                    height: 20px;
                    background-color: #b5a6a6;

                    &:hover{
                        cursor: pointer;
                        background-color: #797575;
                    }
                }

                &.filter-applied{
                    span{
                        padding-left: 10px;
                        border-left: 8px solid rgb(216, 115, 0);
                        border-bottom: 2px solid rgb(216, 115, 0);
                        // border-top: 2px solid rgb(216, 115, 0);
                        border-radius: 4px;
                    }
                }
            }
        }
        
        .filters{
            display: flex;
            flex-direction: row;

            div{                
                flex: 1;
                text-align: center;
                &:nth-child(1){
                    flex: 0.3;
                }

                
            }


        }

    }
}

.modal-msg{
    position: absolute;
    right: 40px;
    bottom: 602px;
    font-weight: 600;
    font-size: 12px;
    background-color: #068c69;
    padding: 4px 10px;
    border-radius: 12px;
    color: white;

}

.filter-applied-msg{
    position: absolute;
    right: 40px;
    bottom: 550px;
    font-weight: 700;
    font-size: 12px;
    background-color: rgb(255, 221, 0);
    padding: 4px 10px;
    border-radius: 12px;
    color: black;
}

.temp-lead-modal{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;

    .lead-details{
        display: flex;
        flex-direction: column;
        gap: 10px;

        // .discard-lead{
        //     font-weight: 600;
        //     font-size: 18px;
        //     color: white;
        //     background-color: red;
        //     padding: 8px 20px;
        //     width: fit-content;
        //     border-radius: 8px;
        //     border: 2px solid red;
        //     &:hover{
        //         background-color: white;
        //         color: red;
        //         cursor: pointer;
        //     }
        // }

        div{
            display: flex;
            div{
                width: 100px;
                font-weight: 600;
                font-size: 18px;
            }
        }

        .title{
            font-size: 20px;
            font-weight: 700;
        }
    }

    .lead-form{

        display: flex;
        flex-direction: column;
        gap: 10px;

        .select-locality{
            font-size: 18px;
            font-weight: 600;
        }

        .address-input-label{
            font-size: 18px;
            font-weight: 600;
        }

        .date-input{
            .title{
                font-size: 18px;
                font-weight: 600;
            }
        }
        .time-input{
            .title{
                font-size: 18px;
                font-weight: 600;
            }
        }

        .submit-btns{
            display: flex;
            gap: 30px;
    
            div{
                padding: 4px 10px;
                border-radius: 8px;
                color: white;
                font-weight: 400;
                width: 60px;
                text-align: center;
            }
    
            .yes{
                background-color: green;
                border: 2px solid green;
                &:hover{
                    background-color: white;
                    cursor: pointer;
                    color: green;
                }
            }
            .no{
                background-color: red;
                border: 2px solid red;  
                &:hover{
                    background-color: white;
                    cursor: pointer;
                    color: red;
                }
            }
        }

        .docs-of-locality{
            margin-top: 20px;
            .title{
                font-size: 18px;
                font-weight: 600;
            }
        }
    
        .address-input{
            width: 100%;
            height: 100px
        }
    
        .home-msg{
            position: absolute;
            bottom: 40px;
            right: 40px;
            font-size: 20px;
            font-weight: 500;
            padding: 4px 20px;
            background-color: #d9d2d2;
            border-radius: 8px;
        
        }
    }

    

    
}


.lead-substatus-popup-overlay{
    background: rgba(0, 0, 0, 0.1)
}

.lead-substatus-popup-content{

    div{
        padding: 20px;
        flex-direction: column;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .lead-substatus-title{
            padding: 0px;
            color: #068c69;
            font-weight: 700;
            font-size: 20px;
        }
        
        .lead-substatus{
            padding: 0px;
            color: #f58625;
            font-weight: 650;
            font-size: 18px;
        }
    }
}


.create-new-lead-content{
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    
    .new-lead{

        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        div{
            text-align: center;
            font-weight: 600;
            font-size: 20px;
        }

        .title{
            width: 100%;
            background-color: #068c69;
            color: white;
            font-weight: 700;
            text-align: center;
            padding: 8px 12px;
            font-size: 20px;
        }

        .submit{
            background-color: #068c69;
            color: white;
            border: 2px solid #068c69;
            padding: 6px 10px;
            width: fit-content;
            border-radius: 6px;

            &:hover{
                cursor: pointer;
                background-color: white;
                color: #068c69;
            }
        }
    }
}