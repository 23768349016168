.sales-home{
    .head{
        padding: 20px;
        
        img{
            width: 10%;
        }
    }
    .title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #068c89;

        .logout{
            position: absolute;
            font-size: 20px;
            font-weight: 500;
            color: black;
            padding: 4px 10px;
            border-radius: 8px;
            background-color: rgb(185, 180, 180);
            left: 20px;

            &:hover{
                background-color: grey;
                cursor: pointer;
                color: white;
            }
        }
    }

    .sales-auth{
        padding: 20px;
    }

    .home{
        
        padding: 20px;
        .sales-options{
            margin-top: 20px;
            display: flex;   
            background-color: white;
            gap: 4px;

            div{
                flex: 1;
                background-color: rgb(227, 221, 221);
                padding: 4px 10px;
                text-align: center;
                border-radius: 4px;
                color: black;

                &:hover{
                    cursor: pointer;
                    background-color: rgb(99, 99, 99);
                    color: white;
                }

                &.active{
                    background-color: rgb(99, 99, 99);
                    color: white;

                    &:hover{
                        background-color: rgb(227, 221, 221);
                        color: black;
                    }
                }
            }
        }


        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logout{
                padding: 5px 12px;
                background-color: rgb(227, 221, 221);
                border-radius: 4px;

                &:hover{
                    cursor: pointer;
                    background-color: grey;
                }
            }


            .title{
                font-size: 20px;
                font-weight: 600;
                color: black;
            }
        }

        .content{
            .sales-navbar{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: #80808066;
                padding: 4px 20px;
                align-items: center;
        
                .tabs{
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    font-weight: 700;
                    font-size: 14px;
        
                    &:hover{
                        cursor: pointer;
                    }
        
                    .active{
                        color: blue
                    }
                }
        
                .search-bar{
                    background-color: white;
                    padding: 2px 10px;
                    border-radius: 12px;
                    
                    input{
                        border: 0px;
                    }
                }
            }
        
        }

    }
}


