.baner-region_aboutus {
    background: #003c3c;padding-bottom: 0; position: relative;overflow: hidden;
}

.baner-region_aboutus h3 {
    font-weight: 500;
    line-height: 40px;
    font-size: 22px;
    color: #fff;
}

.baner-region_aboutus .left-side {

    position: relative;
    z-index: 99;
}

.baner-region_aboutus h1 {
    line-height: 40px; letter-spacing: 1px;
    font-size: 32px;
    color: #fff;text-transform: uppercase; font-weight: 800;font-family: 'GalanoGrotesqueHeavy', sans-serif;
}

.baner-region_aboutus p {
    color: #fff;
    font-size: 12px;
    line-height: 24px;
}

.baner-region_aboutus .btn {
    background: #FB9F40;
    border: 1px solid #FB9F40;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 16px 12px 16px;
}
.baner-region_aboutus .aft {
    position: absolute;
    top: -90px;
    right: -109px;
}
.baner-region_aboutus .right-side {
    position: relative;
}

.baner-region_aboutus .right-side::after {
    background-image: url("../images/Background-Gradient.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 122px;
    width: 445px;
    height: 100%;
    z-index: -1;
    left: 0px;
}

.baner-region_aboutus .right-side::before {
    background-image: url("../images/Ellipse-3032.svg");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 92px;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: -20px;
    background-size: contain;
}
.baner-region_aboutus .right-side::after {
    background-image: url("../images/Background-Gradient.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    bottom: 0;
    z-index: 1;
    width: 128px;
    height: 61px;
    left: -20px;
}
.baner-region_aboutus img.bnrimg {
    z-index: 1;
    position: relative;
}

.baner-region_aboutus .tag {
    position: absolute;
    right: 25px;
    z-index: 9;
    top: 167px;
}

.bs {
    border-right: 2px solid #003c3c;
    min-height: 150px;
}

@media only screen and (max-width: 767px) { 

    .bs {
        border-right: none;
        border-bottom: 2px solid #003c3c;;
        min-height: 1px;
    }



    .baner-region_aboutus .right-side::before {
        top: 85px;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0px;
    }
    .baner-region_aboutus .tag {
    position: absolute;
    right: -10px;
    z-index: 999;
    top: 125px;
}
.baner-region_aboutus .aft {
    position: absolute;
    top: -390px;
    right: inherit;
    left: 0;
}
.baner-region_aboutus .aft img {
    max-width: 100%;
}

.baner-region_aboutus .left-side::after {
    
    top: 122px;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0px;
}

img.bnrimg {
    z-index: 1;
    position: relative;
}
.baner-region_aboutus .right-side::after {
       bottom: 0;
    z-index: 1;
    width: 128px;
    height: 61px;
    left: -20px;
}
.baner-region_aboutus .ml-70 {
    margin-left: 0px;
}


}