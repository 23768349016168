.baner-region {
    background: #003c3c;padding-bottom: 0; position: relative; overflow: hidden;
}

.baner-region h3 {
    font-weight: 500;
    line-height: 40px;
    font-size: 22px;
    color: #fff;
}
.yellow{color: yellow;}
.left-side {

    position: relative;
    z-index: 99;
}
.left-side::after {
    background-image: url("../images/Background-Gradient.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 122px;
    width: 445px;
    height: 100%;
    z-index: -1;
    left: 0px;
}
.baner-region h1 {
    line-height: 40px; letter-spacing: 1px;
    font-size: 32px;
    color: #fff;text-transform: uppercase; font-weight: 800;font-family: 'GalanoGrotesqueHeavy', sans-serif;
}

.baner-region p {
    color: #fff;
    font-size: 15px;
    line-height: 24px;
}

.baner-region .btn {
    background: #FB9F40;
    border: 1px solid #FB9F40;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 16px 12px 16px;
}
.aft {
    position: absolute;
    top: -90px;
    right: -109px;
}
.right-side {
    position: relative;
}
.right-side::before {
    background-image: url("../images/Ellipse-3032.svg");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 92px;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: -20px;
    background-size: contain;
}
.right-side::after {
    background-image: url("../images/Tags.png");
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    bottom: 0;
    z-index: 1;
    width: 128px;
    height: 61px;
    left: -20px;
}
img.bnrimg {
    z-index: 1;
    position: relative;
}

.tag {
    position: absolute;
    right: 25px;
    z-index: 9;
    top: 167px;
}

@media only screen and (max-width: 767px) { 



    .right-side::before {
        top: 85px;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0px;
    }
.tag {
    position: absolute;
    right: -10px;
    z-index: 999;
    top: 125px;
}
.aft {
    position: absolute;
    top: -390px;
    right: inherit;
    left: 0;
}
.aft img {
    max-width: 100%;
}

.left-side::after {
    top: 130px;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: -53px;
    background-size: cover;
    opacity: 0.7;
}

img.bnrimg {
    z-index: 111!important;
    position: relative;
}
.right-side::after {
       bottom: 0;
    z-index: 111;
    width: 128px;
    height: 61px;
    left: -20px;
}
.ml-70 {
    margin-left: 0px;
}


}

@media only screen and (max-width: 600px) {

    p.mob-noee {
        display: none;
    }



    
}

.nav-item.butn {
    display: none;
  }

  .nav-item.butn.seo {
    display: block;
  }