
.seven-fold {
    position: relative;
    background: #f7f7f7;
}

.home-visit::before {
    background-image: url(../images/home-visit-Topology.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 0px;
    z-index: 1;
    width: 149px;
    height: 100%;
    left: 7px;
    background-size: contain;
}

.home-visit::after {
    background-image: url(../images/shadow-Gradient.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 19px;
    z-index: 1;
    width: 403px;
    height: 100%;
    right: 7px;
    background-size: contain;
}

.seven-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;
}
.seven-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}
.seven-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    z-index: 9;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'GalanoGrotesqueBold', sans-serif;

}
.home-visit {
    background: #003C3C;
    padding: 0px 40px 0px 80px;
    border-radius: 8px; position: relative;margin-top: 15px;
    margin-left: 130px;
}
.home-visit h3 {
    font-size: 17px;
    color: #fff;line-height: 25px;

}
.home-visit p {
    font-size: 13px;
    color: #fff;    line-height: 20px;
}
.home-visit p span {
    color: #FB9F40;
    font-size: 15px;
    font-weight: 600;
    position: relative;
}

.seven-r-side {
    position: relative;
    z-index: 999;
}

.seven-r-side::after {
    background-image: url(../images/round.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: 52px;
    z-index: -1;
    width: 168px;
    height: 100%;
    right: 90px;
}

.thrs-one img {
    position: absolute;
    right: 0;
    top: 0;
}

.thrs-one {
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background: #fff;
    position: relative;
    min-height: 105px;
    width: 280px;
    padding: 45px 15px 0;overflow: hidden;
}

.thrs-one::before {
    background-image: url(../images/medi-round.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -14px;
    z-index: 1;
    width: 80px;
    height: 100%;
    right: -14px;
}
.thrs-one::after {
    background-image: url(../images/medi-line.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -17px;
    width: 118px;
    height: 100%;
    right: -21px;
    transform: rotate(-0.35turn);
}

.thrs-part {
    margin-left: 150px;
    margin-top: 50px;
}

.thrs-one h4 {
    font-size: 15px;
    color: #000;line-height: 24px;
}

.thrs-part .col-md-4:nth-child(3) ~ * {
    margin-top: 30px;
}

.all .btn {
    background: #fff;
    border: 1px solid #FB9F40;
    color: #FB9F40;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px 12px 16px;
    min-width: 340px;
}
.all.text-center {
    margin-top: 30px;
}

.gradss {
    position: absolute;
    left: 160px;
    top: 0;
}
.gradss img {
    max-width: 100%;
}

@media only screen and (max-width: 767px) { 

    .thrs-one img {
        position: absolute;
        right: 0;
        top: 6px;
        height: 52%;
    }
    .thrs-one::before {
        top: -14px;
        z-index: 1;
        width: 80px;
        height: 100%;
        right: -28px;
        background-size: 75%;
    }
    .thrs-one::after {
        top: -49px;
        width: 118px;
        height: 100%;
        right: -28px;
        transform: rotate(-0.35turn);
        background-size: 62%;
    }


    .home-visit {
        padding: 0px 40px 0px 20px;
        margin-top: 15px;
        margin-left: 0px;overflow: hidden;
    }
.thrs-part {
    margin-left: 0px;
    margin-top: 30px;
}
.thrs-one {
       min-height: 90px;
    width: 100%;
    padding: 30px 10px 0;
    overflow: hidden;
}
.thrs-part .col-md-4:nth-child(2) ~ * {
    margin-top: 30px;
}
.all .btn {
    min-width: 100%;
}
.all.text-center {
    margin-top: 10px;
}

.gradss img {
    max-width: inherit;
}
.seven-fold {
overflow: hidden;
}
.seven-r-side::after {
    
    top: 54px;
    z-index: -1;
    width: 168px;
    height: 100%;
    right: -48px;
}

.home-visit h3, .home-visit p, .mrm-btm {
       z-index: 999999;
    position: relative;
}
.home-visit p {
    font-size: 10px;
    color: #fff;
    line-height: 20px;
}

.home-visit::after {

    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    right: -30px;
    background-size: cover;
}

.seven-fold h5 {
      padding-left: 40px;
}
.seven-fold h5::before {
    left: 0px;
}

.seven-fold h2 {
    font-size: 29px;
   
}
.thrs-one h4 {
    font-size: 12px;
    color: #000;
    line-height: 20px;
}

}

.bookslot .btn {
    background: #FB9F40;
    border: 1px solid #FB9F40;
    color: #fff;
    display: inline-block;
    margin-top: 0px;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    padding: 12px 32px 12px 32px;
}
.bookslot .btn a{ color:#fff;text-decoration: none;}