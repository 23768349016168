
.five-fold {
    position: relative;background: #f7f7f7;
 }

.five-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;
}
.five-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}
.five-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    z-index: 9;margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'GalanoGrotesqueBold', sans-serif; text-transform: uppercase;
}

.five-fold h2 span{
    color: #003C3C;
  
}
.five-fold h3{ font-size: 24px; color: #000;}

.table-portion {
    border: 1px solid #000000;
    border-radius: 8px;
    width: 803px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 0 0px 0px 0px;
    position: relative;
}

.table-portion::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -230px;
    z-index: 1;
    width: 100%;
    height: 100%;
    right: -230px;
    background-size: cover;
}


.tble h6 {
    color: #000;
    font-size: 13px;
    margin-top: 0px;line-height: 21px;
}
.tble h6 span {
    color: #5FA61A;
}
.tble {
    margin-top: 25px;
    text-align: left;
    padding-left: 80px;min-height: 70px;
}
.table-portion h4 {
    background: #000;
    color: #fff;
    padding: 16px 30px;
    text-align: center;min-height: 55px;
    font-size: 16px;
}
.table-portion h4 img {
    padding-right: 10px;
}
.tble img {
    margin-bottom: 10px;
}
.table-portion .col-md-4 {
    padding: 0 0;
}

.table-portion .row {
    margin: 0 0;
}
.radus{ border-radius: 8px 0px 0px 8px;}
.radus-r{ border-radius: 0px 8px 8px 0px;}

.tble p {
    font-size: 13px;
    color: #000;
}
.brder-right{border-right: 1px solid #000;}
.brder-left{border-left: 1px solid #000;}

@media only screen and (max-width: 767px) { 

    .table-portion {
         width: 100%;
        margin-top: 40px;
    
    }

    .table-portion::before {       
        top: -230px;       
        right: 0; z-index: -1;

    }

    .tble {
        margin-top: 25px;
        text-align: left;
        padding-left: 10px;
        min-height: 70px;
        padding-right: 10px;
    }
    .table-portion h4 {
        padding: 16px 6px;
        min-height: 55px;
        font-size: 11px;
    }
    .tble p {
        font-size: 10px;
        color: #000;
        line-height: 13px;
    }
    .tble h6 {
        color: #000;
        font-size: 10px;
        margin-top: 0px;
        line-height: 13px;
    }
    .table-portion h4 img {
        padding-right: 4px;
        max-width: 18px;
    }
    .five-fold h5 {
        padding-left: 40px;
    }
    .five-fold h5::before {
      left: 0px;
    }
    .five-fold h2 {
        font-size: 29px;        
    }
    

}