.booking_slot .booking-form {
    width: 90%;
    padding: 20px 0;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.booking_thankyou {
    margin-top: 80px;
}

.booking_slot .section {
    /* margin-bottom: 20px; */
}

.booking_slot .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}

.booking_slot .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.booking_slot .confirm-button {
    width: 100%;
    padding: 15px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
}

.booking_slot .confirm-button:hover {
    background-color: #999;
}

.booking_slot .title {
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
    font-weight: 600;
}

.booking_slot .input-field1 {
    /* width: 311px; */
    /* height: 48px; */
    /* padding: 14px 164px 14px 16px; */
    /* gap: 0px; */
    /* border-radius: 8px 0px 0px 0px; */
    /* opacity: 0px; */
    background-color: #C4C4C4;

}

.booking_slot>.content {
    font-size: 12px;
    font-weight: 500;
    color: grey;
    text-align: center;
}

.booking_slot .carousel-container {
    margin-bottom: 40px;
    /* Adjust the value as per your layout needs */
}

.booking_slot .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking_slot .slick-slide img {
    width: 100%;
    /* Ensure image takes full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensure the image covers the entire container */
    max-height: 400px;
    /* You can set a max-height based on your needs */
}

@media (max-width: 768px) {
    .booking_thankyou {
        margin-top: 57px;
    }
  }
  
