.overview-item {
    display: flex;
    // flex-direction: row;
    /* gap: 20px; */
    justify-content: space-between;
    padding: 20px 160px;
    height: fit-content;
    .overview-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 72px;
        padding-right: 72px;
        width: 40%;
        filter: drop-shadow(-82px -213px 180px #068C69);

        .title {
            font-size: 32px;
            font-weight: 700;
            span {
                color: #068C69;
            }
        }
        .description {
            /* font-size: 12px; */
            opacity: 0.75;
        }
        .links {
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .book-btn {
                background-color: white;
                padding: 10px 14px;
                border-radius: 10px;
                color: #068C69;
                border: 2px solid #068C69;
                font-weight: 600;
                width: 280px;
                svg {
                    width: 24px;
                    height: 16px;
                    fill: #068c69;
                    /* padding-right: 10px; */
                }
                &:hover {
                    background-color: #068C69;
                    color: white;
                    /* z-index: 10; */
                    cursor: pointer;
                    svg {
                        fill: white;
                    }
                }
            }
            .contact-btn {
                font-weight: 600;
                background-color: white;
                padding: 10px 14px;
                border-radius: 24px;
                color: #068C69;
                border: 2px solid #068C69;

                &:hover {
                    background-color: #068C69;
                    color: white;
                    /* z-index: 10; */
                    cursor: pointer;
                }
            }

            .callback {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .head {
                    color: #068C69;
                    font-size: 18px !important;
                    font-weight: 500;
                }

                .input {
                    display: flex;
                    flex-direction: row;
                    padding: 4px 10px;
                    width: fit-content;
                    background-color: #068C69;
                    align-items: center;
                    border-radius: 10px;

                    .number {
                        padding: 4px 0px 4px 0px;

                        span {
                            padding-right: 8px;
                            color: white;
                            font-weight: 500;
                            border-right: 1px solid white;
                        }

                        input {
                            margin: 0;
                            font-family: inherit;
                            font-size: inherit;
                            line-height: inherit;
                            padding-left: 10px;
                            color: white;
                            background-color: #068c69;
                            border: 0px;

                            &::placeholder {
                                color: white;
                            }

                            &:focus-visible {
                                border: 1px solid white;
                                outline-width: 0px;
                            }

                            &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                margin: 0px;
                            }


                            

                        }
                    }


                    .phone-icon {
                        padding: 5px 10px;
                        border-left: 0px;
                        border-radius: 0px 8px 8px 0px;
                        height: max-content;
                        align-items: center;
                        display: flex;
                        border-left: 1px solid white;

                        &:hover {
                            cursor: pointer;
                        }

                        svg {
                            width: 18px;
                            height: 18px;
                            /* color: #068c68b2; */
                            color: white;
                            fill: white;
                        }
                    }
                
                }

                .callback-phone-err{
                    font-size: 12px;
                    color: red;
                }

                .callback-submit-success{
                    font-size: 14px;
                    color: #068C69;
                }
            }
        }
    }
}

.overview-item>img {
    width: 54%;
}

.carousel {
    height: 674px;
}

@media screen and (max-width: 1100px) {
    .carousel {
        height: 480px;
    }

    .overview-item {
        padding: 20px 120px;
        img {
            width: 50%;
        }
        .overview-content {
            width: 48%;
            padding-right: 50px;
            padding-top: 50px;
            .links {
                div{
                    font-size: 15px;
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .overview-item {
        padding: 20px 110px;
        img {
            width: 46%;
            height: 100%;
            margin: auto;
        }

        .overview-content {
            width: 48%;
            padding-right: 40px;
            padding-top: 40px;
        }
    }

    .carousel {
        height: 420px;
    }
}

@media screen and (max-width: 800px) {
    .carousel {
        height: 400px;
    }

    .overview-item {
        padding: 20px 80px;

        .overview-content{
            .title {
                font-size: 26px;
            }
            .description {
                font-size: 12px;
            }
            .links {
                width: 120%;
                .book-btn {
                    font-size: 12px;
                    width: 280px;
                }
                .contact-btn {
                    font-size: 12px;
                }
            }
        }
    }

}

@media screen and (max-width:480px) {
    .carousel {
        height: 710px;
    }

    .overview-item {
        padding: 20px 40px;
        flex-direction: column;
        gap: 32px;

        img {
            width: 100%;
        }

        .overview-content {
            width: 100%;
            padding: 10px;
            z-index: 100;

            .callback{
                width: 250px;
                .head{
                    width: 250px;
                    white-space: break-spaces;
                }

                .input {
                    width: 250px;
                    .number{
                        input{
                            width: 165px;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 350px) {

    .overview-item{
        .overview-content{
            .links {
                display: flex;
                flex-direction: column;
                div {
                    width: fit-content;
                }

                .callback {
                    .head{
                        width: 250px;
                        white-space: break-spaces;
                    }

                    .input {
                        width: 250px;
                        .number{
                            input{
                                width: 165px;
                            }
                        }
                    }
                }

                .book-btn{
                    width: 250px;
                }
            }
        }
    }
}