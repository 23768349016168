.booking_slot_new_flow {
  margin-top: 80px !important;
}
.booking_slot_new_flow .booking-form {
    width: 100%;
    padding: 20px 0;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.booking_slot_new_flow .MuiSelect-select {
  min-height: 0px;
  line-height: 40px;
  height: 0px;
  padding: 0 10px;
}

.booking_slot_new_flow .section {
    /* margin-bottom: 20px; */
}

.booking_slot_new_flow .carousel-heading {
  font-size: 20px;
}

.booking_slot_new_flow .suggestions-list {
  position: absolute;
  top: 100%; /* Position right below the input */
  left: 0;
  right: 0;
  z-index: 2;
  max-height: 200px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Add scroll if suggestions are too long */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px; /* Small gap between input and dropdown */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-left: 0px;

  
}

.booking_slot_new_flow .suggestions-list li {
  padding: 8px 12px;
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid;
  font-size: 12px;

  
}

.booking_slot_new_flow .suggestions-list li:hover {
  background-color: #f0f0f0;
}

.booking_slot_new_flow .detect_location {
  text-align: left;
    background: white;
    justify-content: flex-start;
    gap: 10px;
    padding: 5px 0;
    font-size: 11px;
    font-weight: 600;
}

.booking_slot_new_flow .detect_location svg{
  font-size: 20px;
}

.booking_slot_new_flow .address_label {
  position: relative;
  padding: 5px 44px 5px 5px;
  /* border: 1px solid #ccc; */
  font-size: 12px;
  border-radius: 10px;
  background: white;
  word-break: break-word;

}




.booking_slot_new_flow .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}

.booking_slot_new_flow .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.booking_slot_new_flow .confirm-button {
    width: 100%;
    padding: 15px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
}

.booking_slot_new_flow .confirm-button:hover {
    background-color: #999;
}

.booking_slot_new_flow .title {
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
    font-weight: 600;
}

.booking_slot_new_flow .input-field1 {
    /* width: 311px; */
    /* height: 48px; */
    /* padding: 14px 164px 14px 16px; */
    /* gap: 0px; */
    /* border-radius: 8px 0px 0px 0px; */
    /* opacity: 0px; */
    background-color: #C4C4C4;

}

.booking_slot_new_flow .content {
    font-size: 12px;
    font-weight: 500;
    color: grey;
    text-align: center;
    width: 100%;
}

.booking_slot_new_flow .carousel-container {
    margin-bottom: 10px;
    /* Adjust the value as per your layout needs */
}

.booking_slot_new_flow .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking_slot_new_flow .slick-slide img {
    width: 100%;
    /* Ensure image takes full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensure the image covers the entire container */
    max-height: 400px;
    /* You can set a max-height based on your needs */
}

.booking_slot_new_flow .slick-prev:before,
.booking_slot_new_flow .slick-next:before {
  content: ''; /* Remove the default arrow content */
}

.booking_slot_new_flow .slick-prev,
.booking_slot_new_flow .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px; /* Circle width */
  height: 25px; /* Circle height */
  border-radius: 50%; /* Makes it a circle */
  background-color: gray; /* Background color */
  z-index: 1;
}

.booking_slot_new_flow .slick-prev {
  left: 10px; /* Adjust inside slider */
}

.booking_slot_new_flow .slick-next {
  right: 10px; /* Adjust inside slider */
}

.booking_slot_new_flow .slick-prev:after,
.booking_slot_new_flow .slick-next:after {
  content: '';
  display: inline-block;
  width: 10px; /* Arrow width */
  height: 10px; /* Arrow height */
  border-top: 2px solid black; /* Arrow styling */
  border-right: 2px solid black;
  transform: rotate(135deg); /* Rotate for arrow direction */
}

.booking_slot_new_flow .slick-prev:after {
  transform: rotate(-135deg); /* Left arrow */
}

.booking_slot_new_flow .slick-next:after {
  transform: rotate(45deg); /* Right arrow */
}


.booking_slot_new_flow .input_custom_wrapper {
  margin-bottom: 20px;
  max-width: 500px !important;
  margin: 0 auto;
  gap: 10px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  box-shadow: 0px 7px 16px 0px #083E3E0A;
  box-shadow: 0px 29px 29px 0px #083E3E08;
  box-shadow: 0px 66px 40px 0px #083E3E05;
  box-shadow: 0px 118px 47px 0px #083E3E03;
  box-shadow: 0px 184px 51px 0px #083E3E00;
  background: white;
  height: Hug(298px) px;
  top: 217px;
  left: 26px;
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;
  margin-bottom: 20px;
    width: 90% !important;
    gap: 10px;
    display: flex
;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 20px;
}

.booking_slot_new_flow .input_custom_wrapper  h2{
  font-size: 18px;
  font-weight: 700;
}

.booking_slot_new_flow .consultation-price {
  font-size: 12px;
  color: #333;
  background-color: #f9f9f9; /* Light gray background */
  padding: 15px 20px; /* Add padding around the text */
  border-radius: 8px; /* Rounded corners for the box */
  border: 1px solid #ddd; /* Light border for structure */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px;
  max-width: 600px; /* Optional: Limit the width */
  width: 90%;
  margin: 0 auto;
  line-height: 1.5; /* Improve readability */
  text-align: center;
}

.booking_slot_new_flow .consultation-price .old-price {
  text-decoration: line-through;
  color: #999; /* Gray color for the old price */
  margin-right: 5px;
}

.booking_slot_new_flow .consultation-price .new-price {
  color: green; /* Highlight new price in red */
  font-weight: bold;
}

.booking_slot_new_flow .consultation-price p {
  margin: 0; /* Remove default paragraph margin */
}

.booking_slot_new_flow .feature-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between features */
  max-width: 400px; /* Optional: Limit width */
  margin: 0 auto; /* Center align */
  padding: 20px; /* Add padding */
  width: 90%;
  background-color: #f9f9f9;
  max-width: 500px;
  border-radius: 8px;
}

.booking_slot_new_flow .feature-item {
  display: flex;
  align-items: flex-end;
  gap: 15px; /* Space between icon and text */
}

.booking_slot_new_flow .feature-icon {
  font-size: 36px; /* Large icon size */
  color: #d32f2f; /* Highlight color for the icon */
  flex-shrink: 0; /* Prevent shrinking */
  width: 50px;
    text-align: center;
}

.booking_slot_new_flow .feature-text h3 {
  font-size: 18px;
  color: #333; /* Title color */
  margin: 0; /* Remove margin */
}

.booking_slot_new_flow .feature-text p {
  font-size: 14px;
  color: #555; /* Subtext color */
  margin: 0; /* Remove margin */
}

@media (max-width: 768px) {
  .booking_slot_new_flow {
    margin-top: 57px !important;
  }
}


