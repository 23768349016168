.chat-window{
    padding: 20px;
    .window-title{
        text-align: center;
        margin: 10px 0px;
        color: #068c69;
        font-size: 20px;
        font-weight: 600;
        width: 100%;
    }
    .chat360-authentication{
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: fit-content;
        margin: auto;
        .username{
            div{
                text-align: center;
            }
        }
        .password{
            div{
                text-align: center;
            }
        }
        .submit{
            font-weight: 400;
            font-size: 18px;
            background-color: rgb(185, 180, 180);
            padding: 4px 10px;
            color: black;
            border-radius: 8px;
            text-align: center;

            &:hover{
                cursor: pointer;
                background-color: grey;
                color: white;
            }
        }

    }

    .wa-modal-msg{
        position: absolute;
        bottom: 20px;
        right: 20px;
        background-color: #068c69;
        color: white;
        padding: 4px 10px;
        border-radius: 10px;
        font-size: 20px;
    }


    .chat{
        background-color: #f7f9f9;
        width: 100%;
        height: 100%;
        width: initial;
        height: 480px;

        .chat-messages{
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 80%;
            overflow-y: scroll;


            .user-msg{
                display: flex;
                justify-content: flex-start;
                .msg{
                    width: fit-content;
                    background-color: grey;
                    padding: 4px 10px;
                    border-radius: 10px;
                    color: white;
                }
            }

            .bot-msg{
                display: flex;
                justify-content: flex-end;
                .msg{
                    width: fit-content;
                    max-width: 80%;
                    background-color: #068c69;
                    padding: 4px 10px;
                    border-radius: 10px;
                    color: white;
                }
            }
            .session-msg{
                display: flex;
                justify-content: flex-end;
                .msg{
                    width: fit-content;
                    max-width: 80%;
                    background-color: #068c69;
                    padding: 4px 10px;
                    border-radius: 10px;
                    color: white;
                }
            }
        }

        .enter-msg{
            padding: 20px;
            display: flex;
            gap: 2%;

            input{
                padding: 4px 10px;
                width: 90%;
                border: 0px;
                background-color: grey;
                color: white;
                border-radius: 8px;
            }
            .send{
                padding: 4px 10px;
                width: 8%;
                background-color: #068c69;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;


                &:hover{
                    background-color: #045b43;
                    cursor: pointer;
                }
            }
        }
    }
}


.popup-content{
    width: 60% !important;
}