.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0;margin-left:auto;margin-right:auto}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-initialized .slick-slide,.slick-slide img{display:block}.slick-arrow.slick-hidden,.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}


.eight-fold{position: relative;padding-bottom: 100px;}
.eight-fold h5 {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: #9C0D38;
}
.eight-fold h5::before {
    position: absolute;
    height: 1px;
    background: #9C0D38;
    content: "";
    width: 24px;
    left: -30px;
    top: 6px;
}
.eight-fold h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    z-index: 9;margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'GalanoGrotesqueBold', sans-serif; 
}

.eight-fold h3 {
    font-size: 15px;
    color: #000;
    font-weight: 400;
}

.mentor-profile {
    background: #003C3C;
    padding: 16px 16px 5px 16px;
    max-width: 295px;
    border-radius: 8px;
}


.mentor-profile p {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
}
.mentor-profile img {
    margin-bottom: 15px;
}

.mentor-profile h6 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}
.mentor-profile p span {
    font-size: 12px;
    color: #A0A0A0;
}

.eight-fold .slick-arrow {
    position: absolute;
    z-index: 9;
    border: 0;
    background-repeat: no-repeat;
    font-size: 0;
    bottom: -90px;
    width: 40px !important;
    height: 40px !important;
    padding: 0;
    cursor: pointer;
    background-color: #FB9F40!important;
    background-size: 18px;
    background-position: center;
    border-radius: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.eight-fold .slick-prev {
    left: 42%!important;
    background-image: url(../images/test-left.png);
}
.eight-fold .slick-next {
    right: 50%;
    background-image: url(../images/test-right.png);
}

.eight-fold .slick-slider {
    margin-left: 130px;
    margin-top: 30px;
}

.side-l, .side-r{ top: 43%;}
.side-l {
    position: absolute;
    left: 0;
}

.side-r {
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 767px) { 
.eight-fold h5 {
    padding-left: 40px;
}
.eight-fold h5::before {
  left: 0px;
}

.eight-fold .slick-prev {
    right: 15%!important;
    left: inherit!important;
}
.eight-fold .slick-next {
    right: 0%;
}
.mentor-profile {

    max-width: 100%;
}
.mentor-profile img {
    margin-bottom: 15px;
    width: 100%;
}

.eight-fold h2 {
    font-size: 29px;
}


}