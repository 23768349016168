.footer-old{
    background-color: #cde8e1;
    display: flex;
    flex-direction: row;
    padding: 20px 160px;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    
}

.call{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.call> .img{
    background-color: white;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
}

.call > .img > img{
    width: 20px;
    height: 20px;
}

.call > .details{
    display: flex;
    flex-direction: column;
}

.call > .details > .head{
    font-weight: 700;
}

.call > .details > .content{
    font-size: 12px;
}
.call > .details > .content > span{
    font-weight: 700;
}

.msg{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.msg > .img{
    background-color: white;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
}

.msg > .img > img{
    width: 20px;
    height: 20px;
}

.msg > .details{
    display: flex;
    flex-direction: column;
}

.msg > .details > .head{
    font-weight: 700;
}

.msg > .details > .content{
    font-size: 12px;
}

.location{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.location > .img > img{
    width: 40px;
    height: 40px;
}

.location > .details{
    display: flex;
    flex-direction: column;
}

.location > .details > .head{
    font-weight: 700;
}

.location > .details > .content{
    font-size: 12px;
}

.copyright{
    display: flex;
    flex-direction: column;

    font-size: 12px;
    gap: 10px;
    align-items: flex-end;
}

.copyright > .tnc{
    padding: 0px;
    text-decoration: underline;
}

.copyright > .tnc:hover{
    cursor: pointer;

}

.copyright > .refund-policy{
    padding: 0px;
    text-decoration: underline;
}

.copyright > .refund-policy:hover{
    cursor: pointer;

}
.vl{
    border-left: 2px solid #00020561;
    opacity: 0.5;
    height: 50px;
}

.hl{
    display: block;
}

.social-media-handles{
    display: flex;
    padding: 20px 160px;
    gap: 60px;
    background-color: #cde8e1;
    justify-content: flex-start;
    border-top: 2px solid #cde8e1;
}

.social-media-handles > div{
    display: flex;
    gap: 20px;
    /* width: 25%; */
    align-items: center;
}

.social-media-handles > div> .icon{
    background-color: white;
    height: fit-content;
    padding: 6px;
    border-radius: 16px;
}

.social-media-handles > div > .desc{

    font-size: 14px;
    font-weight: 700;
}


@media screen  and (max-width: 1100px){
    .footer-old{
        padding: 20px 120px;
    }
    .social-media-handles{
        padding: 20px 120px;
    }
}
@media screen  and (max-width: 1000px){
    .footer-old{
        padding: 20px 100px;
    }
    .social-media-handles{
        padding: 20px 100px;
    }
}
@media screen  and (max-width: 800px){
    .footer-old{
        padding: 20px 40px;
    }
    .social-media-handles{
        padding: 20px 40px;
    }

    .call > .details > .head{
        font-size: 14px;
    }
    .call > .details > .content{
        font-size: 10px;
    }
    .msg > .details > .head{
        font-size: 14px;
    }
    .msg > .details > .content{
        font-size: 10px;
    }
    .location > .details > .head{
        font-size: 14px;
    }
    .location > .details > .content{
        font-size: 10px;
    }

    .copyright{
        font-size: 10px;
    }
}

@media screen and (max-width: 700px){
    .footer-old{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
    }
    .copyright{
        align-items: flex-start !important;
    }
    .vl, .hl{
        display: none;
    }

    .social-media-handles{
        padding: 0px 40px;
        justify-content: flex-start;
        gap: 0px;
    }

    .social-media-handles > div{
        width: 50%;
    }
}
@media screen  and (max-width: 500px){
    .footer-old{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        padding-bottom: 30px;
    }

    .copyright{
        align-items: flex-start !important;
    }
    .vl{
        display: none;
    }
    .hl{
        display: block;
        border-top: 2px solid #00020561;
        opacity: 0.5;
        width: 100%;
    }

    .social-media-handles{
        margin-bottom: 50px;
        padding-bottom: 20px;
        flex-direction: column;
        row-gap: 20px;
        
    }

    .social-media-handles > div{
        width: 100%;
    }
}