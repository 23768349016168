.bookingConfirm{
    padding: 20px 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bookingConfirm > .title{
    font-size: 36px;
    color: #068C69;
    font-weight: 600;
    text-align: center;
}

.bookingConfirm > .content{
    font-size: 28px;
    font-weight: 500;
    color: rgb(245,134,37);
    text-align: center;
}

.bookingConfirm > img{
    margin-top: 50px;
    width: 100%;
}


@media screen and (max-width: 1000px) {
    .bookingConfirm{
        padding: 20px 80px;
    }
}
@media screen and (max-width: 800px) {
    .bookingConfirm{
        padding: 20px 60px;
    }

    .bookingConfirm > .title{
        font-size: 32px;
    }
    .bookingConfirm > .content{
        font-size: 24px;
    }
}