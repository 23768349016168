.consult-us-for{
    display: flex;
    padding: 20px 160px;
    gap:20px;
    padding-bottom: 60px;
}

.consult-us-for > .last-col{
    border: 2px solid #179D82;
    border-radius: 6px;
    box-shadow: #179D82 0px 7px 29px 0px;
}

.consult-us-for > .last-col{
    background-color: #F3FFFC;
}

.consult-us-for > .col-3 > .row{
    padding: 20px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.consult-us-for > .first-col > .row{
    justify-content: flex-start;
    opacity: 0.75;
}

.consult-us-for > .col-3 > .row:nth-child(1){
    font-size: 16px;
    font-weight: 700;
    background-color: #F3FFFC;
    height: 80px;
    padding: 0px 20px;
}
.consult-us-for > .last-col > .row:nth-child(1){
    background-color: #DAEDE8 !important;
    width: 98%;
    transform: translateX(14px);
    text-align: center;

}

.consult-us-for > .col-3 > .last-row{
    font-weight: 700;
    border-top: 2px solid #D8E5E2;
}
.consult-us-for > .first-col > .last-row{
    font-weight: 400;
}

.consult-us-for > .last-col > .last-row {
    color: #179D82;
}

.consult-us-for > .last-col > .last-row > span{
    width: fit-content;
    text-decoration: line-through;
    font-size: 12px;
    opacity: 0.75;
    color: black;
}



.consult-us-for > .last-col > .last-row {
    background-color: #DAEDE8;
    width: 98%;
    transform: translateX(14px);
}

/* .consult-us-for > .last-col > .row > img{
    width: 30px;
    height: 30px;
} */

@media screen  and (max-width: 1100px){
    .consult-us-for{
        padding: 20px 120px;
    }
}
@media screen  and (max-width: 1000px){
    .consult-us-for{
        padding: 20px 100px;
    }
}
@media screen  and (max-width: 800px){
    .consult-us-for{
        padding: 20px 40px;
        gap: 0px;
    }

    .consult-us-for > .first-col{
        width: 20%;
    }

    .consult-us-for > .first-col > .row{
        padding: 20px 24px;
        font-size: 13px;
    }

    .consult-us-for > .col-3 > .row:nth-child(1){
        font-size: 13px;
    }
}
@media screen  and (max-width: 700px){
    .consult-us-for > .col-3 > .last-row{
        padding-top: 10px !important;
    }

    .consult-us-for > .col-3 > .first-col{
        padding: 0px 10px;
    }
}
@media screen  and (max-width: 500px){
    .consult-us-for{
        /* justify-content: space-between; */
        padding: 20px;
        margin-bottom: 60px;
    }
    .consult-us-for > .col-3 > div:nth-child(1){
        text-align: center;
    }

    .consult-us-for>.col-3:nth-child(2){
        width: 20%;
    }
    .consult-us-for>.col-3:nth-child(3){
        width: 20%;
    }
    .consult-us-for>.col-3:nth-child(4){
        width: 40%;
    }

    .consult-us-for > .col-3 > .last-row{
        font-size: 13px;
        text-align: center;
    }
}
@media screen  and (max-width: 450px){
    .consult-us-for{
        /* justify-content: space-between; */
        padding: 20px;
    }
    .consult-us-for > .col-3 > .row:nth-child(1){
        text-align: center;
        font-size: 12px;
        padding: 0px 2px;
    }


    .consult-us-for > .col-3 > .last-row{
        font-size: 11px;
        text-align: center;
    }
    .consult-us-for > .first-col> .row{
        font-size: 12px;
        text-align: left;
    }

    .consult-us-for > .first-col > .row{
        padding: 20px 10px;
    }
}