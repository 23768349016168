.howToBookAppointment{
    padding: 20px 160px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.howToBookAppointment > .title{
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    width: 420px;
}

.howToBookAppointment > .title > span{
    color: #068C69;
}

.howToBookCards{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.howToBookCards > div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    flex-grow: 1;
    border-radius: 24px;
    padding: 20px 0px 0px 20px;
    /* color: white; */
    width: 280px;
    border: 2px solid #a1dacb;
    font-weight: 600;
    
}

.howToBookCards > div > .img {
    padding: 16px;
    border-radius: 32px;
    background-color: #CEE9E2;
    width: fit-content;
}

.howToBookCards  > div > img{
    width: 100px;
    height: 60px;
    transform: translateX(160px);
}

.howToBookCards > div:hover {
    background-color: #068C69;
    color: white;
}

.howToBookCards > div:hover > .img{
    background-color: white;
}

.howToBookCards > div:hover > img{
    fill: white;
}

@media screen and (max-width: 800px) {
    .howToBookAppointment{
        padding: 20px 40px;
    }
    .howToBookCards > div{
        width: 220px;
    }
    
    .howToBookCards > div > img{
        position: absolute;
    }
}
@media screen and (max-width: 500px) {
    .howToBookAppointment{
        padding: 20px 65px;
    }
    .howToBookAppointment > .title{
        width: 100%;
    }
    .howToBookCards > div{
        width: 300px;
        height: 170px;
    }
    .howToBookCards{
        flex-direction: column;
    }
    .howToBookCards > div > img{
        position: absolute;
        transform: translate(180px, 80px) !important;
        /* transform: translateY(80px) !important; */
    }

}