.new_contact_us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 108px;
  gap: 1em;
  background: #e9f8f5;
}

.new_contact_us .kyno-section_title {
  font-weight: 700;
  font-size: 50px;
  color: #083e3e;
  text-align: center;
}

.new_contact_us .feature-card-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  /* width: 1224px; */
}

.new_contact_us .feature-card {
  width: 380px;
  height: 157px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.new_contact_us .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 24px 24px 24px;
  text-align: center;
}

.timing-section {
  background-color: #179d82;
  padding: 30px clamp(30px, 0.5vw, 108px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.timing-section .sub_text {
  color: rgb(255, 179, 0);
  font-weight: 600;
  font-size: 18px;
}
.timing-section .sub_text a {
  color: rgb(255, 179, 0);
  text-decoration: none;
}
.timing-section .timing_title {
  font-size: 40px;
  color: white;
  font-weight: 700;
  width: 500px;
}
.timing-section .info_label {
  font-size: 30px;
  font-weight: bold;
}
.timing-section .info_card {
  background-color: #068c69;
  color: white;
  border-radius: 6px;
  text-align: center;
  width: clamp(340px, 1vw, 400px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.timing-section .timing_card_section {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 760px;
}
.timing-section .timing-cards {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  height: auto;
}

@media (max-width: 768px) {
  .new_contact_us {
    width: auto;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    /* background-color: #f9fdfc; */
    color: #004d40;
  }

  .new_contact_us .feature-card-section {
    display: flex;
    flex-wrap: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    gap: 15px;
  }

  .new_contact_us .kyno-section_title {
    text-align: center;
    color: black;
    font-size: 28px;
    font-weight: 600;
  }

  .new_contact_us .feature-card-section h3 {
    color: #083e3e;
    font-weight: 600;
  }

  .new_contact_us .feature-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background: #fff; */
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 8px; */
    /* margin: 5px 0; */
    padding: 15px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    width: 343px;
    height: auto;
    justify-content: start;
    gap: 1em;
  }

  .new_contact_us .feature-card-icon {
    font-size: 28px;
    margin-right: 15px;
    color: #00796b;
  }

  .new_contact_us .content {
    padding: unset;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
  }

  .new_contact_us .content h3 {
    margin: 0 0 5px;
    font-size: 18px;
    color: #004d40;
  }

  .new_contact_us .content p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  .timing-section {
    flex-direction: column;
    gap: 20px;
    padding: 15px 0;
  }
  .timing-section .timing_card_section {
    width: auto;
  }
  .timing-section .timing_title {
    font-size: 20px;
    width: auto;
  }
  .timing-section .sub_text {
    font-size: 14px;
  }
  .timing-section .info_label {
    font-size: 15px;
  }
  .timing-section .info_card {
    width: auto;
    padding: 10px;
  }
  .timing-section .timing-cards {
    gap: 10px;
    padding: 10px;
  }
}

.contact_us_header {
  background-color: #003d3c;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 80px 108px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 80px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact_us_header-title {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  width: 668px;
  height: auto;
}

.highlight {
  color: #004d40;
}

.contact_us_header-description {
  font-size: 1em;
  color: #fff;
  line-height: 1.5;
  margin-bottom: 15px;
  width: 668px;
}

.contact_us_header-availability {
  background-color: #e6f7f1;
  color: #00897b;
  font-weight: bold;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.cities {
  font-weight: bold;
}

.book-button {
  background-color: #ffa000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #ff8f00;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .contact_us_header {
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px 12px;
    display: block;
    height: auto;
    margin-top: 60px;
  }

  .contact_us_header-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    width: auto;
    height: auto;
  }

  .highlight {
    color: #004d40;
  }

  .contact_us_header-description {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 15px;
    width: auto;
  }

  .contact_us_header-availability {
    background-color: #e6f7f1;
    color: #00897b;
    font-weight: bold;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 15px;
    margin-bottom: 20px;
  }

  .cities {
    font-weight: bold;
  }

  .book-button {
    background-color: #ffa000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .book-button:hover {
    background-color: #ff8f00;
  }
}
