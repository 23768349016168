.whole-block{
    padding: 20px 40px;
    background-color: rgb(240, 240, 240);
    gap: 20px;
    justify-content: space-between;
    display: flex;
}

.left-block{
    background-color: white ;
    width: 70%;
    padding: 20px;
    border-radius: 8px;
}

.right-block{
    width: 30%;
    height: 40%;
    /* background-color: white; */
    padding: 20px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.right-block > .form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.left-block > div > .page-heading > img{
    width: 100%;
}

.left-block > div > .page-heading > div{
    font-size: 24px;
    font-weight: 700;
    padding: 10px;
}

.left-block > div > .sub-headings{
    padding: 20px 0px;
}

.left-block > div > .sub-headings > div{
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin-bottom: 16px;
}

.left-block > div > .sub-headings > div > .heading{
    font-size: 20px;
    font-weight: 560;
}

.left-block > div > .sub-headings > div > .content{
    padding: 0px 8px;
}

div.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    /* right: 20px;
    width: 28%; */
    /* height: 80%; */
    
}

.right-block > .form >.heading{
    font-size: 20px;
    font-weight: 600;
    color: #068C69;
}

.right-block > .form >div{
    margin-bottom: 8px;
}

.right-block > .form >div > div{
    font-size: 18px;
}
.right-block > .form >div > input{
    padding: 0px 8px;
    border: 1px solid grey;
    border-radius: 4px;
}

.right-block > .form >.submit-btn{
    margin-top: 20px;
    font-size: 20px;
    background-color: white;
    padding: 4px 20px;
    width: fit-content;
    border: 2px solid #068C69;
    border-radius: 12px;
    color: #068C69;
    font-weight: 600;
}

.right-block > .doctors{
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    border: 2px solid #068C69;
}

._doc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

._doc > .name{
    font-size: 20px;
    font-weight: 500;
}

._doc > .speciality{
    font-size: 18px;
}
._doc > .experience{
    font-size: 18px;
}

._doc > img{
    width: 240px;
}

.doctors > .react-multi-carousel-list{
    transform: translateY(0px) !important;
}

.doctors > .title{
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
}

@media screen and (max-width: 1100px) {
    ._doc > img {
        width: 200px;
        
    }

    ._doc > .name{
        text-align: center;
    }

    ._doc > .name{
        font-size: 18px;
    }
    ._doc > .speciality{
        font-size: 18px;
    }
    ._doc > .experience{
        font-size: 18px;
    }
}
@media screen and (max-width: 1000px) {
    ._doc > img {
        width: 160px;
    }

    ._doc > .name{
        font-size: 16px;
    }
    ._doc > .speciality{
        font-size: 16px;
    }
    ._doc > .experience{
        font-size: 16px;
    }
}


@media screen and (max-width: 768px) {
    .whole-block{
        flex-direction: column;
    }
    .right-block{
        width: 100%;
    }
    .right-block > .form{
        display: none;
    }
    .left-block{
        width: 100%;
    }

    ._doc > img{
        width: 200px;
    }

    .react-multi-carousel-item{
        width: 300px;
    }
}   


@media screen and (max-width: 500px) {
    .left-block > div > .sub-headings > div > .content{
        font-size: 14px;
    }

    .whole-block{
        padding: 20px
    }

}

@media screen and (max-width: 400px) {
    .left-block > div > .page-heading > div{
        text-align: center;

    }
}