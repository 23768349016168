.sales-all-calls{
    margin-top: 20px;

    .page-msg{
        font-size: 16px;
        font-weight: 700;
        color: white;
        background-color: #068c69;
        border-radius: 8px;
        padding: 8px  12px;
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    .all-calls-tabs{
        padding: 8px 12px;
        background-color: rgba(255, 192, 203, 0.3137254902);
        display: flex;
        gap: 20px;

        div{
            padding: 4px 10px;
            border-radius: 12px;
            &.active{
                background-color: rgba(255, 192, 203, 0.9);
            }

            &:hover{
                cursor: pointer;
            }
        }
    }


    .all-calls-content, .outgoing-calls, .incoming-calls{

        overflow-x: scroll;
        overflow-y: scroll;
        height: 400px;
        // padding: 8px 12px;

        .all-calls-headers, .outgoing-calls-headers, .incoming-calls-headers{
            width: 120%;
            display: flex;
            gap: 10px;
            background-color: rgba(128, 128, 128, 0.4);
            padding: 8px 12px;
            font-size: 12px;

            div{
                text-align: center;
            }

            .sno{
                width: 3%;
            }
            .patient-name{
                width: 10%;
            }

            .phone-number{
                width: 10%;
            }
            .patient-id{
                width: 9%;
            }
            .date{
                width: 13%;
            }
            .call-type{
                width: 7%;
            }
            .duration{
                width: 9%;
            }
            .lead-status{
                width: 14%;
            }
            .answered-by{
                width: 8%;
            }
            .bd-assigned{
                width: 8%;
            }
            .initiated-by{
                width: 8%;
            }
            .sr-no{
                width: 10%;
            }
        }

        .all-calls{
            .call{
                padding: 4px 10px;
                width: 120%;
                gap: 10px;
                font-size: 12px;
                div{
                    text-align: center;
                    font-weight: 700;
                }
                .sno{
                    width: 3%;
                }
                .patient-name{
                    width: 10%;
                }
    
                .phone-number{
                    width: 10%;
                }
                .patient-id{
                    width: 9%;
                }
                .date{
                    width: 13%;
                }
                .call-type{
                    width: 7%;
                }
                .duration{
                    width: 9%;
                }
                .lead-status{
                    width: 14%;
                }
                .answered-by{
                    width: 8%;
                }.initiated-by{
                    width: 8%;
                }
                .bd-assigned{
                    width: 8%;
                }
                .sr-no{
                    width: 10%;
                }
                border-right: 8px solid white;

                border-left: 8px solid white;



                &.missed{
                    border-left: 8px solid red;
                    border-right: 8px solid red;
                }
            }
        }
    }


    .sr-number-management{
        overflow-x: scroll;
        overflow-y: scroll;

        
        .headers{
            width: 100%;
            display: flex;
            gap: 20px;
            background-color: rgba(128, 128, 128, 0.4);
            padding: 8px 12px;
            font-size: 18px;
            justify-content: space-around;

            div{
                text-align: center;
            }
        }

        .all-sr-numbers{
            .sr-number{
                width: 100%;
                display: flex;
                gap: 20px;
                padding: 8px 12px;
                font-size: 18px;
                justify-content: space-around;

                div{
                    text-align: center;
                    flex: 1 1;
                }

                select{
                    flex: 1 1;
                }

                &:nth-child(2n){
                    background-color: #f9f7f7;
                }
            }
        }


        &::-webkit-scrollbar{
            display: none;
        }

    }
}