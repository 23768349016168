.thrd-fold_abt{background: #F7F7F7;}

/* .thrd-fold_abt::before {
    background-image: url(../images/typo-vector.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -203px;
    z-index: 1;
    width: 1120px;
    height: 754px;
    left: 50px;
    background-size: 82%;
} */

.thrd-fold_abt h2 {
    color: #000;
    font-size: 32px;
    font-weight: bold;
       position: relative;
    z-index: 9; margin-bottom: 40px;font-family: 'GalanoGrotesqueBold', sans-serif;


}
.thrd-fold_abt h5{margin: 10px;}

.thrd-fold_abt_tab {
    background: #F7F7F7;
    padding: 70px 0 0;
    text-align: center;
    z-index: 111;
}

.thrd-fold_abtss {
    padding: 20px 0;
}

.thrd-fold_abt_tab button {
    border: 1px solid #00000033;
padding: 8px 16px 8px 16px;
border-radius: 8px;
background-color: #fff;
margin: 5px;

}

.thrd-fold_abt_tab button:focus {
    background-color: #FB9F40;
    color: #fff;
}
.thrd-fold_abt_tab h2 {     color: #000;
    font-size: 32px;
    font-weight: bold;
       position: relative;
    z-index: 9; margin-bottom: 40px;font-family: 'GalanoGrotesqueBold', sans-serif;
    text-align: center;}
    .thrd-fold_abt .programitem {
    padding: 50px 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #E7E7E7;
    width: 90%;
    z-index: 9;
    position: relative;
}
.thrd-fold_abt .programitem h4 {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
}

.mb-10 {
    margin-bottom: 30px;
}
.thrd-fold_abt .programitem p {
    color: #646464;
    line-height: 1.7;
    margin: 0;
}
.thrd-fold_abt .programitem img {
    margin-bottom: 40px;
}
/* .thrd-fold_abt .programitem::before {
    background-image: url(http://localhost:3000/static/media/typo-vector.984781d8e21ee6b7bd21.png);
    background-repeat: no-repeat;
    position: absolute;
    content: "";
    top: -261px;
    z-index: 1;
    width: 500px;
    height: 354px;
    left: 176px;
    background-size: 90%;
  } */
@media only screen and (max-width: 767px) { 
    .thrd-fold_abt .programitem_third {
    width: 100%;
    height: 100%;
 
}

    .thrd-fold_abt::before {
        
        width: 100%;
        height: 100%;
        left: 7px;
        background-size: 100%;
    }

    .thrd-fold_abt_tab button {
        padding: 8px 5px 8px 4px;
        margin: 3px;
        width: 48%;
        font-size: 11px;
    }
    
    
    .thrd-fold_abt .programitem {
        padding: 50px 10px;
        background-color: #fff;
        text-align: left;
        border: 1px solid #E7E7E7;
        width: 100%;
        z-index: 9;
        position: relative;
        overflow: hidden;
    }
    
    .programitem h4 {
        margin-bottom: 20px;
    }
    .thrd-fold_abt_tab {
        padding: 40px 0 0;

    }





    }